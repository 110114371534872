import './style1.css'
import WinSteg from '../../WinSteg/WinSteg';
import { useState,useEffect } from 'react';

export default function Step1(props){
    

    let[data,setData] = useState({})

    function toParent(){
        props.setData(data)
    }
    useEffect(() => {
        toParent()
        }, [data])

    return (
        <>
        <section className='stepsec'>
            <p className='newp1'>Upload the original message</p>
            <WinSteg setData={setData} width='900px' height='583px'/>
        </section>
        </>
    )
}
