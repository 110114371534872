import Header2 from "../../components/header/Header2";
import LeftBar from "../../components/leftBar/LeftBar";
import "./style.css";
import { useEffect, useState } from "react";
import pic5 from "../../assets/images/scr2.png";
import Win3 from "../../components/win3/win3";
import AudioSpectrum from "react-audio-spectrum";
import axios from "axios";
import { config } from "../../elements/config";
import Refresh from "../../elements/refresh";
import Loading from "../../elements/Loading";
import SEO from "layout/nextseo";

export default function WebtoSpeech() {
  let [isError, setIsError] = useState(false);
  let [isErrorDone, setIsErrorDone] = useState(false);
  useEffect(() => {
    isError &&
      setTimeout(() => {
        setIsErrorDone(!isErrorDone);
      }, 2000);
  }, [isError]);
  if (isErrorDone === true) {
    setTimeout(() => {
      setIsError(false);
      setIsErrorDone(false);
    }, 2000);
  }
  let [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // Refresh()
  }, []);
  function closeWin() {
    setLink(``);
    setContent(``);
    setIsLoading(false);
  }
  let [link, setLink] = useState(``);
  function showLink(e) {
    setLink(e.currentTarget.value);
    axios
      .get(e.target.value, {
        headers: {
          origin: "https://www.wikipedia.org/",
        },
      })
      .then((res) => {
        console.log(res);
      });
  }
  function convert() {
    return new Promise((res, rej) => {
      axios
        .post(
          `${config.url}convert/content-page/`,
          {
            url: link,
          },
          {
            headers: {
              Authorization: `Bearer ${
                window.localStorage.getItem(`access`) ||
                window.sessionStorage.getItem(`access`)
              }`,
            },
          }
        )
        .then((resolve) => {
          res(resolve.data.content);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    });
  }
  let [ttsData, setTtsData] = useState(``);
  let [content, setContent] = useState(``);
  let [speechInd, setSpeechInd] = useState(0);
  let [nextInd, setNextInd] = useState(400);
  let [nextArr, setNextArr] = useState("");
  let [isBiggerThen8, setIsBiggerThen8] = useState(false);
  useEffect(() => {
    if (+nextInd - 400 >= nextArr.length) {
      setContent(``);
    }
  }, [nextInd]);
  useEffect(() => {
    axios
      .get(
        `https://texttospeech.googleapis.com/v1beta1/voices?key=AIzaSyCD5MddriyA_Tgina0msTZ-xQDa1FuVJaA`
      )
      .then((res) => {
        setTtsData(res.data.voices);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let convertTextToSpeech = async () => {
    setIsLoading(true);
    let text = await convert();
    let finalarr = text.split(" ").filter((item) => {
      return item !== "";
    });
    if (
      text.split(" ").filter((item) => {
        return item !== "";
      }).length > 400
    ) {
      setIsBiggerThen8(true);
      setNextArr(
        text.split(" ").filter((item) => {
          return item !== "";
        })
      );
    }
    let data = {
      audioConfig: {
        audioEncoding: "LINEAR16",
        effectsProfileId: ["handset-class-device"],
        pitch: -1.4,
        speakingRate: 1,
      },
      input: { text: `${finalarr.slice(0, +nextInd)}` },
      voice: {
        languageCode: `${ttsData[speechInd].languageCodes[0]}`,
        name: `${ttsData[speechInd].name}`,
      },
    };
    axios
      .post(
        "https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyCD5MddriyA_Tgina0msTZ-xQDa1FuVJaA",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data.audioContent.length);
        setContent(res.data.audioContent);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
      });
  };
  let convertTextToSpeechNext = async () => {
    setContent(``);
    let data = {
      audioConfig: {
        audioEncoding: "LINEAR16",
        effectsProfileId: ["handset-class-device"],
        pitch: -1.4,
        speakingRate: 1,
      },
      input: { text: `${nextArr.slice(+nextInd, +nextInd + 800)}` },
      voice: {
        languageCode: `${ttsData[speechInd].languageCodes[0]}`,
        name: `${ttsData[speechInd].name}`,
      },
    };

    setIsLoading(true);
    await axios
      .post(
        "https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyCD5MddriyA_Tgina0msTZ-xQDa1FuVJaA",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setContent(res.data.audioContent);
        setIsLoading(false);
        setNextInd(nextInd + 400);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Header2 title="Web to Speech" />
      <SEO
        title="Bayanat Speech | Web To Speech"
        canonicalLink="https://bayanatspeech.com/WebToSpeech"
      />
      <LeftBar />
      <div
        style={{ paddingTop: "73px" }}
        className="animate__animated animate__slideInLeft"
      >
        {content && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <audio
              id="myAudio"
              className="TTSAudio"
              controls="controls"
              autobuffer="autobuffer"
            >
              <source src={`data:audio/wav;base64,` + content} />
            </audio>
            {isBiggerThen8 && (
              <p onClick={convertTextToSpeechNext} className="nextP">
                NEXT
              </p>
            )}
            <AudioSpectrum
              id="audio-canvas"
              height={100}
              width={300}
              audioId={"myAudio"}
              capColor={"orange"}
              capHeight={2}
              meterWidth={3}
              meterCount={512}
              meterColor={[
                { stop: 0, color: "white" },
                { stop: 0.5, color: "#ff9911" },
                { stop: 1, color: "#ff9900" },
              ]}
              gap={4}
            />
          </div>
        )}
        <section className="WebtoSpeechSection">
          <div className="WtSdiv1">
            <div className="tdiv">
              <p>Speech Language</p>
              <select
                onClick={(e) => setSpeechInd(e.target.selectedIndex)}
                className="sel"
                name="Language"
              >
                {ttsData &&
                  ttsData?.map((item, index) => {
                    return (
                      <>
                        {index <= 140 && (
                          <option key={index} value={index}>
                            {item.name.includes(`ar-XA-Wavenet-`)
                              ? item.name.replace(`ar-XA-Wavenet-`, `Arabic - `)
                              : item.name.includes(`bn-IN-Wavenet-`)
                              ? item.name.replace(`bn-IN-Wavenet-`, `Indian - `)
                              : item.name.includes(`en-GB-Wavenet`)
                              ? item.name.replace(
                                  `en-GB-Wavenet-`,
                                  `English UK - `
                                )
                              : item.name.includes(`fr-CA-Wavenet-`)
                              ? item.name.replace(
                                  `fr-CA-Wavenet-`,
                                  `French(Canada) - `
                                )
                              : item.name.includes(`en-US-Wavenet-`)
                              ? item.name.replace(
                                  `en-US-Wavenet-`,
                                  `English US - `
                                )
                              : item.name.includes(`es-ES-Wavenet-`)
                              ? item.name.replace(
                                  `es-ES-Wavenet-`,
                                  `Spanish - `
                                )
                              : item.name.includes(`fi-FI-Wavenet-`)
                              ? item.name.replace(
                                  `fi-FI-Wavenet-`,
                                  `Finnish - `
                                )
                              : item.name.includes(`gu-IN-Wavenet-`)
                              ? item.name.replace(
                                  `gu-IN-Wavenet-`,
                                  `Gujarati (India) - `
                                )
                              : item.name.includes(`ja-JP-Wavenet-`)
                              ? item.name.replace(
                                  `ja-JP-Wavenet-`,
                                  `Japanese - `
                                )
                              : item.name.includes(`kn-IN-Wavenet-`)
                              ? item.name.replace(
                                  `kn-IN-Wavenet-`,
                                  `Kannada (India) - `
                                )
                              : item.name.includes(`ml-IN-Wavenet-`)
                              ? item.name.replace(
                                  `ml-IN-Wavenet-`,
                                  `Malayam - `
                                )
                              : item.name.includes(`sv-SE-Wavenet-`)
                              ? item.name.replace(
                                  `sv-SE-Wavenet-`,
                                  `Swedish - `
                                )
                              : item.name.includes(`ta-IN-Wavenet-`)
                              ? item.name.replace(
                                  `ta-IN-Wavenet-`,
                                  `Tamil(India) - `
                                )
                              : item.name.includes(`tr-TR-Wavenet-`)
                              ? item.name.replace(
                                  `tr-TR-Wavenet-`,
                                  `Turkish - `
                                )
                              : item.name.includes(`ms-MY-Wavenet-`)
                              ? item.name.replace(`ms-MY-Wavenet-`, `Malay - `)
                              : item.name.includes(`cs-CZ-Wavenet-`)
                              ? item.name.replace(`cs-CZ-Wavenet-`, `Czech - `)
                              : item.name.includes(`de-DE-Wavenet-`)
                              ? item.name.replace(`de-DE-Wavenet-`, `German - `)
                              : item.name.includes(`en-AU-Wavenet-`)
                              ? item.name.replace(
                                  `en-AU-Wavenet-`,
                                  `English(Australia) - `
                                )
                              : item.name.includes(`en-IN-Wavenet-`)
                              ? item.name.replace(
                                  `en-IN-Wavenet-`,
                                  `English(Ireland) - `
                                )
                              : item.name.includes(`es-US-Wavenet-`)
                              ? item.name.replace(
                                  `es-US-Wavenet-`,
                                  `Spanish US - `
                                )
                              : item.name.includes(`fr-FR-Wavenet-`)
                              ? item.name.replace(`fr-FR-Wavenet-`, `French - `)
                              : item.name.includes(`hi-IN-Wavenet-`)
                              ? item.name.replace(`hi-IN-Wavenet-`, `Hindi - `)
                              : item.name.includes(`id-ID-Wavenet-`)
                              ? item.name.replace(
                                  `id-ID-Wavenet-`,
                                  `Indonesian - `
                                )
                              : item.name.includes(`it-IT-Wavenet-`)
                              ? item.name.replace(
                                  `it-IT-Wavenet-`,
                                  `Italian - `
                                )
                              : item.name.includes(`ko-KR-Wavenet-`)
                              ? item.name.replace(`ko-KR-Wavenet-`, `Korean - `)
                              : item.name.includes(`ru-RU-Wavenet-`)
                              ? item.name.replace(
                                  `ru-RU-Wavenet-`,
                                  `Russian - `
                                )
                              : item.name.includes(`uk-UA-Wavenet-`)
                              ? item.name.replace(
                                  `uk-UA-Wavenet-`,
                                  `Ukranian - `
                                )
                              : item.name.includes(`cmn-CN-Wavenet-`)
                              ? item.name.replace(
                                  `cmn-CN-Wavenet-`,
                                  `Mandarin Chinese - `
                                )
                              : item.name.includes(`cmn-TW-Wavenet-`)
                              ? item.name.replace(
                                  `cmn-TW-Wavenet-`,
                                  `Chinese(T) - `
                                )
                              : item.name.includes(`da-DK-Wavenet-`)
                              ? item.name.replace(`da-DK-Wavenet-`, `Danish - `)
                              : item.name.includes(`el-GR-Wavenet-`)
                              ? item.name.replace(`el-GR-Wavenet-`, `Greek - `)
                              : item.name.includes(`fil-PH-Wavenet-`)
                              ? item.name.replace(
                                  `fil-PH-Wavenet-`,
                                  `Filipino - `
                                )
                              : item.name.includes(`hu-HU-Wavenet-`)
                              ? item.name.replace(
                                  `hu-HU-Wavenet-`,
                                  `Hungarian - `
                                )
                              : item.name.includes(`nb-NO-Wavenet-`)
                              ? item.name.replace(
                                  `nb-NO-Wavenet-`,
                                  `Norwegian - `
                                )
                              : item.name.includes(`nb-no-Wavenet-`)
                              ? item.name.replace(
                                  `nb-no-Wavenet-`,
                                  `Norwegian - `
                                )
                              : item.name.includes(`nl-BE-Wavenet-`)
                              ? item.name.replace(
                                  `nl-BE-Wavenet-`,
                                  `Dutch(Belgium) - `
                                )
                              : item.name.includes(`nl-NL-Wavenet-`)
                              ? item.name.replace(
                                  `nl-NL-Wavenet-`,
                                  `Dutch(Netherlands) - `
                                )
                              : item.name.includes(`pt-PT-Wavenet-`)
                              ? item.name.replace(
                                  `pt-PT-Wavenet-`,
                                  `Portugese(Portugal) - `
                                )
                              : item.name.includes(`sk-SK-Wavenet-`)
                              ? item.name.replace(`sk-SK-Wavenet-`, `Slovak - `)
                              : item.name.includes(`pl-PL-Wavenet-`)
                              ? item.name.replace(`pl-PL-Wavenet-`, `Polish - `)
                              : item.name.includes(`vi-VN-Wavenet-`)
                              ? item.name.replace(
                                  `vi-VN-Wavenet-`,
                                  `Vietnamese - `
                                )
                              : item.name.includes(`pt-BR-Wavenet-`)
                              ? item.name.replace(
                                  `pt-BR-Wavenet-`,
                                  `Portugese(Brazil) - `
                                )
                              : item.name.includes(`ca-es-Standard-`)
                              ? item.name.replace(
                                  `ca-es-Standard-`,
                                  `Catalan - `
                                )
                              : item.name.includes(`pa-IN-Wavenet-`)
                              ? item.name.replace(
                                  `pa-IN-Wavenet-`,
                                  `Punjabi - `
                                )
                              : null}
                          </option>
                        )}
                      </>
                    );
                  })}
              </select>
            </div>
            <div className="WebToSpeechLoading">
              {isLoading && <Loading size={15} />}
            </div>
          </div>
          <input
            placeholder="Past the link here"
            className="emptydiv"
            onChange={showLink}
            value={link}
          ></input>
          <img className="revImg" src={pic5} alt="icon1"></img>
          <div
            style={!isError ? { display: "none" } : { display: "flex" }}
            className={`successSignUp ${
              isError && "animate__animated animate__slideInLeft"
            } ${isErrorDone && "animate__animated animate__slideOutLeft"}`}
          >
            <p>Something went wrong</p>
          </div>
          <Win3
            convert={convertTextToSpeech}
            refresh={() => {
              setContent(``);
              setIsLoading(false);
            }}
            speech={true}
            closeWin={closeWin}
            width="1540px"
            height="600px"
            cross={true}
            text="some image"
            linkSpace={true}
            link={link}
            nodownload={true}
          />
        </section>
      </div>
    </>
  );
}
