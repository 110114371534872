import { NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./style.css";
import googleicon from "../../assets/images/g.png";
import facebookicon from "../../assets/images/f.png";
import axios from "axios";
import { config } from "../../elements/config";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Toaster from "../../components/toaster/toster-component";
import SEO from "layout/nextseo";

export default function SignUp(props) {
  const history = useHistory();
  let [data, setData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  let [fullNameMessage, setFullNameMessage] = useState("");
  let [emailMessage, setEmailMessage] = useState("");
  let [phoneNumberMessage, setPhoneNumberMessage] = useState("");
  let [passwordMessage, setPasswordMessage] = useState("");
  let [checkMessage, setCheckMessage] = useState("");
  let [isChecked, setIsChecked] = useState(true);
  let [fetchError, setFetchError] = useState([]);
  let [isSignedUp, setIsSignedUp] = useState(false);
  let [isSignedUpDone, setIsSignedUpDone] = useState(true);
  let [googleId, setTokenIdGoogle] = useState("");
  let [fbId, setTokenIdFb] = useState("");
  let [phoneValue, setPhoneValue] = useState("");
  let [toaster, setToaster] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setIsSignedUpDone(!isSignedUpDone);
    }, 2000);
  }, [isSignedUp]);

  if (isSignedUpDone === true) {
    setTimeout(() => {
      setIsSignedUp(false);
    }, 2000);
  }

  function onChangeFullName(e) {
    if (!e.currentTarget.value.includes(` `)) {
      setFullNameMessage(`Please input right Full name`);
    } else {
      setFullNameMessage(``);
    }
    setData({ ...data, fullName: e.target.value });
  }
  function onChangeEmail(e) {
    if (!e.currentTarget.value.includes("@")) {
      setEmailMessage(`Please input right email`);
    } else {
      setEmailMessage(``);
    }
    setData({ ...data, email: e.target.value });
  }
  function onChangePhoneNumber(e) {
    if (e.currentTarget.value.length < 7 || e.currentTarget.value[0] !== "+") {
      setPhoneNumberMessage(
        `Phone number is too short or does not include 'plus'`
      );
    } else {
      setPhoneNumberMessage(``);
    }
    setData({ ...data, phoneNumber: e.target.value });
  }
  function onChangePassword(e) {
    if (e.currentTarget.value.length < 8) {
      setPasswordMessage(`Password has to containt at least 8 characters`);
    } else {
      setPasswordMessage("");
    }
    setData({ ...data, password: e.target.value });
  }
  function onChangeCheck() {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setCheckMessage(`Cannot continue without accepting`);
    } else {
      setCheckMessage(``);
    }
  }

  let handleSubmit = () => {
    if (!data.fullName.includes(` `)) {
      setFullNameMessage(`Please input right Full name`);
    } else {
      setFullNameMessage(``);
    }
    if (!data.email.includes("@")) {
      setEmailMessage(`Please input right email`);
    } else {
      setEmailMessage(``);
    }
    if (data.phoneNumber.length < 7 || data.phoneNumber[0] !== "+") {
      setPhoneNumberMessage(
        `Phone number is too short or does not include 'plus'`
      );
    } else {
      setPhoneNumberMessage(``);
    }
    if (data.password.length < 8) {
      setPasswordMessage(`Password has to containt at least 8 characters`);
    } else {
      setPasswordMessage("");
    }
    if (isChecked === true) {
      setCheckMessage(`Cannot continue without check`);
    } else {
      setCheckMessage(``);
    }
    if (data.fullName.includes(` `)) {
      if (data.email.includes(`@`)) {
        if (data.phoneNumber.length > 7 && data.phoneNumber[0] === "+") {
          if (data.password.length >= 8) {
            if (!isChecked) {
              axios
                .post(`${config.url}auth/register/`, {
                  email: data.email,
                  full_name: data.fullName,
                  password: data.password,
                  phone_number: data.phoneNumber,
                })
                .then((res) => {
                  setError("Please verify your email.");
                  setToaster(true);
                  setTimeout(() => {
                    setToaster(false);
                    setFetchError([]);
                    setIsSignedUp(true);
                    history.push("/login");
                  }, [3000]);
                })
                .catch((rej) => {
                  setError("Email is already Exists.");
                  setToaster(true);
                  setTimeout(() => {
                    setToaster(false);
                  }, [3000]);
                  // setFetchError(Object.values(rej.response.data.errors));
                });
            }
          }
        }
      }
    }
  };

  // function googleLogin(res) {
  //   axios
  //     .post(`${config.url}auth/dj-rest-auth/google/`, {
  //       access_token: res.accessToken,
  //     })
  //     .then((res) => {
  //       // getLoginType();
  //       if (res.status === 200) {
  //         history.push("/TextToSpeech");
  //       } else {
  //         history.push("/login");
  //       }
  //     });
  // }

  // function facebookLogin(res) {
  //   axios
  //     .post(`${config.url}auth/dj-rest-auth/facebook/`, {
  //       access_token: res.accessToken,
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         history.push("/TextToSpeech");
  //       } else {
  //         history.push("/login");
  //       }
  //     });
  // }

  function googleLogin(res) {
    axios
      .post(`${config.url}auth/dj-rest-auth/google/`, {
        access_token: res.accessToken,
      })
      .then((response) => {
        setTokenIdGoogle(response.data.access_token);
        axios
          .get(`${config.url}api/users/me/`, {
            headers: {
              Authorization: `Bearer ${
                googleId || fbId || response.data.access_token
              }`,
            },
          })
          .then((meData) => {
            localStorage.setItem(
              "GoogleUserData",
              JSON.stringify(meData ? meData.data : null)
            );
            sessionStorage.setItem(`access`, response.data.access_token);
            localStorage.setItem(`access`, response.data.access_token);
            localStorage.setItem("loginType", "google");
            history.replace("./TextToSpeech");
            // axios
            //   .post(`${config.url}auth/token/refresh/`, {
            //     refresh: response.data.refresh_token,
            //   })
            //   .then((userAccess) => {
            //     sessionStorage.setItem(`access`, userAccess.data.access);
            //     localStorage.setItem(`refresh`, userAccess.data.access);
            //     localStorage.setItem("googleLoggedIn", "logged");
            //     setTokenIdGoogle(userAccess.data.access);
            //     history.replace("./TextToSpeech");
            //   });
          });
      });
  }

  function facebookLogin(res) {
    axios
      .post(`${config.url}auth/dj-rest-auth/facebook/`, {
        access_token: res.accessToken,
      })
      .then((response) => {
        setTokenIdFb(response.data.access_token);
        axios
          .get(`${config.url}api/users/me/`, {
            headers: {
              Authorization: `Bearer ${
                googleId || fbId || response.data.access_token
              }`,
            },
          })
          .then((meData) => {
            localStorage.setItem(
              "FacebookUserData",
              JSON.stringify(meData?.data)
            );
            localStorage.setItem("access", response.data.access_token);
            localStorage.setItem("loginType", "facebook");
            history.push("/TextToSpeech");
            // axios
            //   .post(`${config.url}auth/token/refresh/`, {
            //     refresh: response.data.refresh_token,
            //   })
            //   .then((userAccess) => {
            //     sessionStorage.setItem(`access`, userAccess.data.access);
            //     localStorage.setItem(`refresh`, userAccess.data.access);
            //     localStorage.setItem("googleLoggedIn", "logged");
            //     setTokenIdFb(userAccess.data.access);
            //     history.replace("./TextToSpeech");
            //   });
          });
      });
  }

  return (
    <div className="signUp">
      {toaster ? <Toaster message={error} /> : ""}
      <div
        style={!isSignedUp ? { display: "none" } : { display: "flex" }}
        className={`successSignUp ${
          isSignedUp && "animate__animated animate__slideInLeft"
        } ${isSignedUpDone && "animate__animated animate__slideOutLeft"}`}
      >
        <p>Message sent to your Email for Verification</p>
      </div>
      <Header />
      <SEO title="Bayanat Speech | Sign Up" />

      <div style={{ paddingTop: "78px" }}>
        <div className="signUpSize">
          <h5 className="signUpTitle">Sign up</h5>
          <p className="signUpText">Full Name</p>
          <input
            style={
              fullNameMessage === ""
                ? { "border-color": "#B2B2B2" }
                : { "border-color": "red" }
            }
            className={`signupInput ${
              fullNameMessage !== "" ? "animate__animated animate__shakeX" : ""
            }`}
            required
            placeholder="Full Name"
            onBlur={onChangeFullName}
          />
          <p className="errorMessage">{fullNameMessage}</p>
          <p className="signUpText">Email Address</p>
          <input
            style={
              emailMessage === ""
                ? { "border-color": "#B2B2B2" }
                : { "border-color": "red" }
            }
            className={`signupInput ${
              emailMessage !== "" ? "animate__animated animate__shakeX" : ""
            }`}
            required
            placeholder="Email Address"
            onBlur={onChangeEmail}
          />
          <p className="errorMessage">{emailMessage}</p>
          <p className="signUpText">Phone Number</p>
          {/* <input
            style={
              phoneNumberMessage === ""
                ? { "border-color": "#B2B2B2" }
                : { "border-color": "red" }
            }
            className={`signupInput ${
              phoneNumberMessage !== ""
                ? "animate__animated animate__shakeX"
                : ""
            }`}
            required
            placeholder="Phone Number"
            onBlur={onChangePhoneNumber}
          /> */}
          <PhoneInput
            country={"us"}
            value={phoneValue}
            onChange={(phone) => {
              setPhoneValue(phone);
              setData({ ...data, phoneNumber: "+" + phone.toString() });
            }}
          />
          <p className="errorMessage">{phoneNumberMessage}</p>
          <p className="signUpText">Password</p>
          <input
            style={
              passwordMessage === ""
                ? { "border-color": "#B2B2B2" }
                : { "border-color": "red" }
            }
            type="password"
            className={`signupInput ${
              passwordMessage !== "" ? "animate__animated animate__shakeX" : ""
            }`}
            required
            placeholder="Password"
            onBlur={onChangePassword}
          />
          <p className="errorMessage">{passwordMessage}</p>
          <div className="Ireadandaccept">
            <input type="checkbox" onClick={onChangeCheck} />
            <p>I read and accept</p>
            <NavLink to="/terms">Terms and Conditions</NavLink>
          </div>
          <p className="errorMessage">{checkMessage}</p>
          <button className="signUpBtn" onClick={handleSubmit}>
            Sign up
          </button>
          <p className="orsignupwith">or sign up with</p>
          <div className="signUpSocBtns">
            <GoogleLogin
              render={(renderProps) => (
                <div
                  className="signUpSocBtn"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img src={googleicon} alt="icon1" />
                  <p>Google</p>
                </div>
              )}
              clientId="352606531076-0drt3a1ssjju2ralnu12bbmaqkdd1lsp.apps.googleusercontent.com"
              onSuccess={googleLogin}
              onFailure={() => console.log(`Google Login Failed`)}
            />
            <FacebookLogin
              appId="1108975349576520"
              fields="name,email,picture"
              callback={facebookLogin}
              icon={
                <div className="signUpSocBtn signUpSocBtn2">
                  <img src={facebookicon} alt="icon1" />
                  <p>Facebook</p>
                </div>
              }
              cssClass="fbBtn"
              textButton=""
            />
          </div>
          <p className="Alreadyavenaccount">
            {" "}
            Already have an account? <NavLink to="/login">Login</NavLink>{" "}
          </p>
          <ul className="errorUl">
            {fetchError.map((item) => {
              return <li>{item[0]}</li>;
            })}
          </ul>
        </div>
        <Footer />
      </div>
    </div>
  );
}
