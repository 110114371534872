import "./style.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import ReactVideoPLayer from "../reactPlayer/reactPlayer.component";
import logo from "app/assets/images/logo.png";
// import howItworksVideo from "../../assets/video/how_it_works.mp4";

export default function Header(props) {
  const [isMenu, setMenu] = React.useState(false);
  const [isVideoShow, setVideoShow] = React.useState(false);

  const toggleMenuHandler = React.useCallback(() => {
    setMenu(!isMenu);
    document.body.classList.toggle("menu-open");
  }, []);
  return (
    <header
      style={{ position: `fixed`, backgroundColor: "white", zIndex: "90000" }}
    >
      <div className="size headerSize">
        <NavLink
          to={
            window.innerWidth < 570 && document.location.pathname !== "/signUp"
              ? "./signUp"
              : window.innerWidth < 570 &&
                document.location.pathname === "/signUp"
              ? "./"
              : "./"
          }
        >
          <img src={logo} className="headerLogo" alt="logo" />
        </NavLink>
        <div className="headerRight">
          <div className="HeaderPart1">
            <NavLink to={"./SubscribePlan"}> Subscribe Plan </NavLink>
            <p onClick={() => setVideoShow(!isVideoShow)}>How it works</p>
            {isVideoShow ? (
              <ReactVideoPLayer
                setShow={setVideoShow}
                videoUrl={
                  require("../../assets/video/how_it_works.mp4").default
                }
              />
            ) : null}
            <NavLink to={"./AboutUs"}> About Us</NavLink>
            <NavLink to={"./Blog"}> Blog</NavLink>
            <NavLink to={"./ContactUs"}>Contact us</NavLink>
            <NavLink to={"/faq"} className="m-link">
              FAQs
            </NavLink>
            <NavLink to={"/privacy"} className="m-link">
              Privacy Policy
            </NavLink>
            <NavLink to={"/terms"} className="m-link">
              Terms and Conditions
            </NavLink>
          </div>
          {props.isLoggedIn &&
          (sessionStorage.getItem("data") || localStorage.getItem("data")) ? (
            <NavLink to={"./TextToSpeech"}> Dashboard </NavLink>
          ) : (
            <>
              {sessionStorage.getItem("data") ? (
                <NavLink to={"./TextToSpeech"}> Dashboard </NavLink>
              ) : (
                <div className="HeaderPart2">
                  <NavLink to={"./login"}>
                    {" "}
                    <p className="headerLogin">Login</p>
                  </NavLink>
                  <NavLink to={"./signUp"} className="signUpBtn SignUpBtnResp">
                    {" "}
                    <span>Sign Up</span>
                  </NavLink>
                  <div
                    className="mobile-menu for-open"
                    onClick={toggleMenuHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-list"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                      />
                    </svg>
                  </div>
                  <div
                    className="mobile-menu for-close"
                    onClick={toggleMenuHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
}
