import React from "react";
import { Redirect, Route } from "react-router";
import { accessTypeByPermission } from "../../common/public";

const GuardedRouteComponent = ({
  component: Component,
  permission = "",
  isAllowed = true,
  ...rest
}) => {
  let accessType = null;
  if (permission !== "") {
    accessType = accessTypeByPermission(permission);
    console.log(accessType);
  }
  return accessType === 0 || !isAllowed ? (
    <Redirect to="/Upgrade" />
  ) : (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
      // component={Component}
    />
  );
};

export default GuardedRouteComponent;
