import './style.css'
import { useState,useEffect } from 'react';
import WinSteg from '../../WinSteg/WinSteg';

export default function Step2(props){  
    let[data,setData] = useState({})

    function toParent(){
        props.setData(data)
    }
    useEffect(() => {
        toParent()
        }, [data])
    return (
        <>
        <section className='stepsec'>
            <p className='newp1'>Upload your secret message</p>
            <WinSteg setData={setData} width='900px' height='583px'/>
        </section>
        </>
    )
}