import React from "react";
import { Helmet } from "react-helmet-async";
//For TypeScript
// interface SEOProps{
//     title:string
// description:string
// }
const SEO = (props) => {
  // <link rel="canonical" href="https://bayanatspeech.com/" />

  const seoData = {
    title: `Bayanat Speech for voice and Text converter and Services`,
    description: `Bayanat Speech for AI and Machine Learning speech and text services. More than 30 languages and 100 voices, male and female steganography, language detection, translation.`,
    canonicalLink: `https://bayanatspeech.com/`,
    imageUrl: ``,
  };
  const {
    title = seoData.title,
    description = seoData.description,
    canonicalLink = seoData.canonicalLink,
    imageUrl = seoData.imageUrl,
  } = props;
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalLink} />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://bayanatspeech.com/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://bayanatspeech.com/" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </Helmet>
  );
};
export default SEO;
