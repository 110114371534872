import "./style.css";
import pic1 from "../../assets/images/play.png";
import circleIcon from "../../assets/images/circle.png";
import filesIcon from "../../assets/images/files.png";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export default function WinSteg(props) {
  let [files, setFiles] = useState([]);
  let [errorMsg, setErrorMsg] = useState("");
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, {})));
    },
  });

  function toParent() {
    props.setData(files[0]);
  }
  useEffect(() => {
    if (files[0] && files[0].type !== `audio/wav`) {
      console.log(`worked`);
      setErrorMsg("Please upload .wav file");
      files.shift();
      return;
    }
    setErrorMsg("");
    toParent();
  }, [files]);
  return (
    <>
      <div className="smallWindow">
        <div className="swG">
          <div>
            <div className="xxx">
              <img className="img1" src={pic1} alt="img1"></img>
            </div>
            <div className="xxx">
              <img className="img3" src={circleIcon} alt="img2"></img>
            </div>
            <div className="xxx">
              <img className="img4" src={filesIcon} alt="img2"></img>
            </div>
          </div>
        </div>
        <div
          className="WinStegDropArea"
          {...getRootProps()}
          onChange={toParent}
        >
          <input {...getInputProps()} />
          <p
            className={
              files.length !== 0 ? "uploadedFileName" : "WinStegPlaceHolder"
            }
          >
            {files.length !== 0
              ? files[0].name
              : "Drag and drop your .wav file here"}
          </p>
        </div>
      </div>
      <p className="WinStegErrorMsg">{errorMsg}</p>
    </>
  );
}
