import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { UnProtectedRoute, ProtectedRoute } from "app/common";
//
import Faqs from "app/components/Faqs";
import Privacy from "app/components/privacy";
import Terms from "app/components/Terms";
//
import Home from "app/containers/home";
import Login from "app/containers/login";
import SignUp from "app/containers/signUp";
import AboutUs from "app/containers/AboutUs";
import Blog from "app/containers/Blog";
import ContactUs from "app/containers/ContactUs";
import CreateSteganography from "app/containers/CreateSteganography";
import ForgotPassword from "app/containers/forgotPassword";
import GuardedRoute from "app/containers/GuardedRoute";
import MyAccount from "app/containers/MyAccount";
import Ocr from "app/containers/Ocr";
import Payment from "app/containers/Payment";
import ResetPassword from "app/containers/ResetPassword";
import SubscribePlan from "app/containers/SubscribePlan";
import TextToSpeech from "app/containers/textToSpeech";
//import TextToSpeech from "app/containers/textToSpeech";
import TranslatePage from "app/containers/Translate";
import Upgrade from "app/containers/Upgrade";
import VideoEdit from "app/containers/VideoEdit";
import WebtoSpeech from "app/containers/WebtoSpeech";
//
import NotFound from "layout/notfound";
function App() {
  const { isLoggedIn, setIsLoggedIn } = useApp();
  return (
    <Switch>
      <Route
        exact={true}
        path="/"
        component={() => <Home isLoggedIn={isLoggedIn} />}
      />
      <Route path="/signUp" component={SignUp} />
      <Route
        path="/login"
        component={() => <Login setData={setIsLoggedIn} data={isLoggedIn} />}
      />
      <Route
        path="/contactUs"
        component={() => <ContactUs isLoggedIn={isLoggedIn} />}
      />
      <Route
        path="/AboutUs"
        component={() => <AboutUs isLoggedIn={isLoggedIn} />}
      />
      <Route
        path="/Blog"
        component={() => <Blog isLoggedIn={isLoggedIn} />}
      />
      <GuardedRoute
        path="/TextToSpeech"
        component={() => <TextToSpeech setData={setIsLoggedIn} />}
        permission="convert_to_speech"
      />
      <Route
        path="/SubscribePlan"
        component={() => <SubscribePlan isLoggedIn={isLoggedIn} />}
      />
      {/* <Route  path='/tracksMerger' component={() => (<TracksMerger isLoggedIn={isLoggedIn} />)} /> */}
      <Route path="/payment/execute" component={Payment} />
      <Route path="/forgotPassword" component={ForgotPassword} />
      <Route path="/resetPassword" component={ResetPassword} />
      <Route path="/MyAccount" component={MyAccount} />
      <GuardedRoute
        path="/History"
        component={History}
        permission="saved_audio_files_history_count"
      />
      <Route path="/terms" component={Terms} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/faq" component={Faqs} />
      <Route
        path="/Upgrade"
        component={() => <Upgrade isLoggedIn={isLoggedIn} />}
      />
      <GuardedRoute
        path="/TextToSpeech"
        component={() =>
          localStorage.getItem("googleLoggedIn") === "logged" && (
            <TextToSpeech setData={setIsLoggedIn} />
          )
        }
      />
      <Route path="/WebToSpeech" component={WebtoSpeech} />
      <GuardedRoute
        path="/Translate"
        component={TranslatePage}
        permission="translation"
      />
      <GuardedRoute
        path="/OCR"
        component={Ocr}
        permission={"convert_to_text"}
      />
      <GuardedRoute
        path="/createSteganography"
        component={CreateSteganography}
        permission="steganography"
      />
      {/* <Route  path='/MP3TracksMerger' component={MP3TracksMerger} /> */}
      <Route path="/VideoEdit" component={VideoEdit} />
      {isLoggedIn && (
        <React.Fragment>
          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route path="/resetPassword" component={ResetPassword} />
          <Route path="/MyAccount" component={MyAccount} />
          <GuardedRoute
            path="/History"
            component={History}
            permission="saved_audio_files_history_count"
          />
          <Route
            path="/Upgrade"
            component={() => <Upgrade isLoggedIn={isLoggedIn} />}
          />
          <GuardedRoute
            path="/TextToSpeech"
            component={() => <TextToSpeech setData={setIsLoggedIn} />}
            permission="convert_to_speech"
          />
          <Route path="/WebToSpeech" component={WebtoSpeech} />
          <GuardedRoute
            path="/Translate"
            component={TranslatePage}
            permission="translation"
          />
          <GuardedRoute
            path="/OCR"
            component={Ocr}
            permission="convert_to_text"
          />
          <GuardedRoute
            path="/createSteganography"
            component={CreateSteganography}
            permission="steganography"
          />
          {/* <Route  path='/MP3TracksMerger' component={MP3TracksMerger} /> */}
          <Route path="/VideoEdit" component={VideoEdit} />
          <Route path={`*`}>
            <NotFound />
          </Route>
        </React.Fragment>
      )}
    </Switch>
  );
}

export default App;

const useApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const history = useHistory();

  if (sessionStorage.getItem("access") || localStorage.getItem("access")) {
    if (UnProtectedRoute.includes(history.location.pathname.toLowerCase())) {
      history.push("/");
    }
  } else {
    if (ProtectedRoute.includes(history.location.pathname.toLowerCase())) {
      history.push("/");
    }
  }
  useEffect(() => {
    if (
      sessionStorage.getItem("data") ||
      localStorage.getItem("data") ||
      sessionStorage.getItem("access") ||
      localStorage.getItem("access")
    ) {
      // @ts-expect-error
      setIsLoggedIn(true);
      //   axios
      //     .post(`${config.url}auth/token/refresh/`, {
      //       refresh: localStorage.getItem(`googleLoggedIn`),
      //     })
      //     .then((res) => {
      //       setIsLoggedIn(true);
      //       localStorage.setItem(`access`, res.data.access);
      //     });
    }
  }, []);
  if (sessionStorage.getItem(`refresh`)) {
    // axios
    //   .post(`${config.url}auth/token/refresh/`, {
    //     refresh: sessionStorage.getItem(`refresh`),
    //   })
    //   .then((res) => {
    //     setIsLoggedIn(true);
    //     sessionStorage.setItem(`access`, res.data.access);
    //   })
    //   .catch((err) => {
    //     setIsLoggedIn(false);
    //   });
  }
  if (window.localStorage.getItem(`refresh`)) {
    // axios
    //   .post(`${config.url}auth/token/refresh/`, {
    //     refresh: localStorage.getItem(`refresh`),
    //   })
    //   .then((res) => {
    //     setIsLoggedIn(true);
    //     window.localStorage.setItem(`access`, res.data.access);
    //   })
    //   .catch((err) => {
    //     setIsLoggedIn(false);
    //   });
  }

  return { isLoggedIn, setIsLoggedIn };
};
