import LeftBar from "../../components/leftBar/LeftBar";
import Header2 from "../../components/header/Header2";
import "./style.css";
import SEO from "layout/nextseo";

export default function VideoEdit() {
  return (
    <>
      <Header2 title="Audio With Videos" />
      <SEO
        title="Bayanat Speech | Video Edit"
        canonicalLink="https://bayanatspeech.com/VideoEdit"
      />
      <LeftBar />
      <div
        style={{ paddingTop: "73px" }}
        className="animate__animated animate__slideInLeft"
      >
        <div className="VideoEditSection">
          <p>Video Page</p>
        </div>
      </div>
    </>
  );
}
