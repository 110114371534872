import Header2 from "../../components/header/Header2";
import LeftBar from "../../components/leftBar/LeftBar";
import { useEffect } from "react";
import Refresh from "../../elements/refresh";
import SubscribePlan from "../SubscribePlan";
import "./style.css";
import SEO from "layout/nextseo";

export default function Upgrade(props) {
  useEffect(() => {
    // Refresh()
  }, []);
  let isLoggedIn = props.isLoggedIn;
  return (
    <>
      <Header2 title="Upgrade" />
      <SEO
        title="Bayanat Speech | Upgrade"
        canonicalLink="https://bayanatspeech.com/Upgrade"
      />

      <LeftBar />
      <div className="animate__animated animate__slideInUp UpgradeSection">
        <SubscribePlan isLoggedIn={isLoggedIn} noHeader={true} />
      </div>
    </>
  );
}
