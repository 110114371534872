import './style.css';


export default function TranslateList(props) {
    return (
        <>
            <div className='tdiv'> 
             <select onClick={props.onClick} className='sel TranslateSel' name="Language" defaultValue={props.isArabic ? 'Arabic' : 'English'}> 
                 <option value="Arabic">Arabic</option>
                    <option value="Albanian">Albanian</option>
                    <option value="Afrikaans">Afrikaans</option>
                    <option value="Amharic">Amharic</option>
                    <option value="Armenian">Armenian</option>
                    <option value="Azerbaijani">Azerbaijani</option>
                    <option value="Basque">Basque</option>
                    <option value="Belarusian">Belarusian</option>
                    <option value="Bengali">Bengali</option>
                    <option value="Bosnian">Bosnian</option>
                    <option value="Bulgarian">Bulgarian</option>
                    <option value="Catalan">Catalan</option>
                    <option value="Cebuano">Cebuano</option>
                    <option value="Chinese">Chinese</option>
                    <option value="Corsican">Corsican</option>
                    <option value="Croatian">Croatian</option>
                    <option value="Czech">Czech</option>
                    <option value="Danish">Danish</option>
                    <option value="Dutch">Dutch</option>
                    <option value="English">English</option>
                    <option value="Esperanto">Esperanto</option>
                    <option value="Estonian">Estonian</option>
                    <option value="Finnish">Finnish</option>
                    <option value="French">French</option>
                    <option value="Frisian">Frisian</option>
                    <option value="Galician">Galician</option>
                    <option value="Georgian">Georgian</option>
                    <option value="German">German</option>
                    <option value="Greek">Greek</option>
                    <option value="Gujarati">Gujarati</option>
                    <option value="Haitian Creole">Haitian Creole</option>
                    <option value="Hausa">Hausa</option>
                    <option value="Hawaiian">Hawaiian</option>
                    <option value="Hebrew">Hebrew</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Hmong">Hmong</option>
                    <option value="Hungarian">Hungarian</option>
                    <option value="Icelandic">Icelandic</option>
                    <option value="Igbo">Igbo</option>
                    <option value="Indonesian">Indonesian</option>
                    <option value="Irish">Irish</option>
                    <option value="Italian">Italian</option>
                    <option value="Japanese">Japanese</option>
                    <option value="Javanese">Javanese</option>
                    <option value="Kannada">Kannada</option>
                    <option value="Kazakh">Kazakh</option>
                    <option value="Khmer">Khmer</option>
                    <option value="Kinyarwanda">Kinyarwanda</option>
                    <option value="Korean">Korean</option>
                    <option value="Kurdish">Kurdish</option>
                    <option value="Kyrgyz">Kyrgyz</option>
                    <option value="Lao">Lao</option>
                    <option value="Latin">Latin</option>
                    <option value="Latvian">Latvian</option>
                    <option value="Lithuanian">Lithuanian</option>
                    <option value="Luxembourgish">Luxembourgish</option>
                    <option value="Macedonian">Macedonian</option>
                    <option value="Malagasy">Malagasy</option>
                    <option value="Malay">Malay</option>
                    <option value="Malayalam">Malayalam</option>
                    <option value="Malayalam">Maltese</option>
                    <option value="Marathi">Marathi</option>
                    <option value="Mongolian">Mongolian</option>
                    <option value="Maori">Maori</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nepali">Nepali</option>
                    <option value="Norwegian">Norwegian</option>
                    <option value="Nyanja">Nyanja</option>
                    <option value="Odia">Odia</option>
                    <option value="Pashto">Pashto</option>
                    <option value="Persian">Persian</option>
                    <option value="Polish">Polish</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Romanian">Romanian</option>
                    <option value="Russian">Russian</option>
                    <option value="Samoan">Samoan</option>
                    <option value="Scots Gaelic">Scots Gaelic</option>
                    <option value="Serbian">Serbian</option>
                    <option value="Sesotho">Sesotho</option>
                    <option value="Shona">Shona</option>
                    <option value="Sindhi">Sindhi</option>
                    <option value="Sinhala">Sinhala</option>
                    <option value="Slovak">Slovak</option>
                    <option value="Slovenian">Slovenian</option>
                    <option value="Somali">Somali</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Sundanese">Sundanese</option>
                    <option value="Swahili">Swahili</option>
                    <option value="Swedish">Swedish</option>
                    <option value="Tagalog">Tagalog</option>
                    <option value="Tajik">Tajik</option>
                    <option value="Tamil">Tamil</option>
                    <option value="Tatar">Tatar</option>
                    <option value="Telugu">Telugu</option>
                    <option value="Thai">Thai</option>
                    <option value="Turkish">Turkish</option>
                    <option value="Turkmen">Turkmen</option>
                    <option value="Urdu">Urdu</option>
                    <option value="Uyghur">Uyghur</option>
                    <option value="Uzbek">Uzbek</option>
                    <option value="Vietnamese">Vietnamese</option>
                    <option value="Welsh">Welsh</option>
                    <option value="Xhosa">Xhosa</option>
                    <option value="Yiddish">Yiddish</option>
                    <option value="Yoruba">Yoruba</option>
                    <option value="Zulu">Zulu</option>
                </select> 
             </div>
        </>
    )
}