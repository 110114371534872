import React, { useState } from "react";
import "./style.scss";
import Switch from "react-switch";

export default function Header2(props) {
  // const [sideLeftSideBarToogle, setLeaftSideBarToggle] = useState(true);

  const [isToggle, setIsToggle] = useState(false);

  const sidebarToggle = () => {
    setIsToggle(!isToggle);
    document.body.classList.add("sidebar-open");
  };

  return (
    <div className="Header2">
      {/* <p onClick={() => setLeaftSideBarToggle(false)}>Click</p> */}
      <div className="sidetoggle-open" onClick={sidebarToggle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </div>
      <p className="p1" style={!props.checked ? { color: "#FF9900" } : null}>
        {props.title}
      </p>
      {props.tik && (
        <Switch
          className="switchIcon"
          onChange={props.handleChange}
          checked={props.checked}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={"#FF9900"}
        />
      )}
      <p className="p2" style={props.checked ? { color: "#FF9900" } : null}>
        {props.title2}
      </p>
    </div>
  );
}
