import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useState } from "react";
import axios from "axios";
import { config } from "../../elements/config";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Toaster from "../../components/toaster/toster-component";
import SEO from "layout/nextseo";

export default function ResetPassword() {
  const history = useHistory();
  let [passwordData, setPasswordData] = useState({
    password: "",
    re_password: "",
  });
  let [isSame, setIsSame] = useState(null);
  let [toaster, setToaster] = useState();

  function onChangePassword(e) {
    if (!isSame) {
      console.log(`change password`);
    }
    setPasswordData({ ...passwordData, password: e.currentTarget.value });
  }
  function onChangeRePassword(e) {
    if (passwordData.password !== e.currentTarget.value) {
      setIsSame(false);
    } else {
      setIsSame(true);
    }
    setPasswordData({ ...passwordData, re_password: e.currentTarget.value });
  }

  function handleSubmit() {
    if (isSame) {
      axios
        .patch(`${config.url}auth/password-reset-complete`, {
          password: passwordData.password,
          token,
          uidb64,
        })
        .then((res) => {
          if (res.status === 200) {
            setToaster(true);
            setTimeout(() => {
              history.push("/login");
            }, [2000]);
          } else {
            history.push("/forgotPassword");
          }
        })
        .catch((err) => console.log(err));
    }
  }
  let myNewLink = new URLSearchParams(window.location.href);
  let token, uidb64;
  for (let [key, value] of myNewLink) {
    if (key === `uidb64`) {
      uidb64 = value;
    }
    if (key === `token`) {
      token = value;
    }
  }

  return (
    <>
      <div className="signUp">
        <Header />
        <SEO title="Bayanat Speech | ResetPassword" />
        <div style={{ paddingTop: "78px" }}>
          <div className="signUpSize">
            {toaster ? (
              <Toaster message={"Your Password is Successfully Reset."} />
            ) : (
              ""
            )}
            <h5 className="signUpTitle">Reset Your Password</h5>
            <p className="signUpText">Password</p>
            <input
              type="password"
              className="signupInput"
              placeholder="******"
              onChange={onChangePassword}
            />
            <p className="signUpText">Retype Password</p>

            <input
              type="password"
              className="signupInput"
              placeholder="******"
              onChange={onChangeRePassword}
            />
            <button className="signUpBtn" onClick={handleSubmit}>
              Login
            </button>
            <p className="Alreadyavenaccount RP">
              Don't Have An Account?<NavLink to="/signUp">Sign Up</NavLink>{" "}
            </p>
            <p className={!isSame ? "errorMessageNew" : "successMessageNew"}>
              {isSame == null
                ? ""
                : isSame
                ? "Passwords are the same"
                : "Passwords are not same"}
            </p>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
