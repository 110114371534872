import { Toast } from "react-bootstrap";
import "./toster.style.css";

const Toaster = ({
  message,
  show,
  setShow,
  backgroundColor = "green",
  color = "#fff",
}) => {
  return (
    <div
      className="c_toast"
      style={{ backgroundColor: backgroundColor, color: color }}
    >
      <Toast
        show={show}
        delay={150}
        autohide
      >
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
};
export default Toaster;
