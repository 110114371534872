import axios from "axios";
import SEO from "layout/nextseo";
import { useState } from "react";
import Header2 from "../../components/header/Header2";
import LeftBar from "../../components/leftBar/LeftBar";
import { config } from "../../elements/config";
import "./style.css";

export default function MyAccount(props) {
  const googleUserData = JSON.parse(localStorage.getItem("GoogleUserData"));
  let userData =
    JSON.parse(window.sessionStorage.getItem(`data`)) ||
    JSON.parse(window.localStorage.getItem(`data`));
  let [isClosed, setIsClosed] = useState(false);
  let [isSuccessChange, setIsSuccessChange] = useState("");
  let [errorMsg, setErrorMsg] = useState("");
  let [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  let [isEditing, setIsEditing] = useState(false);
  let [isPasswordChanging, setIsPasswordChanging] = useState(false);
  let [editData, setEditData] = useState({
    first_name: googleUserData?.first_name || userData?.first_name,
    last_name: googleUserData?.last_name || userData?.last_name,
    phone_number: googleUserData?.phone_number || userData?.phone_number,
  });
  let [changePasswordData, setChangePasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    reNewPassword: "",
  });
  let staticEditData = {
    first_name: googleUserData?.first_name || userData?.first_name,
    last_name: googleUserData?.last_name || userData?.last_name,
    phone_number: googleUserData?.phone_number || userData?.phone_number,
  };
  let staticChangePasswordData = {
    oldPassword: "",
    newPassword: "",
    reNewPassword: "",
  };
  let handleChange = (e) => {
    console.log(e.target.files[0]);
    let a = new FormData();
    a.append(`image`, e.target.files[0]);
    console.log(a);
    axios
      .put(`${config.url}api/users/${userData.id}/`, a, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${
            window.localStorage.getItem(`access`) ||
            window.sessionStorage.getItem(`access`)
          }`,
        },
      })
      .then((res) => {
        window.localStorage.setItem(`data`, JSON.stringify(res.data));
        let imgTab = document.getElementsByClassName(`MyAccountImg`)[0];
        imgTab.src = `${config.url}media/${e.target.files[0].name}`;
      });
  };
  let sendEditData = () => {
    setIsClosed(true);
    axios
      .put(
        `${config.url}api/users/${userData.id}/`,
        {
          first_name: editData.first_name,
          last_name: editData.last_name,
          phone_number: editData.phone_number,
        },
        {
          headers: {
            Authorization: `Bearer ${
              window.localStorage.getItem(`access`) ||
              window.sessionStorage.getItem(`access`)
            }`,
          },
        }
      )
      .then((res) => {
        window.localStorage.setItem(`data`, JSON.stringify(res.data));
        let accName = document.getElementsByClassName(`MyAccountFullName`)[0];
        let accPhone =
          document.getElementsByClassName(`MyAccountPhoneNumber`)[0];
        accName.innerHTML = `${res.data.first_name} ${res.data.last_name}`;
        accPhone.innerHTML = `${res.data.phone_number}`;
        setErrorMsg("");
        setIsEditing(false);
        setIsClosed(false);
      })
      .catch((err) => {
        setErrorMsg(`Failed to change phone number`);
      });
  };

  let sendPasswordData = () => {
    setIsClosed(true);
    if (
      changePasswordData.newPassword === changePasswordData.reNewPassword &&
      changePasswordData.oldPassword !== changePasswordData.newPassword
    ) {
      setPasswordErrorMsg("");
      axios
        .patch(
          `${config.url}auth/password-change/`,
          {
            old_password: changePasswordData.oldPassword,
            new_password: changePasswordData.newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${
                window.localStorage.getItem(`access`) ||
                window.sessionStorage.getItem(`access`)
              }`,
            },
          }
        )
        .then((res) => {
          setPasswordErrorMsg("");
          setIsSuccessChange(true);
          setIsPasswordChanging(false);
          setIsClosed(false);
        })
        .catch((err) => {
          setPasswordErrorMsg(err.response.data.old_password[0]);
        });
    } else if (
      changePasswordData.newPassword === changePasswordData.reNewPassword
    ) {
      setPasswordErrorMsg(`Passwords do not match`);
      setIsClosed(false);
    } else if (
      changePasswordData.oldPassword !== changePasswordData.newPassword
    ) {
      setPasswordErrorMsg(`Old and New Password cannot be same`);
      setIsClosed(false);
    }
  };

  return (
    <>
      <div
        style={isSuccessChange ? { display: `block` } : { display: "none" }}
        className="MyAccoutAll"
      >
        <div className="MyAccountPopup animate__animated animate__flipInY">
          <p className="MyAccountPopupP1">Password successfully changed</p>
          <p className="MyAccountPopupP2">Would you like to Log out ? </p>
          <div className="MyAccountPopupSelect">
            <p
              onClick={() => {
                window.localStorage.clear();
                window.sessionStorage.clear();
                window.location.href = "./";
              }}
            >
              Sure
            </p>
            <p
              onClick={() => {
                setIsSuccessChange(false);
              }}
            >
              No, Thanks
            </p>
          </div>
        </div>
      </div>
      <Header2 title="My Account" />
      {/* */}
      <LeftBar />
      <SEO title="Bayanat Speech | My Account" />

      <div
        style={{ paddingTop: "73px" }}
        className="animate__animated animate__slideInLeft"
      >
        <div className="MyAccountSection">
          <div className="MyAccountAll">
            <div>
              <input
                style={{ display: "none" }}
                id="imgUpload"
                type="file"
                onChange={handleChange}
              />
              <img
                className="MyAccountImg"
                src={userData?.image || ""}
                alt=""
              />
              <label htmlFor="imgUpload">
                <div className="MyAccountAddImg">+</div>
              </label>
            </div>
            <div className="MyAccountData">
              <p className="MyAccountFullName">{`${
                userData?.first_name || googleUserData?.first_name
              } ${userData?.last_name || googleUserData?.last_name}`}</p>
              <div>
                <div className="MyAccountDataSlash"></div>
              </div>
              <p style={{ opacity: 0.4 }}>
                {userData?.email || googleUserData?.email}
              </p>
              <p style={{ opacity: 0.4 }} className="MyAccountPhoneNumber">
                {userData?.phone_number || googleUserData?.full_name}
              </p>
              <div
                className="MyAccountDataHandleSection"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  lineHeight: 2,
                }}
              >
                <p
                  style={isEditing ? { color: "#FF9900" } : null}
                  className="MyAccountDataEdit"
                  onClick={() => {
                    setIsEditing(true);
                    setIsPasswordChanging(false);
                  }}
                >
                  Edit Info
                </p>
                <p
                  style={isPasswordChanging ? { color: "#FF9900" } : null}
                  className="MyAccountDataChangePassword"
                  onClick={() => {
                    setIsPasswordChanging(true);
                    setIsEditing(false);
                  }}
                >
                  Change Password
                </p>
              </div>
            </div>
          </div>
          <div className="MyAccountOpened">
            {isEditing && (
              <div
                className={`MyAccountOpenedEdit ${
                  isEditing && "animate__animated animate__slideInLeft"
                } ${isClosed && "animate__animated animate__slideOutLeft"}`}
              >
                <div className="MyAccountOpenedSlash"></div>
                <p className="EditPersonalInfo">Edit Personal Information</p>
                <p className="signUpText MyAccountEmail">First Name</p>
                <input
                  className="signupInput MyAccountInput"
                  defaultValue={userData?.first_name}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      first_name: e.currentTarget.value,
                    });
                  }}
                />
                <p className="signUpText MyAccountEmail">Last Name</p>
                <input
                  className="signupInput MyAccountInput"
                  defaultValue={userData?.last_name}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      last_name: e.currentTarget.value,
                    });
                  }}
                />
                <p className="signUpText  MyAccountPhone">Phone Number</p>
                <input
                  className="signupInput MyAccountInput"
                  defaultValue={userData?.phone_number}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      phone_number: e.currentTarget.value,
                    });
                  }}
                />
                <p className="errorMessage errorMessage2">{errorMsg}</p>
                <div className="MyAccountHandleButtons">
                  <button
                    className="signUpBtn MyAccountButton1"
                    onClick={() => {
                      setIsClosed(true);
                      setTimeout(() => {
                        setIsClosed(false);
                        setIsEditing(false);
                        setEditData(staticEditData);
                      }, 500);
                    }}
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    className="signUpBtn MyAccountButton2"
                    onClick={sendEditData}
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            )}
            {isPasswordChanging && (
              <div
                className={`MyAccountOpenedEdit ${
                  isPasswordChanging && "animate__animated animate__slideInLeft"
                } ${isClosed && "animate__animated animate__slideOutLeft"}`}
              >
                <div className="MyAccountOpenedSlash"></div>
                <p className="EditPersonalInfo">Change Password</p>
                <p className="signUpText MyAccountEmail">Old Password</p>
                <input
                  type="password"
                  className="signupInput MyAccountInput"
                  placeholder="Old Password"
                  onChange={(e) => {
                    setChangePasswordData({
                      ...changePasswordData,
                      oldPassword: e.currentTarget.value,
                    });
                  }}
                />
                <p className="signUpText  MyAccountPhone">New Password</p>
                <input
                  type="password"
                  className="signupInput MyAccountInput"
                  placeholder="New Password"
                  onChange={(e) => {
                    setChangePasswordData({
                      ...changePasswordData,
                      newPassword: e.currentTarget.value,
                    });
                  }}
                />
                <p className="signUpText  MyAccountPhone">
                  Retype New Password
                </p>
                <input
                  type="password"
                  className="signupInput MyAccountInput"
                  placeholder="Retype New Password"
                  onChange={(e) => {
                    setChangePasswordData({
                      ...changePasswordData,
                      reNewPassword: e.currentTarget.value,
                    });
                  }}
                />
                <p className="errorMessage errorMessage2">{passwordErrorMsg}</p>
                <div className="MyAccountHandleButtons">
                  <button
                    className="signUpBtn MyAccountButton1"
                    onClick={() => {
                      setIsClosed(true);
                      setTimeout(() => {
                        setIsClosed(false);
                        setIsPasswordChanging(false);
                        setChangePasswordData(staticChangePasswordData);
                        setPasswordErrorMsg("");
                      }, 500);
                    }}
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    className="signUpBtn MyAccountButton2"
                    onClick={sendPasswordData}
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
