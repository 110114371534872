import { ClimbingBoxLoader } from "react-spinners";
import "./style.css";

export default function Loading(props) {
  return (
    <div className="loadingBox">
      <ClimbingBoxLoader loading={true} size={props.size} />
    </div>
  );
}
