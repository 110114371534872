export const ProtectedRoute = [
  "/texttospeech",
  "/ocr",
  "/translate",
  "/createsteganography",
  "/myaccount",
  "/history",
  "/upgrade",
];
export const UnProtectedRoute = ["/login", "/signup", "/forgotpassword"];
