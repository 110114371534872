import React, { useState, useEffect } from "react";
import "./style.css";
import LeftBar from "../../components/leftBar/LeftBar";
import Header2 from "../../components/header/Header2";
import Example1 from "../../components/example1/example1";
import Example2 from "../../components/example2/example2";
import Refresh from "../../elements/refresh";
import SEO from "layout/nextseo";

export default function CreateSteganography() {
  // const [leftSideBarToggle,setLeftSideBarToggle]=useState(true);
  // const [leftSideBarToggle,setLeftSideBarToggle]=useState(true);
  useEffect(() => {
    // Refresh()
  }, []);
  let [checked, setChecked] = useState(false);
  let handleChange = () => {
    setChecked(!checked);
  };
  return (
    <>
      <Header2
        title="Create Steganography"
        tik={true}
        title2="Extract From steganography"
        checked={checked}
        handleChange={handleChange}
      />
      <SEO
        canonicalLink="https://bayanatspeech.com/createSteganography"
        title="Bayanat Speech | Create Steganography"
      />

      <LeftBar />
      <div style={{ paddingTop: "73px" }}>
        {!checked ? (
          <Example1 />
        ) : (
          <>
            {" "}
            <Example2 />{" "}
          </>
        )}
      </div>
    </>
  );
}
