import axios from "axios";
import SEO from "layout/nextseo";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../elements/config";
import "./style.css";

export default function Payment() {
  let history = useHistory();
  let myNewLink = new URLSearchParams(window.location.search);
  let paymentId;
  for (let [key, value] of myNewLink) {
    if (key === `subscription_id`) {
      paymentId = value;
    }
  }
  useEffect(async () => {
    if (paymentId.length !== 0) {
      let status = await axios.post(
        `${config.url}payments/confirm-payment-intent/`,
        {
          payment_intent_id: paymentId,
        },
        {
          headers: {
            Authorization: `Bearer ${
              window.localStorage.getItem(`access`) ||
              window.sessionStorage.getItem(`access`)
            }`,
          },
        }
      );
      if (status?.data?.status === "ACTIVE") {
        history.push("/TextToSpeech");
      } else {
        history.push("/");
      }
    }
  });
  return (
    <>
      <SEO title="Bayanat Speech | Payment" />
      <div className="paymentBox">
        <div class="loader-container">
          <div class="loader-icon"></div>
        </div>
        <p className="paymentTitle">Please wait for a moment </p>
      </div>
    </>
  );
}
