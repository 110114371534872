import { useState } from "react";
import axios from "axios";
import "./style.css";
import { config } from "../../elements/config";

export default function ContactForm() {
  let [userData, setUserData] = useState({
    id: "",
    name: "",
    email: "",
    subject: "",
    description: "",
  });
  let [submitMessage, setSubmitMessage] = useState("");
  function handleSubmit() {
    axios
      .post(`${config.url}contact_us/`, {
        name: userData.name,
        email: userData.email,
        subject: userData.subject,
        description: userData.description,
      })
      .then(setSubmitMessage(`Your message was sent successfully`))
      .catch((rej) => console.log(rej));
  }
  return (
    <>
      <div className="ContactFormMain">
        <h1>Contact Us</h1>
        <p>We are here to Help and answer any Questions </p>
        <div className="ContactFormInfo">
          <input
            type="text"
            placeholder="Name"
            onChange={(e) =>
              setUserData({ ...userData, name: e.currentTarget.value })
            }
          ></input>
          <input
            type="text"
            placeholder="Email Address"
            onChange={(e) =>
              setUserData({ ...userData, email: e.currentTarget.value })
            }
          ></input>
        </div>
        <input
          type="text"
          placeholder="Subject"
          className="ContactFormSubject"
          onChange={(e) =>
            setUserData({ ...userData, subject: e.currentTarget.value })
          }
        ></input>
        <textarea
          type="text"
          placeholder="Write your message here"
          className="ContactFormMessage"
          onChange={(e) =>
            setUserData({ ...userData, description: e.currentTarget.value })
          }
        ></textarea>
        <button className="ContactFormButton" onClick={handleSubmit}>
          Submit
        </button>
        <p className="submitMessage">{submitMessage}</p>
      </div>
    </>
  );
}
