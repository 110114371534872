import { NavLink } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./style.css";
import googleicon from "../../assets/images/g.png";
import facebookicon from "../../assets/images/f.png";
import { useState } from "react";
import axios from "axios";
import { config } from "../../elements/config";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router-dom";
import SEO from "layout/nextseo";
// import SEO from "layout/nextseo";

export default function Login(props) {
  let [googleId, setTokenIdGoogle] = useState("");
  let [fbId, setTokenIdFb] = useState("");
  let history = useHistory();
  let [dontKeep, setDontKeep] = useState(true);
  let [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  let [fetchError, setFetchError] = useState({
    email: "",
    password: "",
    detail: "",
  });
  let staticFetchError = {
    email: "",
    password: "",
    detail: "",
  };

  let changeKeep = () => {
    setDontKeep((t) => !t);
  };
  let handleSubmit = async () => {
    await axios
      .post(`${config.url}auth/login/`, {
        email: loginData.email,
        password: loginData.password,
      })
      .then(async (res) => {
        await axios
          .get(`${config.url}api/users/me/`, {
            headers: {
              Authorization: `Bearer ${res.data.tokens.access}`,
            },
          })
          .then((res) => {
            console.log("res", res);
            dontKeep
              ? window.sessionStorage.setItem(`data`, JSON.stringify(res.data))
              : window.localStorage.setItem(`data`, JSON.stringify(res.data));
            localStorage.setItem("loginType", "normal");
          });
        dontKeep
          ? window.sessionStorage.setItem(`access`, res.data.tokens.access)
          : window.localStorage.setItem(`access`, res.data.tokens.access);
        dontKeep
          ? window.sessionStorage.setItem(`refresh`, res.data.tokens.refresh)
          : window.localStorage.setItem(`refresh`, res.data.tokens.refresh);
        if (dontKeep) {
          window.sessionStorage.setItem(`dontKeep`, dontKeep);
        }
        history.push("/TextToSpeech");
        setFetchError(staticFetchError);
      })
      .catch((rej) => {
        setFetchError(rej.response.data);
      });
  };

  function googleLogin(res) {
    axios
      .post(`${config.url}auth/dj-rest-auth/google/`, {
        access_token: res.accessToken,
      })
      .then((response) => {
        setTokenIdGoogle(response.data.access_token);
        axios
          .get(`${config.url}api/users/me/`, {
            headers: {
              Authorization: `Bearer ${
                googleId || fbId || response.data.access_token
              }`,
            },
          })
          .then((meData) => {
            localStorage.setItem(
              "GoogleUserData",
              JSON.stringify(meData?.data)
            );
            localStorage.setItem("access", response.data.access_token);
            localStorage.setItem("loginType", "google");
            history.push("/TextToSpeech");
            // axios
            //   .post(`${config.url}auth/token/refresh/`, {
            //     refresh: response.data.refresh_token,
            //   })
            //   .then((userAccess) => {
            //     sessionStorage.setItem(`access`, userAccess.data.access);
            //     localStorage.setItem(`refresh`, userAccess.data.access);
            //     localStorage.setItem("googleLoggedIn", "logged");
            //     setTokenIdGoogle(userAccess.data.access);
            //     history.replace("./TextToSpeech");
            //   });
          });
      });
  }

  function facebookLogin(res) {
    axios
      .post(`${config.url}auth/dj-rest-auth/facebook/`, {
        access_token: res.accessToken,
      })
      .then((response) => {
        setTokenIdFb(response.data.access_token);
        axios
          .get(`${config.url}api/users/me/`, {
            headers: {
              Authorization: `Bearer ${
                googleId || fbId || response.data.access_token
              }`,
            },
          })
          .then((meData) => {
            localStorage.setItem(
              "FacebookUserData",
              JSON.stringify(meData?.data)
            );
            localStorage.setItem("access", response.data.access_token);
            localStorage.setItem("loginType", "facebook");
            history.push("/TextToSpeech");
            // axios
            //   .post(`${config.url}auth/token/refresh/`, {
            //     refresh: response.data.refresh_token,
            //   })
            //   .then((userAccess) => {
            //     sessionStorage.setItem(`access`, userAccess.data.access);
            //     localStorage.setItem(`refresh`, userAccess.data.access);
            //     localStorage.setItem("googleLoggedIn", "logged");
            //     setTokenIdFb(userAccess.data.access);
            //     history.replace("./TextToSpeech");
            //   });
          });
      });
  }

  return (
    <div className="signUp">
      <Header />
      <SEO title="Bayanat Speech | Login" />
      <div style={{ paddingTop: "78px" }}>
        <div className="signUpSize">
          <h5 className="signUpTitle">Login</h5>
          <p className="signUpText">Email Address</p>
          <input
            style={
              fetchError.email === ""
                ? { "border-color": "#B2B2B2" }
                : { "border-color": "red" }
            }
            className={`signupInput ${
              fetchError.email !== "" ? "animate__animated animate__shakeX" : ""
            }`}
            placeholder="Email Address"
            onChange={(e) =>
              setLoginData({ ...loginData, email: e.target.value })
            }
          />
          <p className="errorMessage">{fetchError.email}</p>
          <p className="signUpText">Password</p>
          <input
            style={
              fetchError.password === ""
                ? { "border-color": "#B2B2B2" }
                : { "border-color": "red" }
            }
            type="password"
            className={`signupInput ${
              fetchError.password !== ""
                ? "animate__animated animate__shakeX"
                : ""
            }`}
            placeholder="Password"
            onChange={(e) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
          />
          <p className="errorMessage">{fetchError.password}</p>
          <div className="Ireadandaccept">
            <input type="checkbox" onChange={changeKeep} />
            <p>Keep Me Logged In</p>
            <NavLink to="forgotPassword">Forgot Password ?</NavLink>
          </div>
          <p className="errorMessage"> {fetchError.detail} </p>
          <button className="signUpBtn" onClick={handleSubmit}>
            Login
          </button>
          <p className="orsignupwith">or sign up with</p>
          <div className="signUpSocBtns">
            <GoogleLogin
              render={(renderProps) => (
                <div
                  className="signUpSocBtn"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img src={googleicon} alt="icon1" />
                  <p>Google</p>
                </div>
              )}
              clientId="352606531076-0drt3a1ssjju2ralnu12bbmaqkdd1lsp.apps.googleusercontent.com"
              onSuccess={googleLogin}
              onFailure={() => console.log(`Google Login Failed`)}
            />
            <FacebookLogin
              appId="1108975349576520"
              fields="name,email,picture"
              callback={facebookLogin}
              icon={
                <div className="signUpSocBtn signUpSocBtn2">
                  <img src={facebookicon} alt="icon1" />
                  <p>Facebook</p>
                </div>
              }
              cssClass="fbBtn"
              textButton=""
            />
          </div>
          <p className="Alreadyavenaccount">
            Don't Have An Account?<NavLink to="/signUp">Sign Up</NavLink>{" "}
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
}
