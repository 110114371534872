import "./style.css";
import pic1 from "../../assets/images/play.png";
import pic2 from "../../assets/images/download.png";
import closeIcon from "../../assets/images/closeIcon.png";
import addtrackIcon from "../../assets/images/addtrack.png";
import circleIcon from "../../assets/images/circle.png";
import filesIcon from "../../assets/images/files.png";
import Speech from "speak-tts";
import Iframe from "../../components/Iframe/Iframe";

const speech = new Speech();

export default function Win3(props) {
  let copyToClipboard = () => {
    let el = document.querySelector(`iframe`);
    let iframeDocument = el.contentDocument || el.contentWindow.document;
    console.log(iframeDocument);
  };
  document.addEventListener("DOMContentLoaded", copyToClipboard);

  return (
    <>
      <div className="smallWindow">
        <div className="swG">
          <div>
            {props.cross ? (
              <div className="xxx">
                <img
                  onClick={() => {
                    props.closeWin();
                    speech.cancel();
                  }}
                  className="img1"
                  src={closeIcon}
                  alt="icon1"
                ></img>
              </div>
            ) : null}
            {!props.dontPlay && (
              <div className="xxx">
                <img
                  className="img1"
                  src={pic1}
                  alt="pic1"
                  onClick={props.convert}
                ></img>
              </div>
            )}
            {!props.dontCircle && (
              <div className="xxx">
                <img
                  className="img3"
                  src={circleIcon}
                  alt="pic2"
                  onClick={props.refresh}
                ></img>
              </div>
            )}
            {props.noicon ? null : (
              <div className="xxx" onClick={copyToClipboard}>
                <img className="img4" src={filesIcon} alt="filespic"></img>
              </div>
            )}
            {props.addtrack ? (
              <div className="xxx longdiv">
                <img className="img1" src={addtrackIcon} alt="addpic"></img>
                <p>Add Track</p>
              </div>
            ) : null}
          </div>
          {props.nodownload ? null : props.addfile ? (
            <div className="xxx longdiv2">
              <p>Join Files</p>
            </div>
          ) : (
            <div className="divlast" onClick={props.download}>
              <p>Save & Download</p>
              <img className="img2" src={pic2} alt="pic22"></img>
            </div>
          )}
        </div>
        <div className="swW" style={{ height: props.height }}>
          {props.linkSpace ? (
            <div>
              <Iframe link={props.link} />
            </div>
          ) : (
            <div className="content">
              <textarea
                className="speechText"
                placeholder={props.text}
                value={props.innerText}
              ></textarea>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
