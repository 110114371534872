import './style.css'
import logo from '../../assets/images/logo2.png'
import icon1 from '../../assets/images/soc/1.png'
import icon2 from '../../assets/images/soc/2.png'
import icon3 from '../../assets/images/soc/3.png'
import icon4 from '../../assets/images/soc/4.png'
import ContactForm from '../../components/ContactForm/ContactForm'
import { Link } from "react-router-dom";


export default function Footer(props) {

    return (
        <footer>
            <div className='size footerSize'>
                <div className="footer-top">
                    <img src={logo} alt='logo' />
                    <p className='Copyright'>© Copyright 2022 - Bayanatai - All rights reserved</p>
                </div>
                <div className='footerRight'>
                    <div className='footerRightBox'>
                        <h6>Company</h6>

                        <p><Link to={'/SubscribePlan'} className='footerRightBox__link'>Subscribe Plan</Link></p>
                        <p><Link to={'/ContactUs'} className='footerRightBox__link'>Contact Us</Link></p>
                        <p><Link to={'/faq'} className='footerRightBox__link'>FAQs</Link></p>
                        {/*<p>FAQs</p>*/}
                    </div>
                    <div className='footerRightBox'>
                        <h6>Important</h6>
                        <p><Link to={'/privacy'} className='footerRightBox__link'>Privacy Policy</Link></p>

                        <p><Link to={'/terms'} className='footerRightBox__link'>Terms and Conditions</Link></p>
                    </div>
                    <div className='footerRightBox'>
                        <h6>Follow us</h6>
                        <div className='footersocs'>
                            <img src={icon1} onClick={() => { window.open(`https://www.facebook.com/Bayanatai-100625605635532/`) }} alt='icon1' />
                            <img src={icon2} onClick={() => { window.open(`https://www.instagram.com/bayanatai/`) }} alt='icon2' />
                            <img src={icon3} onClick={() => { window.open(`https://www.linkedin.com/company/bayanat-ai`) }} alt='icon3' />

                        </div>
                    </div>
                </div>
                <div className="for-mobile">
                    <p className='Copyright'>© Copyright 2022 - Bayanatai - All rights reserved</p>
                </div>
            </div>
        </footer>
    )
}
