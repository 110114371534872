import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import mailPic from "../../assets/images/mail.png";
import ContactForm from "../../components/ContactForm/ContactForm";
import SEO from "layout/nextseo";

export default function ContactUs(props) {
  return (
    <>
      <Header isLoggedIn={props.isLoggedIn} />
      <SEO
        title="Bayanat Speech | Contact us"
        canonicalLink="https://bayanatspeech.com/ContactUs"
      />

      <div style={{ paddingTop: "78px" }}>
        <div className="ContactUsMain">
          <div className="ContactUsFirst">
            <div className="ContactUsForm">
              <ContactForm />
            </div>
          </div>
          <div className="ContactUsSecond">
            <img src={mailPic} alt="icon1"></img>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
