import './style.css'
import { useState, useEffect } from 'react';
import Step1 from '../steps/Step1/Step1';
import Step2 from '../steps/Step2/Step2';
import Step3 from '../steps/step3/Step3';
import axios from 'axios';
import { config } from '../../elements/config';
import fileDownload from 'js-file-download';
import Loading from '../../elements/Loading';

export default function Example1(props) {
    let [isSameName,setIsSameName] = useState(false)
    let [isError, setIsError] = useState(false)
    let [isErrorDone, setIsErrorDone] = useState(false)
    useEffect(() => {
        isError && setTimeout(() => {
            setIsErrorDone(!isErrorDone)
        }, 2000);
    }, [isError])
    if (isErrorDone === true) {
        setTimeout(() => {
            setIsError(false)
            setIsErrorDone(false)
        }, 2000);
    }
    let [time,setTime] = useState()
    let [audioData, setAudioData] = useState()
    let [msgData, setMsgData] = useState()
    let [step, setStep] = useState(1)
    let [downloadUrl, setDownloadUrl] = useState()
    let btntext;
    step === 1 ? btntext = 'Convert and Continue' : step === 2 ? btntext = 'Mix files' : btntext = 'Save and download the file';
    let [isLoading, setIsLoading] = useState(false)
    function mixFiles() {
        setTime(Date.now())
        let a = new FormData()
        a.append(`original`, audioData)
        a.append(`secret`, msgData)
        a.append(`name`, `${Date.now()}`)
        setIsLoading(true)
        axios.post(`${config.url}convert/steganography-hide/`, a, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${window.localStorage.getItem(`access`) || window.sessionStorage.getItem(`access`)}`
            }
        }).then((res) => {
            setStep(step + 1)
            setDownloadUrl(`${config.url}media/${res.data.path}`)
            setIsLoading(false)
        })
            .catch(err => {
                if(err.response.status === 400){
                    setIsSameName(true)
                }
                setIsLoading(false)
                setIsError(true)
            })
    }
    function download() {
        axios.get(downloadUrl, {
            responseType: `blob`
        }).then(res => {
            console.log(res);
            fileDownload(res.data, `${time}.wav`)
        })
    }
    let changeStep = () => {
        setStep(step+1)
    }
    return (
        <>
            <section className='sec animate__animated animate__slideInLeft'>
            <div className='stegoWarning warning-line'>
                <p><span>Warning:</span> In order to extract the project you created, Do NOT rename the file</p>
            </div>
                <div style={!isError ? { display: 'none' } : { display: 'flex' }} className={`successSignUp ${isError && 'animate__animated animate__slideInLeft'} ${isErrorDone && 'animate__animated animate__slideOutLeft'} ${isSameName && 'sameNameError'}`}>
                    <p>{isSameName ? 'Project with this name was already created' : 'Something went wrong'}</p>
                </div>
                <div className='newdiv'>
                    <div className='sliderdiv'>
                        <div className='xxx slider' style={step === 1 ? { backgroundColor: '#FF9900', 'color': 'white' } : null}>
                            <p>1</p>
                        </div>
                        <div className='line'></div>
                        <div className='xxx slider' style={step === 2 ? { backgroundColor: '#FF9900', 'color': 'white' } : null}>
                            <p>2</p>
                        </div>
                        <div className='line'></div>
                        <div className='xxx slider' style={step === 3 ? { backgroundColor: '#FF9900', 'color': 'white' } : null}>
                            <p>3</p>
                        </div>
                    </div>
                    {step === 1 ? <Step1 setData={setAudioData} /> : step === 2 ? <Step2 setData={setMsgData} /> : step === 3 ? <Step3 /> : null}
                    <div className='btndiv' style={step === 3 ? { flexDirection: 'column-reverse' } : { flexDirection: 'row' }}>
                        <button className='btn2' style={(step === 2 && !isLoading) ? { display: 'inline' } : { display: 'none' }} onClick={() => { setStep(step - 1);}}> Cancel</button>
                        <button onClick={step === 1 ? audioData && changeStep : step === 2 ? msgData && mixFiles : step === 3 ? download : null} className='btn'>{btntext}</button>
                    </div>
                    {step === 3 && <a href='./createSteganography' className='a1'>Back to the main page</a>}
                    {isLoading && <div className='MixFilesLoading'><Loading size={50} /></div>}
                </div>
            </section>
        </>

    )
}