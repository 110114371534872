import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SEO from "layout/nextseo";

const Faqs = () => {
  return (
    <>
      <Header />
      <SEO
        title="Bayanat Speech | Faqs"
        canonicalLink="https://bayanatspeech.com/faq"
      />
      <section className="footerMenuPages wrapper">
        {/*<ul>*/}
        <h3 className="footerMenuPages__title wrapper">
          What are the BayanatSpeech Features?
        </h3>
        <ul className="footerMenuPages__ul wrapper">
          <li className="footerMenuPages__list">Text to speech service.</li>
          <li className="footerMenuPages__list">Speech to text service.</li>
          <li className="footerMenuPages__list">
            Access to More than 40 Languages.
          </li>
          <li className="footerMenuPages__list">
            More than 100 natural voices can be used.
          </li>
          <li className="footerMenuPages__list">Translation Service</li>
          <li className="footerMenuPages__list">
            OCR, PDF and Text to speech service.
          </li>
          <li className="footerMenuPages__list">
            Convert your OCR and pdf files to text
          </li>
          <li className="footerMenuPages__list">
            {" "}
            Steganography: transmit your hidden audio message in host audio in
            an imperceptible manner. The host audio before steganography and
            stego audio after steganography have the same characteristics.
          </li>
        </ul>
        <h3 className="footerMenuPages__title wrapper">
          What is a personal Plan?
        </h3>
        <p className="footerMenuPages__paragraph wrapper">
          The personal Plan consists of three sub plans (Free, Premium and
          Gold), the main difference between the three plan are in the number of
          allowed features and the number of monthly converted characters, the
          free plan has limited number of characters per month, while the other
          plans allow you to convert up to 1,500,000 characters per month and
          for more than 40 Languages (see the subscription plans for Details)
          You can use our service for private listening. Audio files created
          with the personal-use versions cannot be used publicly (including on
          websites or YouTube videos), or otherwise redistributed in any way
        </p>
        <h3 className="footerMenuPages__title wrapper">
          What is the Commercial Plan?
        </h3>
        <p className="footerMenuPages__paragraph wrapper">
          The Commercial Plan consists of three sub plans (Premium, Gold and
          Platinum), the main difference between the three plan are in the
          number of allowed features and the number of monthly converted
          characters, the Premium plan allows you to convert up to 2,00,00,000
          characters per month, while the other plans allow you to convert up to
          4,000,000 characters per month. The Commercial Pack allows you to use
          our service and voices for business or public use. This includes, but
          is not limited, to: YouTube videos, public announcements, broadcasts,
          and e-Learning. Unlimited download for your audio files. All features
          are available under the Commercial Plan (see the subscription plans
          for Details)
        </p>
        <h3 className="footerMenuPages__title wrapper">
          How do I get started?
        </h3>
        <p className="footerMenuPages__paragraph wrapper">
          Just sign up for free and check out all of our features and
          subscription plans.
        </p>
        <h3 className="footerMenuPages__title wrapper">
          Will my subscription auto-renew?
        </h3>
        <p className="footerMenuPages__paragraph wrapper">
          All of our subscriptions are automatically renewed unless cancelled
          during the subscription period. You will be charged for renewal within
          24 hours prior to the end of the current subscription period. You can
          cancel the auto-renewal at any time, however no refunds are available
          within the active subscription period.
        </p>
        {/*</ul>*/}
      </section>
      <Footer />
    </>
  );
};

export default Faqs;
