import React, { useState, useEffect } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import { config } from "app/elements/config";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import SpeechRecognition from "./speechrecognition";

const mic = new SpeechRecognition();
const useSpeach = (props) => {
  let [subPlan, setSubPlan] = useState({
    mainType: "",
    planName: "",
  });
  const [showLimitPopUp, setShowLimitPopUp] = useState(false);

  let history = useHistory();

  // useEffect(() => {
  //   // Refresh();
  //   if (sessionStorage.getItem("data")) {
  //     console.log(sessionStorage.getItem("data"));
  //   } else {
  //     console.log(localStorage.getItem("data"));
  //   }
  // }, []);
  let dontKeep = window.sessionStorage.getItem(`dontKeep`);
  useEffect(() => {
    axios
      .get(`${config.url}api/users/me/`, {
        headers: {
          Authorization: `Bearer ${
            dontKeep
              ? window.sessionStorage.getItem(`access`)
              : window.localStorage.getItem(`access`)
          }`,
        },
      })
      .then((res) => {
        dontKeep
          ? window.sessionStorage.setItem(`data`, JSON.stringify(res.data))
          : window.localStorage.setItem(`data`, JSON.stringify(res.data));
        setSubPlan({
          mainType: res.data.user_subscription.plan.main_type,
          planName: res.data.user_subscription.plan.name,
        });
        props.setData(true);
      })
      .catch((err) => {
        console.log(err, "error");
        props.setData(false);
      });
    axios
      .get(`${config.url}plans/lettercount/`, {
        headers: {
          Authorization: `Bearer ${
            dontKeep
              ? window.sessionStorage.getItem(`access`)
              : window.localStorage.getItem(`access`)
          }`,
        },
      })
      .then((res) => {
        dontKeep
          ? window.sessionStorage.setItem(
              `lettercount`,
              JSON.stringify(res.data.letterCount)
            )
          : window.localStorage.setItem(
              `lettercount`,
              JSON.stringify(res.data.letterCount)
            );
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);
  console.log(subPlan, `SubPlan`);
  let [upload, setUpload] = useState(false);
  let [isConverting, setIsConverting] = useState(false);
  let [checked, setChecked] = useState(false);
  let handleChange = () => {
    setChecked(!checked);
    setNote("");
  };
  let [micState, setMicState] = useState({ record: false });
  function startRecording() {
    setMicState({ record: true });
  }
  function stopRecording() {
    setMicState({ record: false });
  }
  let name1, name2;
  let [micLang, setMicLang] = useState(`ar-XA`);
  !checked ? (name2 = "Speech Language") : (name2 = "Text Language");
  !checked ? (name1 = "Text Language") : (name1 = "Speech Language");
  mic.continuous = true;
  mic.interimResults = true;
  mic.lang = micLang;
  // console.log(micLang);
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);
  useEffect(() => {
    handleListen();
  }, [isListening]);
  const handleListen = () => {
    if (isListening) {
      startRecording();
      mic.start();
    } else {
      stopRecording();
      mic.stop();
    }
    mic.onresult = (event) => {
      console.log(event, "dsazghfhjsgdhjfghjghjghj");
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      let userData = dontKeep
        ? sessionStorage.getItem("data")
        : localStorage.getItem("data");
      userData = JSON.parse(userData);
      if (userData && userData.user_subscription) {
        let transcriptData = transcript;
        let charLimit = 0;
        let letterCount = dontKeep
          ? sessionStorage.getItem(`lettercount`)
          : localStorage.getItem(`lettercount`);
        letterCount = parseInt(letterCount);
        if (letterCount < userData.user_subscription.plan.characters_month) {
          if (
            userData.user_subscription.plan.characters_month <
            letterCount + transcript.length
          ) {
            charLimit =
              userData.user_subscription.plan.characters_month - letterCount;
            transcriptData = transcriptData.substring(0, charLimit);
            setShowLimitPopUp(true);
          } else {
            charLimit = transcriptData.length;
          }
          const letterData = {
            letterCount: charLimit,
          };
          axios
            .post(`${config.url}plans/update-lettercount/`, letterData, {
              headers: {
                Authorization: `Bearer ${
                  dontKeep
                    ? sessionStorage.getItem(`access`)
                    : localStorage.getItem(`access`)
                }`,
              },
            })
            .then((res) => {
              dontKeep
                ? sessionStorage.setItem(
                    `lettercount`,
                    JSON.stringify(res.data.letterCount)
                  )
                : localStorage.setItem(
                    `lettercount`,
                    JSON.stringify(res.data.letterCount)
                  );
              setNote(transcriptData);
            })
            .catch((err) => {
              console.log(err, "error");
            });
        } else {
          setShowLimitPopUp(true);
        }
      } else {
        history.push("/Upgrade");
      }
      console.log(transcript, `TRANSSCRIPT`);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
  };
  function toggleMic() {
    setIsListening((prevState) => !prevState);
  }
  function getData(url) {
    axios
      .get(url)
      .then((res) => {
        let userData = dontKeep
          ? sessionStorage.getItem("data")
          : localStorage.getItem("data");
        userData = JSON.parse(userData);
        if (userData && userData.user_subscription) {
          let textData = res.data;
          let charLimit = 0;
          let letterCount = dontKeep
            ? sessionStorage.getItem(`lettercount`)
            : localStorage.getItem(`lettercount`);
          letterCount = parseInt(letterCount);
          if (letterCount < userData.user_subscription.plan.characters_month) {
            if (
              userData.user_subscription.plan.characters_month <
              letterCount + res.data.length
            ) {
              charLimit =
                userData.user_subscription.plan.characters_month - letterCount;
              textData = textData.substring(0, charLimit);
              setShowLimitPopUp(true);
            } else {
              charLimit = textData.length;
            }
            const letterData = {
              letterCount: charLimit,
            };
            axios
              .post(`${config.url}plans/update-lettercount/`, letterData, {
                headers: {
                  Authorization: `Bearer ${
                    dontKeep
                      ? sessionStorage.getItem(`access`)
                      : localStorage.getItem(`access`)
                  }`,
                },
              })
              .then((res) => {
                dontKeep
                  ? sessionStorage.setItem(
                      `lettercount`,
                      JSON.stringify(res.data.letterCount)
                    )
                  : localStorage.setItem(
                      `lettercount`,
                      JSON.stringify(res.data.letterCount)
                    );
                setNote(textData);
              })
              .catch((err) => {
                console.log(err, "error");
              });
          } else {
            setShowLimitPopUp(true);
          }
        } else {
          history.push("/Upgrade");
        }
        setIsConverting(false);
      })
      .catch((err) => console.log(err));
  }
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      upload && setFiles(acceptedFiles.map((file) => Object.assign(file, {})));
    },
  });
  function convertSpeech() {
    let userData = dontKeep
      ? sessionStorage.getItem("data")
      : localStorage.getItem("data");
    userData = JSON.parse(userData);
    if (userData && userData.user_subscription) {
      let letterCount = dontKeep
        ? sessionStorage.getItem(`lettercount`)
        : localStorage.getItem(`lettercount`);
      letterCount = parseInt(letterCount);
      if (letterCount < userData.user_subscription.plan.characters_month) {
        setIsConverting(true);
        let a = new FormData();
        a.append("file", files[0]);
        a.append("languageCode", micLang);
        axios
          .post(`${config.url}convert/speech-to-text/155/`, a, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${
                window.localStorage.getItem(`access`) ||
                window.sessionStorage.getItem(`access`)
              }`,
            },
          })
          .then((res) => {
            let url = `${config.url}${res.data.path}`;
            getData(url);
          });
      } else {
        setShowLimitPopUp(true);
      }
    } else {
      history.push("/Upgrade");
    }
  }
  let [ttsData, setTtsData] = useState(``);
  let [showTtsData, setShowTtsData] = useState(``);
  let [content, setContent] = useState(``);
  let [talkText, setTalkText] = useState(``);
  let [isConvertings, setIsConvertings] = useState(false);
  let [speechInd, setSpeechInd] = useState(0);
  let [detectedLang, setDetectedLang] = useState("");
  let [langDecode, setLangDecode] = useState("");

  let getLeng = () => {
    axios
      .post(
        `https://translation.googleapis.com/language/translate/v2/detect?key=AIzaSyCrt8DLsdE47-gbGSeLXBbzGJVI6r8LjfI`,
        {
          q: talkText,
        }
      )
      .then((res) => {
        setDetectedLang(res.data.data.detections[0][0].language);
      });
  };
  if (talkText.length !== 0) {
    getLeng();
    axios
      .post(
        `https://translation.googleapis.com/language/translate/v2/languages?key=AIzaSyCrt8DLsdE47-gbGSeLXBbzGJVI6r8LjfI`,
        {
          target: "en",
        }
      )
      .then((res) => {
        let newarr = res.data.data.languages.filter((item) => {
          return item.language === detectedLang;
        });
        setLangDecode(newarr[0]?.name);
      });
  }
  useEffect(() => {
    setTalkText("");
    setNote("");
  }, [checked]);
  // useEffect(()=>{
  //   const  temArr=[];
  //   if(ttsData.length>0){
  //   ttsData.map(i=>temArr.push(i.languageCodes[0]));
  //    console.log([...new Set(temArr)]);
  // }
  // },[ttsData]);
  useEffect(() => {
    (async () => {
      let a = await new Promise((resolve, rej) => {
        axios
          .get(
            `https://texttospeech.googleapis.com/v1beta1/voices?key=AIzaSyCrt8DLsdE47-gbGSeLXBbzGJVI6r8LjfI`
          )
          .then((res) => {
            resolve(res.data.voices);
          })
          .catch((err) => {
            console.log(err);
          });
      });

      setTtsData(
        a
          .filter((a) => a.name.includes("Wavenet"))
          .sort((a, b) => a.languageCodes[0].localeCompare(b.languageCodes[0]))
      );

      setShowTtsData(
        a
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => {
            if (item.name.includes(`-A`)) {
              if (item.name.includes(`en-AU-Wavenet-A`)) {
                return item.name.replace(
                  `en-AU-Wavenet-A`,
                  `en-AU-Wavenet-Alex-Female`
                );
              }
              if (item.name.includes(`fr-FR-Wavenet-A`)) {
                return item.name.replace(
                  `fr-FR-Wavenet-A`,
                  `fr-FR-Wavenet- Esme-Female`
                );
              }
              if (item.name.includes(`cmn-CN-Wavenet-A`)) {
                return item.name.replace(
                  `cmn-CN-Wavenet-A`,
                  `cmn-CN-Wavenet- Joanna`
                );
              }
              if (item.name.includes(`cmn-TW-Wavenet-A`)) {
                return item.name.replace(
                  `cmn-TW-Wavenet-A`,
                  `cmn-TW-Wavenet- Fang 芳`
                );
              }
              if (item.name.includes(`cmn-CN-Wavenet-B`)) {
                return item.name.replace(
                  `cmn-CN-Wavenet-B`,
                  `cmn-CN-Wavenet- Jermi`
                );
              }
              if (item.name.includes(`en-AU-Wavenet-B`)) {
                return item.name.replace(
                  `en-AU-Wavenet-B`,
                  `en-AU-Wavenet-Joseph-Male`
                );
              }
              if (item.name.includes(`en-US-Wavenet-A`)) {
                return item.name.replace(
                  `en-US-Wavenet-A`,
                  `en-US-Wavenet-Jonna-Female`
                );
              }
              if (item.name.includes(`pt-BR-Wavenet-A`)) {
                return item.name.replace(
                  `pt-BR-Wavenet-A`,
                  `pt-BR-Wavenet-Julianna-Female`
                );
              }
              if (item.name.includes(`nl-NL-Wavenet-A`)) {
                return item.name.replace(
                  `nl-NL-Wavenet-A`,
                  `nl-NL-Wavenet-Anika-Female`
                );
              }

              if (item.name.includes(`ar-XA-Wavenet-A`)) {
                return item.name.replace(
                  `ar-XA-Wavenet-A`,
                  `ar-XA-Wavenet- Marwa-Female`
                );
              }
              if (item.name.includes(`it-IT-Wavenet-A`)) {
                return item.name.replace(
                  `it-IT-Wavenet-A`,
                  `it-IT-Wavenet- Sofia-Female`
                );
              }
              if (item.name.includes(`bn-IN-Wavenet-A`)) {
                return item.name.replace(
                  `bn-IN-Wavenet-A`,
                  `bn-IN-Wavenet-A- Dakka-Female`
                );
              }
              if (item.name.includes(`tr-TR-Wavenet-A`)) {
                return item.name.replace(
                  `tr-TR-Wavenet-A`,
                  `tr-TR-Wavenet-Aysun-Female`
                );
              }
              if (item.name.includes(`en-GB-Wavenet-A`)) {
                return item.name.replace(
                  `en-GB-Wavenet-A`,
                  `en-GB-Wavenet-Alexa-Female`
                );
              }
              if (item.name.includes(`da-DK-Wavenet-A`)) {
                return item.name.replace(
                  `da-DK-Wavenet-A`,
                  `da-DK-Wavenet-Josefine-Female`
                );
              }
              if (item.name.includes(`nl-BE-Wavenet-A`)) {
                return item.name.replace(
                  `nl-BE-Wavenet-A`,
                  `nl-BE-Wavenet- Bahavia-Female`
                );
              }

              if (item.name.includes(`ar-XA-Wavenet-B`)) {
                return item.name.replace(
                  `ar-XA-Wavenet-B`,
                  `ar-XA-Wavenet- Hamza`
                );
              }
              if (item.name.includes(`de-DE-Wavenet-A`)) {
                return item.name.replace(
                  `de-DE-Wavenet-A`,
                  `de-DE-Wavenet-Petra-Female`
                );
              }
              if (item.name.includes(`ar-XA-Wavenet-C`)) {
                return item.name.replace(
                  `ar-XA-Wavenet-C`,
                  `ar-XA-Wavenet- Suhayb`
                );
              }
              if (item.name.includes(`ar-XA-Wavenet-D`)) {
                return item.name.replace(
                  `ar-XA-Wavenet-D`,
                  `ar-XA-Wavenet- Joury`
                );
              }
              if (item.name.includes(`fr-CA-Wavenet-A`)) {
                return item.name.replace(
                  `fr-CA-Wavenet-A`,
                  `fr-CA-Wavenet- Samantha-Female`
                );
              }
              if (item.name.includes(`en-AU-Wavenet-C`)) {
                return item.name.replace(
                  `en-AU-Wavenet-C`,
                  `en-AU-Wavenet-Alexis-Female`
                );
              }
              if (item.name.includes(`es-US-Wavenet-A`)) {
                return item.name.replace(
                  `es-US-Wavenet-A`,
                  `es-US-Wavenet-Isabella-Female`
                );
              }
              if (item.name.includes(`en-AU-Wavenet-D`)) {
                return item.name.replace(
                  `en-AU-Wavenet-D`,
                  `en-AU-Wavenet-Casper-Male`
                );
              }
              return item.name.replace(`-A`, `- sound A`);
            } else if (item.name.includes(`-B`)) {
              if (item.name.includes(`nl-BE-Wavenet-B`)) {
                return item.name.replace(
                  `nl-BE-Wavenet-B`,
                  `nl-BE-Wavenet- Baker`
                );
              }
              if (item.name.includes(`it-IT-Wavenet-B`)) {
                return item.name.replace(
                  `it-IT-Wavenet-B`,
                  `it-IT-Wavenet-Alice-Female`
                );
              }
              if (item.name.includes(`cmn-TW-Wavenet-B`)) {
                return item.name.replace(
                  `cmn-TW-Wavenet-B`,
                  `cmn-TW-Wavenet- Haitao-Male`
                );
              }
              if (item.name.includes(`nl-NL-Wavenet-B`)) {
                return item.name.replace(
                  `nl-NL-Wavenet-B`,
                  `nl-NL-Wavenet-Sem-Male`
                );
              }
              if (item.name.includes(`pt-BR-Wavenet-B`)) {
                return item.name.replace(
                  `pt-BR-Wavenet-B`,
                  `pt-BR-Wavenet-Marko-Male`
                );
              }
              if (item.name.includes(`pt-BR-Wavenet-C`)) {
                return item.name.replace(
                  `pt-BR-Wavenet-C`,
                  `pt-BR-Wavenet-Maryanna-Female`
                );
              }
              if (item.name.includes(`cmn-CN-Wavenet-B`)) {
                return item.name.replace(
                  `cmn-CN-Wavenet-B`,
                  `cmn-CN-Wavenet- Jermi`
                );
              }
              if (item.name.includes(`bn-IN-Wavenet-B`)) {
                return item.name.replace(
                  `bn-IN-Wavenet-B`,
                  `bn-IN-Wavenet-B-Singh-Male`
                );
              }
              if (item.name.includes(`es-US-Wavenet-B`)) {
                return item.name.replace(
                  `es-US-Wavenet-B`,
                  `es-US-Wavenet-Mario-Male`
                );
              }
              if (item.name.includes(`de-DE-Wavenet-B`)) {
                return item.name.replace(
                  `de-DE-Wavenet-B`,
                  `de-DE-Wavenet-Stefan-Male`
                );
              }
              if (item.name.includes(`en-US-Wavenet-B`)) {
                return item.name.replace(
                  `en-US-Wavenet-B`,
                  `en-US-Wavenet-Merlyin-Female`
                );
              }
              if (item.name.includes(`es-ES-Wavenet-B`)) {
                return item.name.replace(
                  `es-ES-Wavenet-B`,
                  `es-ES-Wavenet-Alejandro-Male`
                );
              }
              if (item.name.includes(`tr-TR-Wavenet-B`)) {
                return item.name.replace(
                  `tr-TR-Wavenet-B`,
                  `tr-TR-Wavenet-Alp-Male`
                );
              }
              if (item.name.includes(`en-GB-Wavenet-B`)) {
                return item.name.replace(
                  `en-GB-Wavenet-B`,
                  `en-GB-Wavenet-Joe-Male`
                );
              }
              if (item.name.includes(`ar-XA-Wavenet-B`)) {
                return item.name.replace(
                  `ar-XA-Wavenet-B`,
                  `ar-XA-Wavenet- Hamza-Male`
                );
              }
              if (item.name.includes(`fr-FR-Wavenet-B`)) {
                return item.name.replace(
                  `fr-FR-Wavenet-B`,
                  `fr-FR-Wavenet- Leo-Male`
                );
              }
              if (item.name.includes(`fr-CA-Wavenet-B`)) {
                return item.name.replace(
                  `fr-CA-Wavenet-B`,
                  `fr-CA-Wavenet- Louis-Male`
                );
              }
              return item.name.replace(`-B`, `-sound B`);
            } else if (item.name.includes(`-C`)) {
              if (item.name.includes(`fr-CA-Wavenet-C`)) {
                return item.name.replace(
                  `fr-CA-Wavenet-C`,
                  `fr-CA-Wavenet- Mona-Female`
                );
              }
              if (item.name.includes(`fr-FR-Wavenet-C`)) {
                return item.name.replace(
                  `fr-FR-Wavenet-C`,
                  `fr-FR-Wavenet- Fayette-Female`
                );
              }

              if (item.name.includes(`de-DE-Wavenet-C`)) {
                return item.name.replace(
                  `de-DE-Wavenet-C`,
                  `de-DE-Wavenet-Gisela-Female`
                );
              }
              /*if (item.name.includes(`pt-BR-Wavenet-C`)) {
                return item.name.replace(
                  `pt-BR-Wavenet-C`,
                  `pt-BR-Wavenet-Marya-Female`
                );
              }*/
              if (item.name.includes(`pt-BR-Wavenet-C`)) {
                return item.name.replace(
                  `pt-BR-Wavenet-C`,
                  `pt-BR-Wavenet-Maryanna-Female`
                );
              }
              if (item.name.includes(`cmn-TW-Wavenet-C`)) {
                return item.name.replace(
                  `cmn-TW-Wavenet-C`,
                  `cmn-TW-Wavenet- Lixin`
                );
              }
              if (item.name.includes(`es-US-Wavenet-C`)) {
                return item.name.replace(
                  `es-US-Wavenet-C`,
                  `es-US-Wavenet-Mateo-Male`
                );
              }
              if (item.name.includes(`nl-NL-Wavenet-C`)) {
                return item.name.replace(
                  `nl-NL-Wavenet-C`,
                  `nl-NL-Wavenet-Dann-Male`
                );
              }

              if (item.name.includes(`es-ES-Wavenet-C`)) {
                return item.name.replace(
                  `es-ES-Wavenet-C`,
                  `es-ES-Wavenet-Emilia-Female`
                );
              }
              if (item.name.includes(`fr-CA-Wavenet-D`)) {
                return item.name.replace(
                  `fr-CA-Wavenet-D`,
                  `fr-CA-Wavenet-Yahya-Male`
                );
              }
              if (item.name.includes(`tr-TR-Wavenet-C`)) {
                return item.name.replace(
                  `tr-TR-Wavenet-C`,
                  `tr-TR-Wavenet-Ece-Female`
                );
              }
              if (item.name.includes(`ar-XA-Wavenet-C`)) {
                return item.name.replace(
                  `ar-XA-Wavenet-C`,
                  `ar-XA-Wavenet- Suhayb-Male`
                );
              }
              if (item.name.includes(`en-US-Wavenet-C`)) {
                return item.name.replace(
                  `en-US-Wavenet-C`,
                  `en-US-Wavenet-Samer-Male`
                );
              }

              if (item.name.includes(`da-DK-Wavenet-C`)) {
                return item.name.replace(
                  `da-DK-Wavenet-C`,
                  `da-DK-Wavenet- William-Male`
                );
              }
              if (item.name.includes(`en-GB-Wavenet-C`)) {
                return item.name.replace(
                  `en-GB-Wavenet-C`,
                  `en-GB-Wavenet-Samantha-Female`
                );
              }
              if (item.name.includes(`it-IT-Wavenet-C`)) {
                return item.name.replace(
                  `it-IT-Wavenet-C`,
                  `it-IT-Wavenet-Lorenzo-Male`
                );
              }

              if (item.name.includes(`cmn-CN-Wavenet-C`)) {
                return item.name.replace(
                  `cmn-CN-Wavenet-C`,
                  `cmn-CN-Wavenet- Jean`
                );
              }
              if (item.name.includes(`cmn-CN-Wavenet-D`)) {
                return item.name.replace(
                  `cmn-CN-Wavenet-D`,
                  `cmn-CN-Wavenet- Simone`
                );
              }
              return item.name.replace(`-C`, `- Sound C`);
            } else if (item.name.includes(`-D`)) {
              if (item.name.includes(`de-DE-Wavenet-F`)) {
                return item.name.replace(
                  `de-DE-Wavenet-F`,
                  `de-DE-Wavenet-Monika-Female`
                );
              }
              if (item.name.includes(`fr-FR-Wavenet-D`)) {
                return item.name.replace(
                  `fr-FR-Wavenet-D`,
                  `fr-FR-Wavenet-Hugo-Male`
                );
              }
              if (item.name.includes(`nl-NL-Wavenet-D`)) {
                return item.name.replace(
                  `nl-NL-Wavenet-D`,
                  `nl-NL-Wavenet-Anike-Female`
                );
              }

              if (item.name.includes(`ar-XA-Wavenet-D`)) {
                return item.name.replace(
                  `ar-XA-Wavenet-D`,
                  `ar-XA-Wavenet- Joury-Female`
                );
              }
              if (item.name.includes(`de-DE-Wavenet-D`)) {
                return item.name.replace(
                  `de-DE-Wavenet-D`,
                  `de-DE-Wavenet-Klaus- Male`
                );
              }
              if (item.name.includes(`es-ES-Wavenet-D`)) {
                return item.name.replace(
                  `es-ES-Wavenet-D`,
                  `es-ES-Wavenet-Savannah-Female`
                );
              }
              if (item.name.includes(`it-IT-Wavenet-D`)) {
                return item.name.replace(
                  `it-IT-Wavenet-D`,
                  `it-IT-Wavenet-Francesco-Male`
                );
              }
              if (item.name.includes(`en-US-Wavenet-D`)) {
                return item.name.replace(
                  `en-US-Wavenet-D`,
                  `en-US-Wavenet-Mike-Male`
                );
              }
              if (item.name.includes(`tr-TR-Wavenet-D`)) {
                return item.name.replace(
                  `tr-TR-Wavenet-D`,
                  `tr-TR-Wavenet-Alara-Female`
                );
              }

              if (item.name.includes(`en-GB-Wavenet-D`)) {
                return item.name.replace(
                  `en-GB-Wavenet-D`,
                  `en-GB-Wavenet-Philip-Male`
                );
              }
              if (item.name.includes(`de-DE-Wavenet-D`)) {
                return item.name.replace(
                  `de-DE-Wavenet-D`,
                  `de-DE-Wavenet- Alida`
                );
              }
              if (item.name.includes(`de-DE-Wavenet-E`)) {
                return item.name.replace(
                  `de-DE-Wavenet-E`,
                  `de-DE-Wavenet-Hans-Male`
                );
              }

              if (item.name.includes(`da-DK-Wavenet-D`)) {
                return item.name.replace(
                  `da-DK-Wavenet-D`,
                  `da-DK-Wavenet- Emma-Female`
                );
              }
              if (item.name.includes(`da-DK-Wavenet-E`)) {
                return item.name.replace(
                  `da-DK-Wavenet-E`,
                  `da-DK-Wavenet- Alma-Male`
                );
              }
              return item.name.replace(`-D`, `- Sound D`);
            } else if (item.name.includes(`-E`)) {
              if (item.name.includes(`en-US-Wavenet-E`)) {
                return item.name.replace(
                  `en-US-Wavenet-E`,
                  `en-US-Wavenet-Alura-Female`
                );
              }
              if (item.name.includes(`nl-NL-Wavenet-E`)) {
                return item.name.replace(
                  `nl-NL-Wavenet-E`,
                  `nl-NL-Wavenet-Barend-Female`
                );
              }

              if (item.name.includes(`da-DK-Wavenet-D`)) {
                return item.name.replace(
                  `da-DK-Wavenet-D`,
                  `da-DK-Wavenet- Emma-Female`
                );
              }
              if (item.name.includes(`da-DK-Wavenet-E`)) {
                return item.name.replace(
                  `da-DK-Wavenet-E`,
                  `da-DK-Wavenet- Alma-Male`
                );
              }
              if (item.name.includes(`tr-TR-Wavenet-E`)) {
                return item.name.replace(
                  `tr-TR-Wavenet-E`,
                  `tr-TR-Wavenet-Ahmet-Male`
                );
              }
              if (item.name.includes(`fr-FR-Wavenet-E`)) {
                return item.name.replace(
                  `fr-FR-Wavenet-E`,
                  `fr-FR-Wavenet- Fayette-Female`
                );
              }
              return item.name.replace(`-E`, `- Sound E`);
            } else if (item.name.includes(`-F`)) {
              if (item.name.includes(`en-GB-Wavenet-F`)) {
                return item.name.replace(
                  `en-GB-Wavenet-F`,
                  `en-GB-Wavenet-Noura-Female`
                );
              }
              if (item.name.includes(`de-DE-Wavenet-F`)) {
                return item.name.replace(
                  `de-DE-Wavenet-F`,
                  `de-DE-Wavenet-Monika-Female`
                );
              }

              if (item.name.includes(`en-US-Wavenet-F`)) {
                return item.name.replace(
                  `en-US-Wavenet-F`,
                  `en-US-Wavenet-Donan-Male`
                );
              }
              return item.name.replace(`-F`, `- Sound F`);
            } else if (item.name.includes(`-G`)) {
              if (item.name.includes(`en-US-Wavenet-G`)) {
                return item.name.replace(
                  `en-US-Wavenet-G`,
                  `en-US-Wavenet-Scarlet-Female`
                );
              }
              return item.name.replace(`-G`, `- Sound G`);
            } else if (item.name.includes(`-H`)) {
              if (item.name.includes(`en-US-Wavenet-H`)) {
                return item.name.replace(
                  `en-US-Wavenet-H`,
                  `en-US-Wavenet-Sebastian-Male`
                );
              }
              return item.name.replace(`-H`, `- Sound H`);
            } else if (item.name.includes(`-I`)) {
              if (item.name.includes(`en-US-Wavenet-I`)) {
                return item.name.replace(
                  `en-US-Wavenet-I`,
                  `en-US-Wavenet-Sarah-Female`
                );
              }
              return item.name.replace(`-I`, `Sound I`);
            } else if (item.name.includes(`-J`)) {
              if (item.name.includes(`en-US-Wavenet-J`)) {
                return item.name.replace(
                  `en-US-Wavenet-J`,
                  `en-US-Wavenet- Samantha-Female`
                );
              }
              return item.name.replace(`-J`, `-Sound J`);
            } else {
              return item.name;
            }
          })
      );
    })();
  }, []);
  let convertTextToSpeech = () => {
    setIsConvertings(true);
    let userData = dontKeep
      ? sessionStorage.getItem("data")
      : localStorage.getItem("data");
    userData = JSON.parse(userData);
    console.log(talkText.length, "data length");
    if (userData && userData.user_subscription) {
      let talkData = talkText;
      let charLimit = 0;
      let letterCount = dontKeep
        ? sessionStorage.getItem(`lettercount`)
        : localStorage.getItem(`lettercount`);
      letterCount = parseInt(letterCount);
      if (letterCount < userData.user_subscription.plan.characters_month) {
        if (
          userData.user_subscription.plan.characters_month <
          letterCount + talkText.length
        ) {
          charLimit =
            userData.user_subscription.plan.characters_month - letterCount;
          talkData = talkData.substring(0, charLimit);
          setShowLimitPopUp(true);
        } else {
          charLimit = talkText.length;
        }
        const letterData = {
          letterCount: charLimit,
        };
        axios
          .post(`${config.url}plans/update-lettercount/`, letterData, {
            headers: {
              Authorization: `Bearer ${
                dontKeep
                  ? window.sessionStorage.getItem(`access`)
                  : window.localStorage.getItem(`access`)
              }`,
            },
          })
          .then((res) => {
            dontKeep
              ? window.sessionStorage.setItem(
                  `lettercount`,
                  JSON.stringify(res.data.letterCount)
                )
              : window.localStorage.setItem(
                  `lettercount`,
                  JSON.stringify(res.data.letterCount)
                );
          })
          .catch((err) => {
            console.log(err, "error");
          });
        let data = {
          audioConfig: {
            audioEncoding: "LINEAR16",
            effectsProfileId: [
              "handset-class-device",
              "headphone-class-device",
            ],
            pitch: -1.4,
            speakingRate: 1,
          },
          input: { text: `${talkData}` },
          voice: {
            languageCode: `${ttsData[speechInd].languageCodes[0]}`,
            name: `${ttsData[speechInd].name}`,
          },
        };
        axios
          .post(
            "https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyCrt8DLsdE47-gbGSeLXBbzGJVI6r8LjfI",
            data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setContent(res.data.audioContent);
            setIsConvertings(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setShowLimitPopUp(true);
      }
    } else {
      history.push("/Upgrade");
    }
  };
  let downloadMp3 = () => {
    axios
      .get(`data:audio/wav;base64,` + content, {
        responseType: `blob`,
      })
      .then((res) => {
        fileDownload(res.data, "my-audio.wav");
      });
  };

  // Now Simple
  return {
    checked,
    handleChange,
    content,
    talkText,
    downloadMp3,
    setUpload,
    upload,
    getRootProps,
    getInputProps,
    files,
    toggleMic,
    micState,
    isListening,
    isConverting,
    subPlan,
    setMicLang,
    ttsData,
    convertSpeech,
    setSpeechInd,
    showTtsData,
    isConvertings,
    langDecode,
    setTalkText,
    setNote,
    setContent,
    convertTextToSpeech,
    showLimitPopUp,
    setShowLimitPopUp,
    note,
  };
};

export default useSpeach;
