export default function Iframe(props){
    let f1 = (e) =>{
        let myFrame = document.getElementById(`myFrame`)
        console.log(myFrame.innerHTML.lenght);
    }
    return (
        <>
        <iframe title='myIframe' id='myFrame' width="100%" height="600" src={props.link} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
        allowFullScreen onLoad={f1} ></iframe>
        </>
    )
}




