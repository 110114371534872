import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import img2 from "../../assets/images/home/img2.png";
import img from "../../assets/images/home/img.png";
import img3 from "../../assets/images/home/img3.webp";
import img4 from "../../assets/images/home/img4.webp";
import img6 from "../../assets/images/home/img6.png";
import img7 from "../../assets/images/home/img7.png";
import img8 from "../../assets/images/home/img8.png";
import img9 from "../../assets/images/home/img9.png";
import img10 from "../../assets/images/home/img10.png";
// import img11 from "../../assets/images/home/img11.png";
import watchVideoUrl from "../../assets/video/watch_now.mp4";
import React from "react";
import { NavLink } from "react-router-dom";
import ReactVideoPLayer from "../../components/reactPlayer/reactPlayer.component";

export default function Home(props) {
  const [scrollValue, setScrollValue] = React.useState("");
  const [isVideoModel, setVideoModel] = React.useState(false);

  const videoModelHandler = React.useCallback(() => {
    setVideoModel(true);
  }, [isVideoModel]);

  React.useEffect(() => {
    function scrollBar() {
      setScrollValue(window.pageYOffset);
    }
    if (window.innerWidth > 1050) {
      window.addEventListener("scroll", scrollBar);
    }
    return () => window.removeEventListener("scroll", scrollBar);
  }, []);

  return (
    <main className="home homepage">
      <Header isLoggedIn={props.isLoggedIn} />
      <div style={{ paddingTop: "78px" }} className="homeResponse">
        <section className="homeSection1">
          <div className="size homeSize">
            <div className="homeSection1Left animate__animated animate__slideInLeft">
              <h1 className="homeSection1LeftTitle">BayanatSpeech</h1>
              <p className="homeSection1LeftText">One Stop Shop for Speech Services</p>
              <p className="homeSection1LeftBottomTitle">
                Speech to text , Text to Speech , Translation, Steganography in one click
              </p>
              <div className="homeSection1Btns">
                <NavLink to="./signUp">
                  <button className="homeSection1Btn1">start free now</button>
                </NavLink>
                <button
                  className="homeSection1Btn1 homeSection1Btn2"
                  onClick={videoModelHandler}
                >
                  Watch video
                </button>
                {isVideoModel ? (
                  <ReactVideoPLayer
                    setShow={setVideoModel}
                    videoUrl={watchVideoUrl}
                  />
                ) : null}
              </div>
            </div>
            <div className="banner-bottom section-wrap">
              <div className="left section-text animate__animated animate__slideInLeft">
                <div className="homeSection1LeftBottomTop">
                  <p>text to speech</p>
                  <img src={img2} alt="icon1" />
                </div>
                <p className="homeSection1LeftBottomTitle">or speech to Text</p>
                <p className="homeSection1LeftBottomText">
                  Listen to your documents, texts and notes anywhere any time by
                  using the most robust Text to speech converter. Multilingual
                  speech-to-text aimed at assisting you with transcription of
                  any type of documents, notes, reports, blog posts, etc by
                  using your voice.Text to speech and Speech to Text can be a
                  way of boosting communication connections, meeting people
                  where ever they are.
                </p>
              </div>
              <div className="animate__animated animate__slideInRight right">
                <img src={img} alt="icon1" className="section-img small-img" />
              </div>
            </div>
          </div>
        </section>
        <section className="homeSection2">
          <div className="size homeSectionSize section-wrap">
            <img
              src={img3}
              alt="icon1"
              className={`imgHello ${
                scrollValue <= 300
                  ? "dontShow"
                  : "animate__animated animate__slideInLeft"
              }`}
            />
            <div
              className={`${
                scrollValue <= 300
                  ? "dontShow section-text "
                  : "animate__animated animate__slideInRight homeSection2Side"
              }`}
            >
              <h3 className="homeSectionsTitle">
                More Than 30 languages and 100 Voices to choose
              </h3>
              <p className="homeSectionsText">
                Multiple Voices , both male and female, and with different
                accents, serve the unique needs of a varying audience. learning
                content should be accessible to global learners and to those who
                do not speak the language fluently.Brilliant tool for Employees
                to translate text into their native language or produce audio in
                multiple languages.
              </p>

              <NavLink to="./signUp">
                <button className="homeSectionsBtns">start free now</button>
              </NavLink>
            </div>
          </div>
        </section>
        <section className="homeSection2 homeSection3 homeSection3Bg">
          <div
            className={`section-wrap ${
              scrollValue <= 1100
                ? "dontShow"
                : "animate__animated animate__slideInLeft size homeSectionSize homeSectionSize2"
            }`}
          >
            <img src={img4} alt="icon1" className="section-img" />
            <div className="homeSection2Side homeSection2Side2 section-text">
              <h3 className="homeSectionsTitle">E-Learning Special Packages</h3>
              <p className="homeSectionsText">
                Special and distinct services for schools, universities, and
                Training Centers. Easy and cost-effective to add and update
                voice for a presentation, training manual, or learning course
                instead of using costly and time-intensive human actors.
              </p>
              <p className="homeSectionsText"> </p>
              <NavLink to="./signUp">
                <button className="homeSectionsBtns">start free now</button>
              </NavLink>
            </div>
          </div>
        </section>
        <section className="homeSection2 homeSection4">
          <div className="size homeSectionSize section-wrap">
            <img
              src={img6}
              alt="icon1"
              className={`section-img ${
                scrollValue <= 2100
                  ? "dontShow"
                  : "animate__animated animate__lightSpeedInLeft"
              }`}
            />
            <div
              className={`${
                scrollValue <= 2100
                  ? "dontShow section-text "
                  : "animate__animated animate__slideInRight homeSection2Side"
              }`}
            >
              <h3 className="homeSectionsTitle">Smart Voice Technology</h3>
              <p className="homeSectionsText">
                Using cutting-edge Technology for Public use, broadcasting, IoT,
                or IVR systems. Create narration for your videos. Allowing to
                test scripts before they go into production allows for cost and
                resource efficiencies. TTS and STT can be used in various
                infrastructure as a health measure, as well as a broader
                learning tool.
              </p>
              <NavLink to="./signUp">
                <button className="homeSectionsBtns">start free now</button>
              </NavLink>
            </div>
          </div>
        </section>
        <section className="homeSection2 homeSection3 homeSection5">
          <div className="size homeSectionSize homeSectionSize2 section-wrap">
            <img
              src={img7}
              alt="icon1"
              className={`${
                scrollValue <= 2900
                  ? "dontShow"
                  : "animate__animated animate__slideInRight"
              }`}
            />
            <div
              className={`${
                scrollValue <= 2900
                  ? "dontShow section-text "
                  : "animate__animated animate__slideInLeft homeSection2Side homeSection2Side2"
              }`}
            >
              <h3 className="homeSectionsTitle">
                Keeping Students Engaged in Online Learning
              </h3>
              <p className="homeSectionsText">
                keeping students engaged in online learning by maximizing access
                to material for all students. Having text read out loud relieves
                learners with decoding issues so they can concentrate on the
                content, and allows those that prefer to listen the chance to
                engage in the material in various ways.
              </p>
              <NavLink to="./signUp">
                <button className="homeSectionsBtns">start free now</button>
              </NavLink>
            </div>
          </div>
        </section>
        <section className="homeSection2 homeSection6">
          <div className="size homeSectionSize section-wrap">
            <img
              src={img8}
              alt="icon1"
              className={`section-img ${
                scrollValue <= 3700
                  ? "dontShow"
                  : "animate__animated animate__slideInLeft"
              }`}
            />
            <div
              className={`${
                scrollValue <= 3700
                  ? "dontShow section-text "
                  : "animate__animated animate__slideInRight homeSection2Side"
              }`}
            >
              <h3 className="homeSectionsTitle">steganography</h3>
              <p className="homeSectionsText">
                Transmit your hidden audio message in host audio in an
                imperceptible manner. The host audio before steganography and
                steago audio after steganography have the same characteristics.
              </p>
              <NavLink to="./signUp">
                <button className="homeSectionsBtns">start free now</button>
              </NavLink>
            </div>
          </div>
        </section>
        <section className="homeSection2 homeSection3 homeSection7">
          <div className="size homeSectionSize homeSectionSize2 section-wrap">
            <img
              src={img9}
              alt="icon1"
              className={`section-img ${
                scrollValue <= 4548
                  ? "dontShow"
                  : "animate__animated animate__slideInRight"
              }`}
            />
            <div
              className={`${
                scrollValue <= 4548
                  ? "dontShow section-text "
                  : "animate__animated animate__slideInLeft homeSection2Side homeSection2Side2"
              }`}
            >
              <h3 className="homeSectionsTitle">Language Detection</h3>
              <p className="homeSectionsText">
                Analyzing the given text and identifying the Language among more
                than 30 Languages.
              </p>
              <NavLink to="./signUp">
                <button className="homeSectionsBtns">start free now</button>
              </NavLink>
            </div>
          </div>
        </section>
        <section className="homeSection2 homeSection8">
          <div className="size homeSectionSize section-wrap">
            <img
              src={img10}
              alt="icon1"
              className={`section-img ${
                scrollValue <= 5448
                  ? "dontShow"
                  : "animate__animated animate__slideInLeft"
              }`}
            />
            <div
              className={`${
                scrollValue <= 5448
                  ? "dontShow section-text "
                  : "animate__animated animate__slideInRight homeSection2Side"
              }`}
            >
              <h3 className="homeSectionsTitle">Translation</h3>
              <p className="homeSectionsText">
                Translates words, phrases, and web pages between English and
                over 100 other languages.
              </p>
              <NavLink to="./signUp">
                <button className="homeSectionsBtns">start free now</button>
              </NavLink>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </main>
  );
}
