import './style.css';
import pic1 from '../../assets/images/play.png';
import closeIcon from '../../assets/images/closeIcon.png';
import addtrackIcon from '../../assets/images/addtrack.png';
import circleIcon from '../../assets/images/circle.png';
import filesIcon from '../../assets/images/files.png';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Speech from 'speak-tts';
import { useEffect } from 'react';

export default function Win(props) {
    let [value, Setvalue] = useState('')
    useEffect(()=>{
        props.setTalkText(value)
    },[value])
    function handleSpeak() {
        const speech = new Speech()
        speech.setLanguage(props.language)
        speech.speak({ text: value })
    }
    return (
        <>
            <div className='smallWindow '>
                {console.log(value,"this is original")}
                <div className='swG'>
                    <div>
                        {props.cross ? <div className='xxx'>
                            <img onClick={props.closeWin} className='img1' src={closeIcon} alt=''></img>
                        </div> : null}
                        <div className='xxx'>
                            <img className='img1' src={pic1} onClick={props.speech ? handleSpeak : props.handleConvert} alt='icon1'></img>
                        </div>
                        <div className='xxx'>
                            <img className='img3' src={circleIcon} onClick={props.refresh} title='click to refresh' alt='icon1'></img>
                        </div>
                        {props.noicon ? null : <CopyToClipboard text={props.innerText}>
                            <div className='xxx'>
                                <img className='img4' src={filesIcon} alt='filesIcon'></img>
                            </div>
                        </CopyToClipboard>}
                        {props.addtrack ?
                            <div className='xxx longdiv'>
                                <img className='img1' src={addtrackIcon} alt='addicon'></img>
                                <p>Add Track</p>
                            </div> : null}
                    </div>
                    {props.addfile ? <div className='xxx longdiv2'>
                        <p>Join Files</p>
                    </div> : null}
                </div>
                <div className='swW'>
                    {props.linkSpace ? <div>{props.webPage}</div> : <div className='content'><textarea className='speechText' onChange={(e) => Setvalue(e.target.value)} placeholder={props.text} value={props.innerText} disabled={props.isChecked}></textarea>
                    </div>}
                </div>
            </div>

        </>
    )
}