import './style.css'
import check from '../../assets/images/check.png';
import no_check from '../../assets/images/no_check.png'
export default function SubList(props){
    return (
        <>
        <div className='SubListMain'>
            <img className='SubListImg' src={props.check ? check : no_check} alt='check'></img>
            <p style={props.check ? {color : 'black'} : {color : '#B2B2B2'}}>{props.text}</p>
        </div>
        </>
    )
}