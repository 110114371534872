import "./style.css";
import Header2 from "../../components/header/Header2";
import LeftBar from "../../components/leftBar/LeftBar";
import pic5 from "../../assets/images/scr2.png";
import Win3 from "../../components/win3/win3";
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import Refresh from "../../elements/refresh";
import axios from "axios";
import { config } from "../../elements/config";
import Loading from "../../elements/Loading";
import fileDownload from "js-file-download";
import SEO from "layout/nextseo";

export default function Ocr() {
  let [isError, setIsError] = useState(false);
  let [isErrorDone, setIsErrorDone] = useState(false);
  let [ttsData, setTtsData] = useState("");
  useEffect(() => {
    isError &&
      setTimeout(() => {
        setIsErrorDone(!isErrorDone);
      }, 2000);
  }, [isError]);
  if (isErrorDone === true) {
    setTimeout(() => {
      setIsError(false);
      setIsErrorDone(false);
    }, 2000);
  }
  useEffect(async () => {
    // Refresh()
    let a = await new Promise((resolve, rej) => {
      axios
        .get(
          `https://texttospeech.googleapis.com/v1beta1/voices?key=AIzaSyCD5MddriyA_Tgina0msTZ-xQDa1FuVJaA`
        )
        .then((res) => {
          resolve(res.data.voices);
        })
        .catch((err) => {
          console.log(err);
        });
    });
    setTtsData(a);
  }, []);
  let [checked, setChecked] = useState(false);
  let [files, setFiles] = useState([]);
  let [txtUrl, setTxtUrl] = useState("");
  let [voiceUrl, setVoiceUrl] = useState("");
  let [downloadUrl, setDownloadUrl] = useState("");
  let [isConverting, setIsConverintg] = useState(false);
  let [ocrLang, setOcrLang] = useState("en-EN");
  useEffect(() => {
    !checked ? setDownloadUrl(txtUrl) : setDownloadUrl(voiceUrl);
  }, [checked]);
  function download() {
    axios
      .get(downloadUrl.length === 0 ? txtUrl : downloadUrl, {
        responseType: `blob`,
      })
      .then((res) => {
        fileDownload(res.data, `${files[0].name}${checked ? ".mp3" : ".txt"}`);
        setDownloadUrl("");
      });
  }
  let converPdf = () => {
    files.length !== 0 && setIsConverintg(true);
    let a = new FormData();
    a.append("file", files[0]);
    a.append(
      "type",
      files[0]?.type === "application/pdf"
        ? "pdf"
        : files[0]?.type === "image/jpeg"
        ? "ocr"
        : null
    );
    a.append("langCode", ocrLang);
    files.length !== 0 &&
      axios
        .post(`${config.url}convert/pdf-to-text/`, a, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${
              window.localStorage.getItem(`access`) ||
              window.sessionStorage.getItem(`access`)
            }`,
          },
        })
        .then((res) => {
          setTxtUrl(`${config.media}${res.data.file}`);
          setVoiceUrl(`${config.media}${res.data.voice}`);
          setIsConverintg(false);
        })
        .catch((err) => {
          console.log(err);
          setIsError(true);
          setIsConverintg(false);
        });
  };
  useEffect(() => {
    converPdf();
  }, [files]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, {})));
    },
  });
  let handleChange = () => {
    setChecked(!checked);
  };
  let name1;
  !checked ? (name1 = "text") : (name1 = "speech");
  console.log(ocrLang);
  return (
    <>
      <Header2
        title="OCR, PDF To Text"
        tik={true}
        title2="OCR, PDF To Speech"
        checked={checked}
        handleChange={handleChange}
      />
      <SEO title="Bayanat Speech | OCR" />
      <LeftBar />
      <div
        style={{ paddingTop: "73px" }}
        className="animate__animated animate__slideInLeft"
      >
        <section className="ocrcont">
          <p className="warningMsg">
            Warning: Please select language code before uploading the file.
          </p>
          <div className="mid" {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              style={!isError ? { display: "none" } : { display: "flex" }}
              className={`successSignUp ${
                isError && "animate__animated animate__slideInLeft"
              } ${isErrorDone && "animate__animated animate__slideOutLeft"}`}
            >
              <p>Something went wrong</p>
            </div>
            <p className="midp">
              Drag and drop your <span>file</span> to convert it into {name1}
            </p>
            <p className="uploadedFileName">
              {files.length !== 0 ? files[0].name : null}
            </p>
            <p>{isConverting && <Loading size={10} />}</p>
            <p
              style={isConverting ? { color: "#FF9900" } : { display: "none" }}
              className="recordMessage recordMessage2"
            >
              Converting
            </p>
          </div>
          <div className="tdiv tdiv2">
            <p>Select Language Code</p>
            <select
              onClick={(e) => setOcrLang(e.target.value)}
              className="sel"
              name="Language"
            >
              {ttsData &&
                ttsData?.map((item, index) => {
                  return (
                    <>
                      {index <= 140 && (
                        <option value={item.languageCodes[0]}>
                          {item.languageCodes[0].includes(`ar-XA`)
                            ? item.languageCodes[0].replace(`ar-XA`, `Arabic `)
                            : item.languageCodes[0].includes(`ro-RO`)
                            ? item.languageCodes[0].replace(
                                `ro-RO`,
                                `Romanian `
                              )
                            : item.languageCodes[0].includes(`pa-IN`)
                            ? item.languageCodes[0].replace(
                                `pa-IN`,
                                `Punjabi(India) `
                              )
                            : item.languageCodes[0].includes(`pl-PL`)
                            ? item.languageCodes[0].replace(`pl-PL`, `Polish`)
                            : item.languageCodes[0].includes(`bn-IN`)
                            ? item.languageCodes[0].replace(`bn-IN`, `Indian`)
                            : item.languageCodes[0].includes(`en-GB`)
                            ? item.languageCodes[0].replace(
                                `en-GB`,
                                `English UK `
                              )
                            : item.languageCodes[0].includes(`fr-CA`)
                            ? item.languageCodes[0].replace(
                                `fr-CA`,
                                `French(Canada) `
                              )
                            : item.languageCodes[0].includes(`en-US`)
                            ? item.languageCodes[0].replace(
                                `en-US`,
                                `English US  `
                              )
                            : item.languageCodes[0].includes(`es-ES`)
                            ? item.languageCodes[0].replace(`es-ES`, `Spanish `)
                            : item.languageCodes[0].includes(`fi-FI`)
                            ? item.languageCodes[0].replace(`fi-FI`, `Finnish`)
                            : item.languageCodes[0].includes(`gu-IN`)
                            ? item.languageCodes[0].replace(
                                `gu-IN`,
                                `Gujarati (India)`
                              )
                            : item.languageCodes[0].includes(`ja-JP`)
                            ? item.languageCodes[0].replace(`ja-JP`, `Japanese`)
                            : item.languageCodes[0].includes(`kn-IN`)
                            ? item.languageCodes[0].replace(
                                `kn-IN`,
                                `Kannada (India)`
                              )
                            : item.languageCodes[0].includes(`ml-IN`)
                            ? item.languageCodes[0].replace(`ml-IN`, `Malayam`)
                            : item.languageCodes[0].includes(`sv-SE`)
                            ? item.languageCodes[0].replace(`sv-SE`, `Swedish `)
                            : item.languageCodes[0].includes(`ta-IN`)
                            ? item.languageCodes[0].replace(
                                `ta-IN`,
                                `Tamil(India) `
                              )
                            : item.languageCodes[0].includes(`tr-TR`)
                            ? item.languageCodes[0].replace(`tr-TR`, `Turkish`)
                            : item.languageCodes[0].includes(`ms-MY`)
                            ? item.languageCodes[0].replace(`ms-MY`, `Malay `)
                            : item.languageCodes[0].includes(`cs-CZ`)
                            ? item.languageCodes[0].replace(`cs-CZ`, `Czech `)
                            : item.languageCodes[0].includes(`de-DE`)
                            ? item.languageCodes[0].replace(`de-DE`, `German `)
                            : item.languageCodes[0].includes(`en-AU`)
                            ? item.languageCodes[0].replace(
                                `en-AU`,
                                `English(Australia)`
                              )
                            : item.languageCodes[0].includes(`en-IN`)
                            ? item.languageCodes[0].replace(
                                `en-IN`,
                                `English(Ireland) `
                              )
                            : item.languageCodes[0].includes(`es-US`)
                            ? item.languageCodes[0].replace(
                                `es-US`,
                                `Spanish US `
                              )
                            : item.languageCodes[0].includes(`fr-FR`)
                            ? item.languageCodes[0].replace(`fr-FR`, `French `)
                            : item.languageCodes[0].includes(`hi-IN`)
                            ? item.languageCodes[0].replace(`hi-IN`, `Hindi `)
                            : item.languageCodes[0].includes(`id-ID`)
                            ? item.languageCodes[0].replace(
                                `id-ID`,
                                `Indonesian `
                              )
                            : item.languageCodes[0].includes(`it-IT`)
                            ? item.languageCodes[0].replace(`it-IT`, `Italian`)
                            : item.languageCodes[0].includes(`ko-KR`)
                            ? item.languageCodes[0].replace(`ko-KR`, `Korean`)
                            : item.languageCodes[0].includes(`ru-RU`)
                            ? item.languageCodes[0].replace(`ru-RU`, `Russian`)
                            : item.languageCodes[0].includes(`uk-UA`)
                            ? item.languageCodes[0].replace(`uk-UA`, `Ukranian`)
                            : item.languageCodes[0].includes(`cmn-CN`)
                            ? item.languageCodes[0].replace(
                                `cmn-CN`,
                                `Mandarin Chinese `
                              )
                            : item.languageCodes[0].includes(`cmn-TW`)
                            ? item.languageCodes[0].replace(
                                `cmn-TW`,
                                `Chinese(T) - `
                              )
                            : item.languageCodes[0].includes(`da-DK`)
                            ? item.languageCodes[0].replace(`da-DK`, `Danish `)
                            : item.languageCodes[0].includes(`el-GR`)
                            ? item.languageCodes[0].replace(`el-GR`, `Greek`)
                            : item.languageCodes[0].includes(`fil-PH`)
                            ? item.languageCodes[0].replace(
                                `fil-PH`,
                                `Filipino `
                              )
                            : item.languageCodes[0].includes(`hu-HU`)
                            ? item.languageCodes[0].replace(
                                `hu-HU`,
                                `Hungarian `
                              )
                            : item.languageCodes[0].includes(`nb-NO`)
                            ? item.languageCodes[0].replace(
                                `nb-NO`,
                                `Norwegian `
                              )
                            : item.languageCodes[0].includes(`nb-no`)
                            ? item.languageCodes[0].replace(
                                `nb-no`,
                                `Norwegian `
                              )
                            : item.languageCodes[0].includes(`nl-BE`)
                            ? item.languageCodes[0].replace(
                                `nl-BE`,
                                `Dutch(Belgium) `
                              )
                            : item.languageCodes[0].includes(`nl-NL`)
                            ? item.languageCodes[0].replace(
                                `nl-NL`,
                                `Dutch(Netherlands) `
                              )
                            : item.languageCodes[0].includes(`pt-PT`)
                            ? item.languageCodes[0].replace(
                                `pt-PT`,
                                `Portugese(Portugal) `
                              )
                            : item.languageCodes[0].includes(`sk-SK`)
                            ? item.languageCodes[0].replace(`sk-SK`, `Slovak `)
                            : item.languageCodes[0].includes(`pl-PL`)
                            ? item.languageCodes[0].replace(`pl-PL`, `Polish `)
                            : item.languageCodes[0].includes(`vi-VN`)
                            ? item.languageCodes[0].replace(
                                `vi-VN`,
                                `Vietnamese `
                              )
                            : item.languageCodes[0].includes(`pt-BR`)
                            ? item.languageCodes[0].replace(
                                `pt-BR`,
                                `Portugese(Brazil) `
                              )
                            : item.languageCodes[0].includes(`pa-IN`)
                            ? item.languageCodes[0].replace(
                                `pa-IN`,
                                `Punjabi  `
                              )
                            : null}
                        </option>
                      )}
                    </>
                  );
                })}
            </select>
          </div>

          <img
            className="revImg"
            src={pic5}
            style={{ cursor: "default" }}
            alt="icon1"
          ></img>
          <Win3
            dontPlay={true}
            dontCircle={true}
            noicon={true}
            download={download}
            closeWin={() => {
              setFiles([]);
              setTxtUrl("");
              setVoiceUrl("");
            }}
            width="1540px"
            height="600px"
            cross={true}
            text="some image"
            linkSpace={true}
            link={checked ? voiceUrl : txtUrl}
          />
        </section>
      </div>
    </>
  );
}
