import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Switch from "react-switch";
import { useState } from "react";
import SubList from "../../components/SubscriptionLists/FreeList/FreeList";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { config } from "../../elements/config";
import { useEffect } from "react";
import SEO from "layout/nextseo";

toast.configure();

export default function SubscribePlan(props) {
  let [checked, setChecked] = useState(false);
  let [payPlanId, setPayPlanId] = useState("");
  let handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    let dontKeep = window.sessionStorage.getItem(`dontKeep`);
    axios
      .get(`${config.url}api/users/me/`, {
        headers: {
          Authorization: `Bearer ${
            dontKeep
              ? window.sessionStorage.getItem(`access`)
              : window.localStorage.getItem(`access`)
          }`,
        },
      })
      .then((res) => {
        dontKeep
          ? window.sessionStorage.setItem(`data`, JSON.stringify(res.data))
          : window.localStorage.setItem(`data`, JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);

  let [showPopup, setShowPopup] = useState(false);
  let [isYear, setIsYear] = useState(null);
  let parentPopup = (planId) => {
    setPayPlanId(planId);
    setShowPopup(true);
  };
  let [paymentSteps, setPaymentSteps] = useState(1);
  console.log(payPlanId, `plan ID`);
  console.log(isYear, `isYear`);
  const payPalCheckout = async () => {
    let link = await axios.post(
      `${config.url}payments/create-payment/`,
      {
        plan_id: payPlanId,
        isYear,
      },
      {
        headers: {
          Authorization: `Bearer ${
            window.localStorage.getItem(`access`) ||
            window.sessionStorage.getItem(`access`)
          }`,
        },
      }
    );
    window.open(link.data.links[0].href);
    setShowPopup(false);
    setPaymentSteps(1);
  };
  return (
    <>
      <SEO
        title="Bayanat Speech | Subscribe Plan"
        canonicalLink="https://bayanatspeech.com/SubscribePlan"
      />

      {showPopup && (
        <>
          <div
            className="subscribeAll"
            onMouseDown={() => {
              setShowPopup(false);
              setPaymentSteps(1);
              setPayPlanId(null);
              setIsYear(null);
            }}
          >
            <div
              className="paymentWindow animate__animated animate__fadeIn"
              onMouseDown={(e) => e.stopPropagation()}
            >
              <div className="paymentMain">
                {paymentSteps === 1 && (
                  <>
                    <div className="paymentMainBtns">
                      <div className="SubscribePlanBtnDiv payButton">
                        <button
                          onClick={() => {
                            setIsYear(false);
                            setPaymentSteps(paymentSteps + 1);
                          }}
                        >
                          {" "}
                          Monthly{" "}
                        </button>
                      </div>
                      <div className="SubscribePlanBtnDiv payButton">
                        <button
                          onClick={() => {
                            setIsYear(true);
                            setPaymentSteps(paymentSteps + 1);
                          }}
                        >
                          {" "}
                          Yearly{" "}
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {paymentSteps === 2 && (
                  <>
                    <div className="paymentMainPay">
                      <div className="SubscribePlanBtnDiv payButton payButtonFinal">
                        <button onClick={payPalCheckout}> Pay </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {!props.noHeader && <Header isLoggedIn={props.isLoggedIn} />}
      <div style={{ paddingTop: "78px" }}>
        <div className="SubscribePlanMain">
          <div className="SubscribePlanFirst">
            <h1>Subscribe Plan</h1>
            <div className="SubscribePlanSwitch">
              <p>Personal</p>
              <Switch
                onChange={handleChange}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={"#ffcc80"}
                offColor={"#ffcc80"}
              />
              <p>Commercial</p>
            </div>
          </div>
          <div className="SubscribePlanSecond">
            <div className="floatDiv">
              {!checked && (
                <>
                  <SubList
                    subType={5}
                    open={parentPopup}
                    isLoggedIn={props.isLoggedIn}
                  />
                  <SubList
                    subType={6}
                    open={parentPopup}
                    isLoggedIn={props.isLoggedIn}
                  />
                  <SubList
                    subType={7}
                    open={parentPopup}
                    isLoggedIn={props.isLoggedIn}
                  />
                </>
              )}
              {checked && (
                <>
                  <SubList
                    subType={8}
                    open={parentPopup}
                    isLoggedIn={props.isLoggedIn}
                  />
                  <SubList
                    subType={9}
                    open={parentPopup}
                    isLoggedIn={props.isLoggedIn}
                  />
                  <SubList
                    subType={10}
                    open={parentPopup}
                    isLoggedIn={props.isLoggedIn}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
