export const accessTypeByPermission = (permission) => {
  let dontKeep = window.sessionStorage.getItem(`dontKeep`);
  let userData = dontKeep ? sessionStorage.getItem("data") : localStorage.getItem("data")
  userData = JSON.parse(userData);
  if(userData && userData.user_subscription)
  {
    const planData = userData.user_subscription.plan;
    if (planData[permission]) {
      return 1;
    } else {
      return 0;
    }
  }
  else{
    return 0;
  }
};
