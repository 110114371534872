import "./style.css";
import SubList from "../../SubList/SubList";
import { config } from "../../../elements/config";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

toast.configure();

export default function FreeList(props) {
  let history = useHistory();
  let active = false;
  let [planData, setPlanData] = useState([]);
  let dontKeep = window.sessionStorage.getItem(`dontKeep`);
  useEffect(() => {
    props.subType &&
      axios
        .get(`${config.url}plans/?get_all=true`)
        .then((res) => {
          setPlanData(res.data[props.subType - 5]);
        })
        .catch((err) => {
          console.log(err, `ERRRRRRRRRRRRRRRRROR123123123123`);
        });
  }, []);
  let userPlanData;
  if (window.sessionStorage.getItem(`data`) || window.localStorage.getItem(`data`)) {
    let userSubData = dontKeep ? window.sessionStorage.getItem(`data`) : window.localStorage.getItem(`data`) 
    userPlanData = JSON.parse(userSubData)
      .user_subscription
      ? JSON.parse(userSubData).user_subscription.plan
      : null;
  }
  if (
    userPlanData &&
    userPlanData.main_type === planData.main_type &&
    userPlanData.name === planData.name
  ) {
    active = true;
  }
  const handleClick = () => {
    console.log(props.subType, "props.subType");
    console.log(props.isLoggedIn, "props.isLoggedIn");
    if (props.subType === 5 && (window.sessionStorage.getItem(`data`) || window.localStorage.getItem(`data`))) {
      const data = {
        plan: 5,
      };
      axios
        .post(`${config.url}plans/subscription/activate/`, data, {
          headers: {
            Authorization: `Bearer ${
              dontKeep
                ? window.sessionStorage.getItem(`access`)
                : window.localStorage.getItem(`access`)
            }`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            axios
            .get(`${config.url}api/users/me/`, {
              headers: {
                Authorization: `Bearer ${
                  dontKeep
                    ? window.sessionStorage.getItem(`access`)
                    : window.localStorage.getItem(`access`)
                }`,
              },
            })
            .then((resData) => {
              dontKeep
                ? window.sessionStorage.setItem(`data`, JSON.stringify(resData.data))
                : window.localStorage.setItem(`data`, JSON.stringify(resData.data));
              history.push("/TextToSpeech");
            })
            .catch((err) => {
              console.log(`CATCHHHHHHHHHHHHH`);
            });
          }
        })
        .catch((err) => {
          console.log(err, `ERRRRRRRRRRRRRRRRROR`);
        });
      return;
    } else {
      if (window.sessionStorage.getItem(`data`) || window.localStorage.getItem(`data`)) {
        props.open(planData.id);
      } else {
        history.push(`/login`);
      }
    }
  };
  return (
    <>
      <div
        className={`SubscribePlanSecondDiv ${
          userPlanData &&
          userPlanData.main_type === planData.main_type &&
          userPlanData.name === planData.name
            ? "activePlan"
            : ""
        } ${userPlanData && planData.id < userPlanData.id ? "disable" : ""}`}
      >
        {planData.length !== 0 && (
          <>
            <div className="freeDiv">
              <p>{planData.name}</p>
              {planData.type !== "free" && (
                <>
                  <p>
                    {`$${planData.credit_per_mount} `} <span>month</span>{" "}
                  </p>
                  <p>
                    {`$${planData.price_per_year} `} <span>year</span>{" "}
                  </p>
                </>
              )}
            </div>
            <div className="Lists">
              <SubList check={true} text={"42 Languages"} />
              <SubList check={true} text={"Premium and (100+) voices"} />

 <SubList check={true} text={`${(( planData.type === 'free') ) ? 'Limited characters' : `${planData.characters_month} characters / month`}`} />

               {/*<SubList
                check={true}
                text={`${planData.characters_month} characters / month`}
              />*/}
            {/*  <SubList
                check={
                  planData.saved_audio_files_history_count !== 0 ? true : false
                }
                text={`Audio files in History ${
                  planData.saved_audio_files_history_count !== 0
                    ? `${planData.saved_audio_files_history_count}`
                    : ``
                }`}
              />*/}
      {/*<SubList
                check={planData.convert_to_speech !== null ? true : false}
                text={"text to Speech"}
              />*/}
              {/* <SubList
                check={planData.convert_to_text !== null ? true : false}
                text={"OCR, PDF to text"}
              /> */}
              <SubList check={planData.translation ? true : false} text={"Translation"} />
              <SubList
                check={planData.steganography !== null ? true : false}
                text={"Steganography"}
              />
              <SubList
                check={planData.download_audio_files_limit !== 0 ? true : false}
                text={`Download Audio Files(unlimited)`}
              />
            </div>
            <div className="SubscribePlanBtnDiv">
              <button disabled={active ? true : false} onClick={handleClick}>
                {" "}
                {active
                  ? "Active"
                  : `Start ${planData.type === "free" ? "free" : ""} Now`}{" "}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
