import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SEO from "layout/nextseo";

const Privacy = () => {
  return (
    <>
      <Header />
      <SEO
        title="Bayanat Speech | Privacy"
        canonicalLink="https://bayanatspeech.com/privacy"
      />
      <section className="footerMenuPages wrapper">
        {/*<ul>*/}
        <h3 className="footerMenuPages__title wrapper">Your Data</h3>
        <p className="footerMenuPages__list">
          {" "}
          We will respect your right to privacy and treat your personal data in
          accordance with all applicable laws. We only have your email, your
          name (if you tell us) and your safely encrypted password. We don’t
          share these data with any third parties. You may request to delete
          your personal data upon your request by contacting us.
        </p>
        <h3 className="footerMenuPages__title wrapper">Analytics</h3>
        <p className="footerMenuPages__paragraph wrapper">
          This website uses Google Analytics, a web analytics service provided
          by Google, Inc. (“Google”). Google Analytics uses “cookies” to help
          the website analyze how users use the site. The information generated
          by the cookie about your use of the website will be transmitted to and
          stored by Google on servers in the United States. In case
          IP-anonymization is activated on this website, your IP address will be
          truncated within the area of Member States of the European Union or
          other parties to the Agreement on the European Economic Area. Google
          will use this information on behalf of the operator of this website
          for the purpose of evaluating your use of the website, compiling
          reports on website activity for website operators and providing them
          other services relating to website activity and internet usage. The
          IP-address, that your Browser conveys within the scope of Google
          Analytics, will not be associated with any other data held by Google.
          You may refuse the use of cookies by selecting the appropriate
          settings on your browser, however please note that if you do this you
          may not be able to use the full functionality of this website. You can
          also opt-out from being tracked by Google Analytics with effect for
          the future by downloading and installing Google Analytics Opt-out
          Browser Addon for your current web browser.
        </p>
        <h3 className="footerMenuPages__title wrapper">Refund Policy</h3>
        <p className="footerMenuPages__paragraph wrapper">
          NO-refund policy for all BayanatSpeech subscription products. We
          strongly recommend that all customers try before you buy. We provide a
          free trial version to let you fully evaluate our BayanatSpeech
          application before you make a purchase decision. You have no
          obligations to purchase and you won’t be asked to provide any payment
          information until you decide to buy. If you purchase one of our plans,
          after your payment has cleared you will receive an email with the
          order information. You can cancel the auto-renewal for your
          subscription at any time through your account’s Billing Information.
          Your paid access will continue to be active until the end of your
          billing cycle.
        </p>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
