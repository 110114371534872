import React from "react";
import "./countlimit.style.css";
import { useHistory } from "react-router-dom";

const CountLimitPopUp = ({ setShow }) => {
  const history = useHistory();
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <div className={`modal`}>
        <div class="modal_content animate__animated animate__fadeIn">
          <div class="m-content">
            <div className="m-close-icon" onClick={() => handleClose()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
            <p>
              Your charachters limit has been exchausted, kindly explore more
              plans
            </p>
            <button
              className="m-close"
              onClick={() => {
                handleClose();
                history.push("/Upgrade");
              }}
            >
              Explore More Plans
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountLimitPopUp;
