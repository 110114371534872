import Header2 from "../../components/header/Header2";
import LeftBar from "../../components/leftBar/LeftBar";
import TranslateList from "../../components/list/lists/TranslateList";
import { Translator, Translate } from "react-auto-translate";
import loadingPic from "../../assets/images/scr2.png";
import "./style.css";
import { useEffect, useState } from "react";
import closeIcon from "../../assets/images/closeIcon.png";
import Refresh from "../../elements/refresh";
import SEO from "layout/nextseo";

export default function TranslatePage(props) {
  useEffect(() => {
    // Refresh();
  }, []);
  let [transText, setTransText] = useState("");
  let putText = (e) => {
    setTransText(e.target.value);
  };
  let [fromText, setFromText] = useState("en");
  let [toText, setToText] = useState("ar");

  let changePlaces = () => {
    let fromValue = document.querySelector(`#FromTab .tdiv .TranslateSel`);
    let ToValue = document.querySelector(`#ToTab .tdiv .TranslateSel`);
    let a = fromValue.value;
    fromValue.value = ToValue.value;
    ToValue.value = a;
    switchStates();
    switchValue();
  };

  let switchStates = () => {
    let a = fromText;
    let b = toText;
    let c = b;
    b = a;
    a = c;
    setFromText(a);
    setToText(b);
  };

  let switchValue = () => {
    let fromVal = document.querySelector(`#fromTab`);
    let a = fromVal.value;
    let toVal = document.querySelector(`#toTab p`);
    fromVal.value = toVal.innerHTML;
    setTransText(a);
  };
  let clearAll = () => {
    setTransText("");
    let fromVal = document.querySelector(`#fromTab`);
    let toVal = document.querySelector(`#toTab p`);
    fromVal.value = "";
    toVal.value = "";
  };

  let changeFromText = (e, FromTrue) => {
    if (e.currentTarget.value === "Arabic") {
      if (FromTrue) {
        setFromText(`ar`);
      } else {
        setToText(`ar`);
      }
    }
    if (e.currentTarget.value === "Afrikaans") {
      if (FromTrue) {
        setFromText(`af`);
      } else {
        setToText(`af`);
      }
    }
    if (e.currentTarget.value === "Albanian") {
      if (FromTrue) {
        setFromText(`sq`);
      } else {
        setToText(`sq`);
      }
    }
    if (e.currentTarget.value === "Amharic") {
      if (FromTrue) {
        setFromText(`am`);
      } else {
        setToText(`am`);
      }
    }
    if (e.currentTarget.value === "Armenian") {
      if (FromTrue) {
        setFromText(`hy`);
      } else {
        setToText(`hy`);
      }
    }
    if (e.currentTarget.value === "Azerbaijani") {
      if (FromTrue) {
        setFromText(`az`);
      } else {
        setToText(`az`);
      }
    }
    if (e.currentTarget.value === "Basque") {
      if (FromTrue) {
        setFromText(`eu`);
      } else {
        setToText(`eu`);
      }
    }
    if (e.currentTarget.value === "Belarusian") {
      if (FromTrue) {
        setFromText(`be`);
      } else {
        setToText(`be`);
      }
    }
    if (e.currentTarget.value === "Bengali") {
      if (FromTrue) {
        setFromText(`bn`);
      } else {
        setToText(`bn`);
      }
    }
    if (e.currentTarget.value === "Bosnian") {
      if (FromTrue) {
        setFromText(`bs`);
      } else {
        setToText(`bs`);
      }
    }
    if (e.currentTarget.value === "Bulgarian") {
      if (FromTrue) {
        setFromText(`bg`);
      } else {
        setToText(`bg`);
      }
    }
    if (e.currentTarget.value === "Catalan") {
      if (FromTrue) {
        setFromText(`ca`);
      } else {
        setToText(`ca`);
      }
    }
    if (e.currentTarget.value === "Cebuano") {
      if (FromTrue) {
        setFromText(`ceb`);
      } else {
        setToText(`ceb`);
      }
    }
    if (e.currentTarget.value === "Chinese") {
      if (FromTrue) {
        setFromText(`zh`);
      } else {
        setToText(`zh`);
      }
    }
    if (e.currentTarget.value === "Corsican") {
      if (FromTrue) {
        setFromText(`co`);
      } else {
        setToText(`co`);
      }
    }
    if (e.currentTarget.value === "Croatian") {
      if (FromTrue) {
        setFromText(`hr`);
      } else {
        setToText(`hr`);
      }
    }
    if (e.currentTarget.value === "Czech") {
      if (FromTrue) {
        setFromText(`cs`);
      } else {
        setToText(`cs`);
      }
    }
    if (e.currentTarget.value === "Danish") {
      if (FromTrue) {
        setFromText(`da`);
      } else {
        setToText(`da`);
      }
    }
    if (e.currentTarget.value === "Dutch") {
      if (FromTrue) {
        setFromText(`nl`);
      } else {
        setToText(`nl`);
      }
    }
    if (e.currentTarget.value === "English") {
      if (FromTrue) {
        setFromText(`en`);
      } else {
        setToText(`en`);
      }
    }
    if (e.currentTarget.value === "Esperanto") {
      if (FromTrue) {
        setFromText(`eo`);
      } else {
        setToText(`eo`);
      }
    }
    if (e.currentTarget.value === "Estonian") {
      if (FromTrue) {
        setFromText(`et`);
      } else {
        setToText(`et`);
      }
    }
    if (e.currentTarget.value === "Finnish") {
      if (FromTrue) {
        setFromText(`fi`);
      } else {
        setToText(`fi`);
      }
    }
    if (e.currentTarget.value === "French") {
      if (FromTrue) {
        setFromText(`fr`);
      } else {
        setToText(`fr`);
      }
    }
    if (e.currentTarget.value === "Frisian") {
      if (FromTrue) {
        setFromText(`fy`);
      } else {
        setToText(`fy`);
      }
    }
    if (e.currentTarget.value === "Galician") {
      if (FromTrue) {
        setFromText(`gl`);
      } else {
        setToText(`gl`);
      }
    }
    if (e.currentTarget.value === "Georgian") {
      if (FromTrue) {
        setFromText(`ka`);
      } else {
        setToText(`ka`);
      }
    }
    if (e.currentTarget.value === "German") {
      if (FromTrue) {
        setFromText(`de`);
      } else {
        setToText(`de`);
      }
    }
    if (e.currentTarget.value === "Greek") {
      if (FromTrue) {
        setFromText(`el`);
      } else {
        setToText(`el`);
      }
    }
    if (e.currentTarget.value === "Gujarati") {
      if (FromTrue) {
        setFromText(`gu`);
      } else {
        setToText(`gu`);
      }
    }
    if (e.currentTarget.value === "Haitian Creole") {
      if (FromTrue) {
        setFromText(`ht`);
      } else {
        setToText(`ht`);
      }
    }
    if (e.currentTarget.value === "Hausa") {
      if (FromTrue) {
        setFromText(`ha`);
      } else {
        setToText(`ha`);
      }
    }
    if (e.currentTarget.value === "Hawaiian") {
      if (FromTrue) {
        setFromText(`haw`);
      } else {
        setToText(`haw`);
      }
    }
    if (e.currentTarget.value === "Hebrew") {
      if (FromTrue) {
        setFromText(`he`);
      } else {
        setToText(`he`);
      }
    }
    if (e.currentTarget.value === "Hindi") {
      if (FromTrue) {
        setFromText(`hi`);
      } else {
        setToText(`hi`);
      }
    }
    if (e.currentTarget.value === "Hmong") {
      if (FromTrue) {
        setFromText(`hmn`);
      } else {
        setToText(`hmn`);
      }
    }
    if (e.currentTarget.value === "Hungarian") {
      if (FromTrue) {
        setFromText(`hu`);
      } else {
        setToText(`hu`);
      }
    }
    if (e.currentTarget.value === "Icelandic") {
      if (FromTrue) {
        setFromText(`is`);
      } else {
        setToText(`is`);
      }
    }
    if (e.currentTarget.value === "Igbo") {
      if (FromTrue) {
        setFromText(`ig`);
      } else {
        setToText(`ig`);
      }
    }
    if (e.currentTarget.value === "Indonesian") {
      if (FromTrue) {
        setFromText(`id`);
      } else {
        setToText(`id`);
      }
    }
    if (e.currentTarget.value === "Irish") {
      if (FromTrue) {
        setFromText(`ga`);
      } else {
        setToText(`ga`);
      }
    }
    if (e.currentTarget.value === "Italian") {
      if (FromTrue) {
        setFromText(`it`);
      } else {
        setToText(`it`);
      }
    }
    if (e.currentTarget.value === "Japanese") {
      if (FromTrue) {
        setFromText(`ja`);
      } else {
        setToText(`ja`);
      }
    }
    if (e.currentTarget.value === "Javanese") {
      if (FromTrue) {
        setFromText(`jv`);
      } else {
        setToText(`jv`);
      }
    }
    if (e.currentTarget.value === "Kannada") {
      if (FromTrue) {
        setFromText(`kn`);
      } else {
        setToText(`kn`);
      }
    }
    if (e.currentTarget.value === "Kazakh") {
      if (FromTrue) {
        setFromText(`kk`);
      } else {
        setToText(`kk`);
      }
    }
    if (e.currentTarget.value === "Khmer") {
      if (FromTrue) {
        setFromText(`km`);
      } else {
        setToText(`km`);
      }
    }
    if (e.currentTarget.value === "Kinyarwanda") {
      if (FromTrue) {
        setFromText(`rw`);
      } else {
        setToText(`rw`);
      }
    }
    if (e.currentTarget.value === "Korean") {
      if (FromTrue) {
        setFromText(`ko`);
      } else {
        setToText(`ko`);
      }
    }
    if (e.currentTarget.value === "Kurdish") {
      if (FromTrue) {
        setFromText(`ku`);
      } else {
        setToText(`ku`);
      }
    }
    if (e.currentTarget.value === "Kyrgyz") {
      if (FromTrue) {
        setFromText(`ky`);
      } else {
        setToText(`ky`);
      }
    }
    if (e.currentTarget.value === "Lao") {
      if (FromTrue) {
        setFromText(`lo`);
      } else {
        setToText(`lo`);
      }
    }
    if (e.currentTarget.value === "Latin") {
      if (FromTrue) {
        setFromText(`la`);
      } else {
        setToText(`la`);
      }
    }
    if (e.currentTarget.value === "Latvian") {
      if (FromTrue) {
        setFromText(`lv`);
      } else {
        setToText(`lv`);
      }
    }
    if (e.currentTarget.value === "Lithuanian") {
      if (FromTrue) {
        setFromText(`lt`);
      } else {
        setToText(`lt`);
      }
    }
    if (e.currentTarget.value === "Luxembourgish") {
      if (FromTrue) {
        setFromText(`lb`);
      } else {
        setToText(`lb`);
      }
    }
    if (e.currentTarget.value === "Macedonian") {
      if (FromTrue) {
        setFromText(`mk`);
      } else {
        setToText(`mk`);
      }
    }
    if (e.currentTarget.value === "Malagasy") {
      if (FromTrue) {
        setFromText(`mg`);
      } else {
        setToText(`mg`);
      }
    }
    if (e.currentTarget.value === "Malay") {
      if (FromTrue) {
        setFromText(`ms`);
      } else {
        setToText(`ms`);
      }
    }
    if (e.currentTarget.value === "Malayalam") {
      if (FromTrue) {
        setFromText(`ml`);
      } else {
        setToText(`ml`);
      }
    }
    if (e.currentTarget.value === "Maltese") {
      if (FromTrue) {
        setFromText(`mt`);
      } else {
        setToText(`mt`);
      }
    }
    if (e.currentTarget.value === "Maori") {
      if (FromTrue) {
        setFromText(`mi`);
      } else {
        setToText(`mi`);
      }
    }
    if (e.currentTarget.value === "Marathi") {
      if (FromTrue) {
        setFromText(`mr`);
      } else {
        setToText(`mr`);
      }
    }
    if (e.currentTarget.value === "Mongolian") {
      if (FromTrue) {
        setFromText(`mn`);
      } else {
        setToText(`mn`);
      }
    }
    if (e.currentTarget.value === "Myanmar") {
      if (FromTrue) {
        setFromText(`my`);
      } else {
        setToText(`my`);
      }
    }
    if (e.currentTarget.value === "Nepali") {
      if (FromTrue) {
        setFromText(`ne`);
      } else {
        setToText(`ne`);
      }
    }
    if (e.currentTarget.value === "Norwegian") {
      if (FromTrue) {
        setFromText(`no`);
      } else {
        setToText(`no`);
      }
    }
    if (e.currentTarget.value === "Nyanja") {
      if (FromTrue) {
        setFromText(`ny`);
      } else {
        setToText(`ny`);
      }
    }
    if (e.currentTarget.value === "Odia") {
      if (FromTrue) {
        setFromText(`or`);
      } else {
        setToText(`or`);
      }
    }
    if (e.currentTarget.value === "Pashto") {
      if (FromTrue) {
        setFromText(`ps`);
      } else {
        setToText(`ps`);
      }
    }
    if (e.currentTarget.value === "Persian") {
      if (FromTrue) {
        setFromText(`fa`);
      } else {
        setToText(`fa`);
      }
    }
    if (e.currentTarget.value === "Polish") {
      if (FromTrue) {
        setFromText(`pl`);
      } else {
        setToText(`pl`);
      }
    }
    if (e.currentTarget.value === "Punjabi") {
      if (FromTrue) {
        setFromText(`pa`);
      } else {
        setToText(`pa`);
      }
    }
    if (e.currentTarget.value === "Romanian") {
      if (FromTrue) {
        setFromText(`ro`);
      } else {
        setToText(`ro`);
      }
    }
    if (e.currentTarget.value === "Russian") {
      if (FromTrue) {
        setFromText(`ru`);
      } else {
        setToText(`ru`);
      }
    }
    if (e.currentTarget.value === "Samoan") {
      if (FromTrue) {
        setFromText(`sm`);
      } else {
        setToText(`sm`);
      }
    }
    if (e.currentTarget.value === "Scots Gaelic") {
      if (FromTrue) {
        setFromText(`gd`);
      } else {
        setToText(`gd`);
      }
    }
    if (e.currentTarget.value === "Serbian") {
      if (FromTrue) {
        setFromText(`sr`);
      } else {
        setToText(`sr`);
      }
    }
    if (e.currentTarget.value === "Sesotho") {
      if (FromTrue) {
        setFromText(`st`);
      } else {
        setToText(`st`);
      }
    }
    if (e.currentTarget.value === "Shona") {
      if (FromTrue) {
        setFromText(`sn`);
      } else {
        setToText(`sn`);
      }
    }
    if (e.currentTarget.value === "Sindhi") {
      if (FromTrue) {
        setFromText(`sd`);
      } else {
        setToText(`sd`);
      }
    }
    if (e.currentTarget.value === "Sinhala") {
      if (FromTrue) {
        setFromText(`si`);
      } else {
        setToText(`si`);
      }
    }
    if (e.currentTarget.value === "Slovak") {
      if (FromTrue) {
        setFromText(`sk`);
      } else {
        setToText(`sk`);
      }
    }
    if (e.currentTarget.value === "Slovenian") {
      if (FromTrue) {
        setFromText(`sl`);
      } else {
        setToText(`sl`);
      }
    }
    if (e.currentTarget.value === "Somali") {
      if (FromTrue) {
        setFromText(`so`);
      } else {
        setToText(`so`);
      }
    }
    if (e.currentTarget.value === "Spanish") {
      if (FromTrue) {
        setFromText(`es`);
      } else {
        setToText(`es`);
      }
    }
    if (e.currentTarget.value === "Sundanese") {
      if (FromTrue) {
        setFromText(`su`);
      } else {
        setToText(`su`);
      }
    }
    if (e.currentTarget.value === "Swahili") {
      if (FromTrue) {
        setFromText(`sw`);
      } else {
        setToText(`sw`);
      }
    }
    if (e.currentTarget.value === "Swedish") {
      if (FromTrue) {
        setFromText(`sv`);
      } else {
        setToText(`sv`);
      }
    }
    if (e.currentTarget.value === "Tagalog") {
      if (FromTrue) {
        setFromText(`tl`);
      } else {
        setToText(`tl`);
      }
    }
    if (e.currentTarget.value === "Tajik") {
      if (FromTrue) {
        setFromText(`tg`);
      } else {
        setToText(`tg`);
      }
    }
    if (e.currentTarget.value === "Tamil") {
      if (FromTrue) {
        setFromText(`ta`);
      } else {
        setToText(`ta`);
      }
    }
    if (e.currentTarget.value === "Tatar") {
      if (FromTrue) {
        setFromText(`tt`);
      } else {
        setToText(`tt`);
      }
    }
    if (e.currentTarget.value === "Telugu") {
      if (FromTrue) {
        setFromText(`te`);
      } else {
        setToText(`te`);
      }
    }
    if (e.currentTarget.value === "Thai") {
      if (FromTrue) {
        setFromText(`th`);
      } else {
        setToText(`th`);
      }
    }
    if (e.currentTarget.value === "Turkish") {
      if (FromTrue) {
        setFromText(`tr`);
      } else {
        setToText(`tr`);
      }
    }
    if (e.currentTarget.value === "Turkmen") {
      if (FromTrue) {
        setFromText(`tk`);
      } else {
        setToText(`tk`);
      }
    }
    if (e.currentTarget.value === "Urdu") {
      if (FromTrue) {
        setFromText(`ur`);
      } else {
        setToText(`ur`);
      }
    }
    if (e.currentTarget.value === "Uyghur") {
      if (FromTrue) {
        setFromText(`ug`);
      } else {
        setToText(`ug`);
      }
    }
    if (e.currentTarget.value === "Uzbek") {
      if (FromTrue) {
        setFromText(`uz`);
      } else {
        setToText(`uz`);
      }
    }
    if (e.currentTarget.value === "Vietnamese") {
      if (FromTrue) {
        setFromText(`vi`);
      } else {
        setToText(`vi`);
      }
    }
    if (e.currentTarget.value === "Welsh") {
      if (FromTrue) {
        setFromText(`cy`);
      } else {
        setToText(`cy`);
      }
    }
    if (e.currentTarget.value === "Xhosa") {
      if (FromTrue) {
        setFromText(`xh`);
      } else {
        setToText(`xh`);
      }
    }
    if (e.currentTarget.value === "Yiddish") {
      if (FromTrue) {
        setFromText(`yi`);
      } else {
        setToText(`yi`);
      }
    }
    if (e.currentTarget.value === "Yoruba") {
      if (FromTrue) {
        setFromText(`yo`);
      } else {
        setToText(`yo`);
      }
    }
    if (e.currentTarget.value === "Zulu") {
      if (FromTrue) {
        setFromText(`zu`);
      } else {
        setToText(`zu`);
      }
    }
  };
  return (
    <>
      <Header2 title="Translate" />
      <SEO
        title="Bayanat Speech | Translate"
        canonicalLink="https://bayanatspeech.com/Translate"
      />
      <LeftBar />
      <div
        style={{ paddingTop: "73px" }}
        className="animate__animated animate__slideInLeft"
      >
        <div className="TranslateSection">
          <div className="TranslateSectionFirst">
            <div className="TranslateSectionFirstForm">
              <p>From</p>
              <div className="TranslateSectionFirstFormList">
                <div id="FromTab">
                  <TranslateList
                    onClick={(e) => {
                      changeFromText(e, true);
                    }}
                  />
                </div>
              </div>
              <div className="TranslateArea">
                <div className="TranslateAreaUp">
                  <div className="xxx" onClick={clearAll}>
                    <img className="img1" src={closeIcon} alt="icon1"></img>
                  </div>
                </div>
                <div className="TranslateAreaDown">
                  <textarea
                    className="TranslateTextArea"
                    onChange={putText}
                    id="fromTab"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="TranslateSectionFirstMiddle">
              <img src={loadingPic} alt="icon1" onClick={changePlaces} />
            </div>
            <div className="TranslateSectionFirstForm">
              <p>To</p>
              <div className="TranslateSectionFirstFormList">
                <div id="ToTab">
                  <TranslateList
                    onClick={(e) => {
                      changeFromText(e, false);
                    }}
                    isArabic={true}
                  />
                </div>
              </div>
              <div className="TranslateArea">
                <div className="TranslateAreaUp">
                  <div className="xxx" onClick={clearAll}>
                    <img className="img1" src={closeIcon} alt="icon1"></img>
                  </div>
                </div>
                <div className="TranslateAreaDown">
                  <div className="TranslateTextArea" id="toTab">
                    <Translator
                      from={fromText}
                      to={toText}
                      googleApiKey="AIzaSyCrt8DLsdE47-gbGSeLXBbzGJVI6r8LjfI"
                    >
                      <p>
                        <Translate>{transText}</Translate>
                      </p>
                    </Translator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
