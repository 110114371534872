import './example.css';
import { useEffect, useState } from 'react';
import WinSteg from '../WinSteg/WinSteg';
import box from '../../assets/images/box.png'
import axios from 'axios';
import { config } from '../../elements/config';
import fileDownload from 'js-file-download';
import Loading from '../../elements/Loading';

export default function Example2(props) {
    let [step, setStep] = useState(1)
    let [data, setData] = useState({})
    let [isLoading, setIsLoading] = useState(false)
    let [originalUrl, setOriginalUrl] = useState('')
    let [secretUrl, setSecretUrl] = useState('')
    let [foreignName, setForeignName] = useState('')
    useEffect(() => {
        setForeignName(data?.name?.slice(0, data?.name?.length - 4))
    }, [data])
    let extractStego = () => {
        setIsLoading(true)
        let a = new FormData()
        a.append(`msg`, data)
        axios.post(`${config.url}convert/steganography-recover/${foreignName}/`, a, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem(`access`) || window.sessionStorage.getItem(`access`)}`
            }
        }).then(res => {
            console.log(res);
            setOriginalUrl(`${config.media}${res.data.original}`);
            setSecretUrl(`${config.media}${res.data.secret}`);
            setStep(step + 1)
            setIsLoading(false)
        }).catch(err => {
            console.log(err);
            setIsLoading(false)
        })
    }
    let downloadOriginal = () => {
        console.log(`works`);
        axios.get(originalUrl, {
            responseType: `blob`
        }).then(res => {
            fileDownload(res.data, `original.wav`)
        })
    }
    let downloadSecret = () => {
        console.log(`works`);
        axios.get(secretUrl, {
            responseType: `blob`
        }).then(res => {
            fileDownload(res.data, `secret.wav`)
        })
    }
    return (
        <>
          
            {step === 1 ? <> <section className='stepsec example2sec animate__animated animate__fadeInLeftBig'>
            <div className='warning-line animate__animated animate__fadeInLeftBig stegoWarning stegoWarningExtract'>
                <p><span>Warning:</span> In order to extract the project you created, Do NOT rename the file</p>
            </div>
                <p className='newp1'>Upload the Steganography to extract original and secret messages</p>
                <WinSteg setData={setData} width='900px' height='360px' />
            </section> </> :
                <section className='stepsec'>
                    <img className='step3img' src={box} alt='box'></img>
                    <h1 className='hh1'>Your files are ready</h1>
                    <button className='btn step4btn' onClick={downloadSecret}> <div className='step4img'></div> <p>Save the secret message</p></button>
                    <button className='btn step4btn' onClick={downloadOriginal}> <div className='step4img'></div><p>Save the original message</p></button>
                </section>}
            <div className='animate__animated animate__fadeInLeftBig'>
                <div className='example2sec'><button onClick={step !== 3 ? extractStego : () => (alert('stop'))} className='btn' style={step !== 1 ? { display: 'none' } : null}> Extract </button></div>
                {step !== 1 && <a href='./createSteganography' className='a1'>Back to the main page</a>}
                {isLoading && <div className='MixFilesLoading StegoExtract'><Loading size={40} /></div>}
            </div>
        </>
    )
}