import "./style.css";
import logo from "../../assets/images/logo.png";
import icon13 from "../../assets/images/leftBar/13.png";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { GoogleLogout } from "react-google-login";
import axios from "axios";
import { config } from "../../elements/config";
import { useEffect } from "react";
import CancelPlan from "../cancel-subscription/cancelSubscription.component";

export default function LeftBar(props) {
  let history = useHistory();

  const [showCancelPlan,setShowCancelPlan] = useState(false);
  let [isSuccessChange, setIsSuccessChange] = useState(false);
  let [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    axios
      .get(`${config.url}api/users/me/`, {
        headers: {
          Authorization: `Bearer ${
            dontKeep
              ? window.sessionStorage.getItem(`access`)
              : window.localStorage.getItem(`access`)
          }`,
        },
      })
      .then((resData) => {
        dontKeep
          ? window.sessionStorage.setItem(`data`, JSON.stringify(resData.data))
          : window.localStorage.setItem(`data`, JSON.stringify(resData.data));
      })
      .catch((err) => {
        console.log(`CATCHHHHHHHHHHHHH`);
      });
  }, []);

  const [isToggle, setIsToggle] = useState(false);

  let dontKeep = window.sessionStorage.getItem(`dontKeep`);
  let userData = dontKeep
    ? sessionStorage.getItem("data")
    : localStorage.getItem("data");
  userData = JSON.parse(userData);

  const sidebarToggle = () => {
    setIsToggle(!isToggle);
    document.body.classList.remove("sidebar-open");
  };

  const googleLogout = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    history.push("/login");
  };

  const cancelSubscription = () => {
    axios
      .get(`${config.url}plans/cancel-subscription/`, {
        headers: {
          Authorization: `Bearer ${
            dontKeep
              ? window.sessionStorage.getItem(`access`)
              : window.localStorage.getItem(`access`)
          }`,
        },
      })
      .then((resData) => {
        if (resData.data.status === 200) {
          axios
            .get(`${config.url}api/users/me/`, {
              headers: {
                Authorization: `Bearer ${
                  dontKeep
                    ? window.sessionStorage.getItem(`access`)
                    : window.localStorage.getItem(`access`)
                }`,
              },
            })
            .then((resData) => {
              dontKeep
                ? window.sessionStorage.setItem(
                    `data`,
                    JSON.stringify(resData.data)
                  )
                : window.localStorage.setItem(
                    `data`,
                    JSON.stringify(resData.data)
                  );
              history.push("/Upgrade");
            })
            .catch((err) => {
              console.log(`CATCHHHHHHHHHHHHH`);
            });
        }
      })
      .catch((err) => {
        console.log(`CATCHHHHHHHHHHHHH`);
      });
  };

  return (
    <>
      <div
        style={isSuccessChange ? { display: `flex` } : { display: "none" }}
        className="MyAccoutAll"
      >
        <div
          className={`MyAccountPopup LeftBarMyAccountPopup animate__animated animate__flipInX ${
            isClosed && "animate__hinge"
          }`}
        >
          <p className="MyAccountPopupP2">Would you like to Log out ? </p>
          <div className="MyAccountPopupSelect">
            <p
              onClick={async() => {
                if (localStorage.getItem("loginType") === "facebook") {
                  window.FB.logout();
                }
                await window.localStorage.clear();
                await window.sessionStorage.clear();
                await history.push("/");
              }}
            >
              Sure
            </p>
            <p
              onClick={() => {
                setIsClosed(true);
                setTimeout(() => {
                  setIsSuccessChange(false);
                  setIsClosed(false);
                }, 2160);
              }}
            >
              No, Thanks
            </p>
          </div>
        </div>
      </div>
      <div className="leftBar">
        <div className="sidetoggle-close" onClick={sidebarToggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>

        <img
          src={logo}
          alt="logo"
          className="leftBarLogo"
          onClick={() => history.push(`/`)}
        />
        <NavLink to="textToSpeech" className="leftBarList">
          <div className="leftbardiv1 divall"></div>
          <p>Text To Speech</p>
        </NavLink>
        {/* <NavLink to='WebtoSpeech' className='leftBarList'>
                    <div className='leftbardiv2 divall'></div>
                    <p>Web to Speech</p>
                </NavLink> */}
        {/* <NavLink to="OCR" className="leftBarList">
          <div className="leftbardiv3 divall"></div>
          <p>PDF to text</p>
        </NavLink> */}
        <NavLink to="Translate" className="leftBarList">
          <div className="leftbardiv4 divall"></div>
          <p>Translation</p>
        </NavLink>
        <NavLink to="createSteganography" className="leftBarList">
          <div className="leftbardiv5 divall"></div>
          <p>Steganography</p>
        </NavLink>
        {/* <NavLink to='MP3TracksMerger' className='leftBarList'>
                    <div className='leftbardiv6 divall' ></div>
                    <p>MP3 tracks merger</p>
                </NavLink> */}
        {/* <NavLink to='MP3TracksMerger' className='leftBarList'>
                    <div className='leftbardiv6 divall' ></div>
                    <p>MP3 tracks merger</p>
                </NavLink>
                <NavLink to='VideoEdit' className='leftBarList'>
                    <div className='leftbardiv7 divall' ></div>
                    <p>audio with videos</p>
                </NavLink> */}
        <div className="leftBarListLine"></div>
        <NavLink to="MyAccount" className="leftBarList">
          <div className="leftbardiv8 divall"></div>
          <p>My Account</p>
        </NavLink>
{/*<NavLink to="History" className="leftBarList">
          <div className="leftbardiv9 divall"></div>
          <p>History</p>
        </NavLink>*/}
        <NavLink to="Upgrade" className="leftBarList">
          <div className="leftbardiv10 divall"></div>
          <p>Upgrade</p>
        </NavLink>
        {userData && userData.user_subscription ? (
          <>{userData.user_subscription.plan.id === 5 ? null : 
          <div className="leftBarList cancel-sub" onClick={() => setShowCancelPlan(true)}>
            {/* <div className="leftbardiv10 divall"></div> */}
            <div className="divall">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-octagon"
                viewBox="0 0 16 16"
              >
                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
            <p>Cancel Subscription</p>
          </div>
        }</>
        ) : null}
        {showCancelPlan ? <CancelPlan setShow={setShowCancelPlan} cancelPlan={cancelSubscription}/> : null}
        {localStorage.getItem("loginType") === "google" ? (
          <GoogleLogout
            render={(renderProps) => (
              <div onClick={renderProps.onClick} className="leftBarList">
                <img src={icon13} alt="icon13" />
                <p>Log out</p>
              </div>
            )}
            clientId="352606531076-0drt3a1ssjju2ralnu12bbmaqkdd1lsp.apps.googleusercontent.com"
            buttonText="Logout"
            onLogoutSuccess={googleLogout}
          ></GoogleLogout>
        ) : null}
        {localStorage.getItem("loginType") === "facebook" ? (
          <div
            onClick={() => {
              setIsSuccessChange(true);
            }}
            className="leftBarList"
          >
            <img src={icon13} alt="icon13" />
            <p>Log out</p>
          </div>
        ) : null}
        {localStorage.getItem("loginType") === "normal" ? (
          <div
            onClick={() => {
              setIsSuccessChange(true);
            }}
            className="leftBarList"
          >
            <img src={icon13} alt="icon13" />
            <p>Log out</p>
          </div>
        ) : null}
      </div>
    </>
  );
}
