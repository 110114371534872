import { NavLink, useHistory } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./style.css";
import googleicon from "../../assets/images/g.png";
import facebookicon from "../../assets/images/f.png";
import { useState } from "react";
import axios from "axios";
import { config } from "../../elements/config";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import SEO from "layout/nextseo";

export default function ForgotPassword(props) {
  const history = useHistory();
  let [emailData, setEmailData] = useState({
    email: "",
    url: `${config.frontEndUrl}ResetPassword`,
  });
  let [fetchSuccess, setFetchSuccess] = useState("");
  let [googleId, setTokenIdGoogle] = useState("");
  let [fbId, setTokenIdFb] = useState("");
  let [fetchError, setFetchError] = useState({
    email: "",
    password: "",
    detail: "",
  });
  let staticFetchError = {
    email: "",
    password: "",
    detail: "",
  };
  function handleClickForgot() {
    if (emailData.email.includes("@")) {
      axios
        .post(`${config.url}auth/request-reset-email/`, {
          email: emailData.email,
          redirect_url: emailData.url,
        })
        .then((res) => {
          setFetchSuccess(res.data.success);
          setFetchError(staticFetchError);
        })
        .catch((rej) => {
          setFetchError(rej.response.data);
          setFetchSuccess("");
        });
    } else {
      setFetchError({ email: "Input right email" });
    }
  }

  function googleLogin(resGoogle) {
    axios
      .post(`${config.url}social_auth/google/`, {
        auth_token: resGoogle.tokenId,
      })
      .then((access) => {
        setTokenIdGoogle(access.data.tokens.access);
        axios
          .get(`${config.url}api/users/me/`, {
            headers: {
              Authorization: `Bearer ${
                googleId || fbId || access.data.tokens.access
              }`,
            },
          })
          .then((meData) => {
            localStorage.setItem(
              "GoogleUserData",
              JSON.stringify(meData?.data)
            );
            // axios
            //   .post(`${config.url}auth/token/refresh/`, {
            //     refresh: access.data.tokens.refresh,
            //   })
            //   .then((userAccess) => {
            //     sessionStorage.setItem(`access`, userAccess.data.access);
            //     localStorage.setItem(`refresh`, userAccess.data.access);
            //     localStorage.setItem("googleLoggedIn", "logged");
            //     setTokenIdGoogle(userAccess.data.access);
            //   });
          });
      });
    history.replace("./TextToSpeech");
  }

  function facebookLogin(res) {
    axios
      .post(`${config.url}social_auth/facebook/`, {
        auth_token: res.tokenId,
      })
      .then((access) => {
        setTokenIdFb(access.data.tokens.access);
        axios
          .get(`${config.url}api/users/me/`, {
            headers: {
              Authorization: `Bearer ${
                googleId || fbId || access.data.tokens.access
              }`,
            },
          })
          .then((meData) => {
            localStorage.setItem(
              "GoogleUserData",
              JSON.stringify(meData?.data)
            );
            // axios.post(`${config.url}auth/token/refresh/`, {
            //     refresh: access.data.tokens.refresh
            // }).then((userAccess) => {
            //     sessionStorage.setItem(`access`, userAccess.data.access)
            //     localStorage.setItem(`refresh`, userAccess.data.access)
            //     localStorage.setItem('googleLoggedIn', 'logged')
            //     setTokenIdFb(userAccess.data.access)
            // })
          });
      });
    history.replace("./TextToSpeech");
  }

  return (
    <div className="signUp">
      <Header />
      <SEO title="Bayanat Speech | Forgot Password" />

      <div style={{ paddingTop: "78px" }}>
        <div className="signUpSize">
          <h5 className="signUpTitle">Forgot Password?</h5>
          <p className="ForgotPasswordInfo">
            Enter your email address and we’ll send you a link to reset your
            password
          </p>
          <p className="signUpText">Email Address</p>
          <input
            className="signupInput"
            placeholder="Email Address"
            onChange={(e) =>
              setEmailData({ ...emailData, email: e.target.value })
            }
          />
          <p
            className={fetchSuccess === "" ? "errorMessage" : "successMessage"}
          >
            {fetchSuccess === "" ? fetchError.email : fetchSuccess}
          </p>
          <div className="Ireadandaccept">
            <input type="checkbox" />
            <p>Keep Me Logged In</p>
            <NavLink to="">Forgot Password ?</NavLink>
          </div>
          <button className="signUpBtn" onClick={handleClickForgot}>
            Login
          </button>
          <p className="orsignupwith">or sign up with</p>
          <div className="signUpSocBtns">
            <GoogleLogin
              render={(renderProps) => (
                <div
                  className="signUpSocBtn"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img src={googleicon} alt="icon1" />
                  <p>Google</p>
                </div>
              )}
              clientId="352606531076-0drt3a1ssjju2ralnu12bbmaqkdd1lsp.apps.googleusercontent.com"
              onSuccess={googleLogin}
              onFailure={() => console.log(`failed`)}
            />
            <FacebookLogin
              appId="333246241698828"
              fields="name,email,picture"
              callback={facebookLogin}
              icon={
                <div className="signUpSocBtn signUpSocBtn2">
                  <img src={facebookicon} alt="icon1" />
                  <p>Facebook</p>
                </div>
              }
              cssClass="fbBtn"
              textButton=""
            />
          </div>
          <p className="Alreadyavenaccount">
            Don't Have An Account?<NavLink to="/signUp">Sign Up</NavLink>{" "}
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
}
