import React from "react";
import "./style.css";
import SEO from "layout/nextseo";
import { ReactMic } from "react-mic";
import Win from "app/components/win/win";
import Loading from "app/components/loading";
import AudioSpectrum from "react-audio-spectrum";
import Header2 from "app/components/header/Header2";
import LeftBar from "app/components/leftBar/LeftBar";
import CountLimitPopUp from "app/components/countLimit";
// Hook
import useSpeach from "./useSpeach";
// Image
import pic5 from "app/assets/images/scr2.png";
import micc from "app/assets/images/mic.png";

export default function TextToSpeech(props) {
  const {
    checked,
    handleChange,
    content,
    talkText,
    downloadMp3,
    setUpload,
    upload,
    getRootProps,
    getInputProps,
    files,
    toggleMic,
    micState,
    isListening,
    isConverting,
    subPlan,
    setMicLang,
    ttsData,
    convertSpeech,
    setSpeechInd,
    showTtsData,
    isConvertings,
    langDecode,
    setTalkText,
    setNote,
    setContent,
    convertTextToSpeech,
    showLimitPopUp,
    setShowLimitPopUp,
    note,
  } = useSpeach(props);

  // Start
  return (
    <div className="textToSpeech">
      <SEO
        title="Bayanat Speech | Text to Speech"
        canonicalLink="https://bayanatspeech.com/TextToSpeech"
      />
      <Header2
        tik={true}
        title="Text To Speech"
        title2="Speech to Text"
        checked={checked}
        handleChange={handleChange}
      />
      <LeftBar />
      <div
        style={{ paddingTop: "73px" }}
        className="animate__animated animate__slideInLeft"
      >
        {!checked && content && talkText && (
          <>
            {" "}
            <audio
              id="myAudio"
              className="TTSAudio"
              autobuffer={true}
              autoPlay={true}
              controls={true}
            >
              <source src={`data:audio/wav;base64,` + content} />
            </audio>
            <AudioSpectrum
              id="audio-canvas"
              height={100}
              width={300}
              audioId={"myAudio"}
              capColor={"orange"}
              capHeight={2}
              meterWidth={3}
              meterCount={512}
              meterColor={[
                { stop: 0, color: "white" },
                { stop: 0.5, color: "#ff9911" },
                { stop: 1, color: "#ff9900" },
              ]}
              gap={4}
            />
            <p onClick={downloadMp3} className="downloadWav">
              download
            </p>
          </>
        )}
        <section className="textToSpeachContent">
          {checked ? (
            <>
              <div className="TTSCheckBox">
                <p onClick={() => setUpload(!upload)}>
                  {upload ? "Click to Record" : "Click to Upload"}
                </p>
              </div>
              <div className="mid" {...getRootProps()}>
                {upload && <input {...getInputProps()} />}
                <div>
                  <p className="midp">
                    {upload ? (
                      <div style={{ display: "flex" }}>
                        {" "}
                        <p>
                          Drag and drop your <span>WAV</span> to convert it into
                          speech
                        </p>{" "}
                        <p className="uploadedFileName">
                          {files.length !== 0 ? files[0].name : null}
                        </p>{" "}
                      </div>
                    ) : (
                      <p>
                        <span>Record</span> your voice
                      </p>
                    )}
                  </p>
                </div>
                {!upload && (
                  <>
                    {" "}
                    <img
                      className="mic"
                      onClick={toggleMic}
                      src={micc}
                      alt="icon1"
                    ></img>
                    <ReactMic
                      record={micState.record}
                      className="sound-wave"
                      strokeColor="#FF9900"
                      backgroundColor="white"
                    />
                    <p
                      style={
                        isListening ? { color: "#FF9900" } : { display: "none" }
                      }
                      className="recordMessage"
                    >
                      {isListening ? <p>recording</p> : <p>stopped</p>}
                    </p>
                  </>
                )}
                {isConverting && <Loading size={10} />}
              </div>
              {subPlan.mainType === "commercial" ||
              subPlan.planName === "Gold" ? (
                <></>
              ) : (
                <></>
              )}
              <div className="tdiv tdiv2">
                <p>Select Language Code</p>
                <select
                  onClick={(e) => setMicLang(e.target?.value)}
                  className="sel"
                  name="Language"
                >
                  {ttsData &&
                    Array.from(
                      new Set(ttsData.map((i) => i.languageCodes[0]))
                    ).map((item, index) => {
                      return (
                        <>
                          <option value={item} key={index}>
                            {item.includes(`ar-XA`)
                              ? item.replace(`ar-XA`, `Arabic `)
                              : item.includes(`ro-RO`)
                              ? item.replace(`ro-RO`, `Romanian `)
                              : item.includes(`pa-IN`)
                              ? item.replace(`pa-IN`, `Punjabi `)
                              : item.includes(`bn-IN`)
                              ? item.replace(`bn-IN`, `Indian`)
                              : item.includes(`en-GB`)
                              ? item.replace(`en-GB`, `English UK `)
                              : item.includes(`fr-CA`)
                              ? item.replace(`fr-CA`, `French(Canada) `)
                              : item.includes(`en-US`)
                              ? item.replace(`en-US`, `English US  `)
                              : item.includes(`es-ES`)
                              ? item.replace(`es-ES`, `Spanish `)
                              : item.includes(`fi-FI`)
                              ? item.replace(`fi-FI`, `Finnish`)
                              : item.includes(`gu-IN`)
                              ? item.replace(`gu-IN`, `Gujarati (India)`)
                              : item.includes(`ja-JP`)
                              ? item.replace(`ja-JP`, `Japanese`)
                              : item.includes(`kn-IN`)
                              ? item.replace(`kn-IN`, `Kannada (India)`)
                              : item.includes(`ml-IN`)
                              ? item.replace(`ml-IN`, `Malayam`)
                              : item.includes(`sv-SE`)
                              ? item.replace(`sv-SE`, `Swedish `)
                              : item.includes(`ta-IN`)
                              ? item.replace(`ta-IN`, `Tamil(India) `)
                              : item.includes(`tr-TR`)
                              ? item.replace(`tr-TR`, `Turkish`)
                              : item.includes(`ms-MY`)
                              ? item.replace(`ms-MY`, `Malay `)
                              : item.includes(`cs-CZ`)
                              ? item.replace(`cs-CZ`, `Czech `)
                              : item.includes(`de-DE`)
                              ? item.replace(`de-DE`, `German `)
                              : item.includes(`en-AU`)
                              ? item.replace(`en-AU`, `English(Australia)`)
                              : item.includes(`en-IN`)
                              ? item.replace(`en-IN`, `English(Ireland) `)
                              : item.includes(`es-US`)
                              ? item.replace(`es-US`, `Spanish US `)
                              : item.includes(`fr-FR`)
                              ? item.replace(`fr-FR`, `French `)
                              : item.includes(`hi-IN`)
                              ? item.replace(`hi-IN`, `Hindi `)
                              : item.includes(`id-ID`)
                              ? item.replace(`id-ID`, `Indonesian `)
                              : item.includes(`it-IT`)
                              ? item.replace(`it-IT`, `Italian`)
                              : item.includes(`ko-KR`)
                              ? item.replace(`ko-KR`, `Korean`)
                              : item.includes(`ru-RU`)
                              ? item.replace(`ru-RU`, `Russian`)
                              : item.includes(`uk-UA`)
                              ? item.replace(`uk-UA`, `Ukranian`)
                              : item.includes(`cmn-CN`)
                              ? item.replace(`cmn-CN`, `Mandarin Chinese `)
                              : item.includes(`cmn-TW`)
                              ? item.replace(`cmn-TW`, `Chinese(T) `)
                              : item.includes(`da-DK`)
                              ? item.replace(`da-DK`, `Danish `)
                              : item.includes(`el-GR`)
                              ? item.replace(`el-GR`, `Greek`)
                              : item.includes(`fil-PH`)
                              ? item.replace(`fil-PH`, `Filipino `)
                              : item.includes(`hu-HU`)
                              ? item.replace(`hu-HU`, `Hungarian `)
                              : item.includes(`nb-NO`)
                              ? item.replace(`nb-NO`, `Norwegian `)
                              /*: item.includes(`nb-NO`)
                              ? item.replace(`nb-n0`, `Norwegian2 `)*/
                              : item.includes(`nl-BE`)
                              ? item.replace(`nl-BE`, `Dutch(Belgium) `)
                              : item.includes(`nl-NL`)
                              ? item.replace(`nl-NL`, `Dutch(Netherlands) `)
                              : item.includes(`pt-PT`)
                              ? item.replace(`pt-PT`, `Portugese(Portugal) `)
                              : item.includes(`sk-SK`)
                              ? item.replace(`sk-SK`, `Slovak `)
                              : item.includes(`pl-PL`)
                              ? item.replace(`pl-PL`, `Polish `)
                              : item.includes(`vi-VN`)
                              ? item.replace(`vi-VN`, `Vietnamese `)
                              : item.includes(`pt-BR`)
                              ? item.replace(`pt-BR`, `Portugese(Brazil) `)
                              /*: item.includes(`af-ZA`)
                              ? item.replace(`af-ZA`, `Afrikaans `)*/
                              /*: item.includes(`bg-BG`)
                              ? item.replace(`bg-BG`, `Bulgarian `)
                              : item.includes(`sr-RS`)
                              ? item.replace(`sr-RS`, `Serbian (Cyrillic)`)
                              : item.includes(`te-IN`)
                              ? item.replace(`te-IN`, `Telugu (India) `)
                              : item.includes(`th-TH`)
                              ? item.replace(`th-TH`, `Thai (Thailand) `)
                              : item.includes(`is-IS`)
                              ? item.replace(`is-IS`, `Icelandic(Iceland)`)
                              : item.includes(`lv-LV`)
                              ? item.replace(`lv-LV`, `Latvian (Latvia) `)
                              : item.includes(`ca-ES`)
                              ? item.replace(`ca-ES`, `Catalan`)
                              : item.includes(`yue-HK`)
                              ? item.replace(`yue-HK`, `Chinese (Hong Kong) `)*/
                              : null}
                          </option>
                        </>
                      );
                    })}
                </select>
              </div>
              <img
                className="revImg"
                onClick={convertSpeech}
                src={pic5}
                alt="icon1"
              ></img>
            </>
          ) : (
            <div className="textToSpeachContentHeader">
              {!checked && (
                <>
                  <div className="tdiv">
                    <p>Speech Language</p>
                    <select
                      onClick={(e) => setSpeechInd(e.target.selectedIndex)}
                      className="sel"
                      name="Language"
                    >
                      {showTtsData &&
                        showTtsData?.map((item, index) => {
                          return (
                            <>
                              {item.includes(`Wavenet`) && (
                                <option value={index} key={index}>
                                  {item.includes(`ar-XA-Wavenet-`)
                                    ? item.replace(
                                        `ar-XA-Wavenet-`,
                                        `Arabic - `
                                      )
                                    : item.includes(`bn-IN-Wavenet-`)
                                    ? item.replace(
                                        `bn-IN-Wavenet-`,
                                        `Indian - `
                                      )
                                    : item.includes(`en-GB-Wavenet-`)
                                    ? item.replace(
                                        `en-GB-Wavenet-`,
                                        `English UK - `
                                      )
                                    : item.includes(`fr-CA-Wavenet-`)
                                    ? item.replace(
                                        `fr-CA-Wavenet-`,
                                        `French(Canada) - `
                                      )
                                    : item.includes(`en-US-Wavenet-`)
                                    ? item.replace(
                                        `en-US-Wavenet-`,
                                        `English US - `
                                      )
                                    : item.includes(`es-ES-Wavenet-`)
                                    ? item.replace(
                                        `es-ES-Wavenet-`,
                                        `Spanish - `
                                      )
                                    : item.includes(`fi-FI-Wavenet-`)
                                    ? item.replace(
                                        `fi-FI-Wavenet-`,
                                        `Finnish - `
                                      )
                                    : item.includes(`gu-IN-Wavenet-`)
                                    ? item.replace(
                                        `gu-IN-Wavenet-`,
                                        `Gujarati (India) - `
                                      )
                                    : item.includes(`ja-JP-Wavenet-`)
                                    ? item.replace(
                                        `ja-JP-Wavenet-`,
                                        `Japanese - `
                                      )
                                    : item.includes(`kn-IN-Wavenet-`)
                                    ? item.replace(
                                        `kn-IN-Wavenet-`,
                                        `Kannada (India) - `
                                      )
                                    : item.includes(`ml-IN-Wavenet-`)
                                    ? item.replace(
                                        `ml-IN-Wavenet-`,
                                        `Malayam - `
                                      )
                                    : item.includes(`sv-SE-Wavenet-`)
                                    ? item.replace(
                                        `sv-SE-Wavenet-`,
                                        `Swedish - `
                                      )
                                    : item.includes(`ta-IN-Wavenet-`)
                                    ? item.replace(
                                        `ta-IN-Wavenet-`,
                                        `Tamil(India) - `
                                      )
                                    : item.includes(`tr-TR-Wavenet-`)
                                    ? item.replace(
                                        `tr-TR-Wavenet-`,
                                        `Turkish - `
                                      )
                                    : item.includes(`ms-MY-Wavenet-`)
                                    ? item.replace(
                                        `ms-MY-Wavenet-`,
                                        `Malay - `
                                      )
                                    : item.includes(`cs-CZ-Wavenet-`)
                                    ? item.replace(
                                        `cs-CZ-Wavenet-`,
                                        `Czech - `
                                      )
                                    : item.includes(`de-DE-Wavenet-`)
                                    ? item.replace(
                                        `de-DE-Wavenet-`,
                                        `German - `
                                      )
                                    : item.includes(`en-AU-Wavenet-`)
                                    ? item.replace(
                                        `en-AU-Wavenet-`,
                                        `English(Australia) - `
                                      )
                                    : item.includes(`en-IN-Wavenet-`)
                                    ? item.replace(
                                        `en-IN-Wavenet-`,
                                        `English(Ireland) - `
                                      )
                                    : item.includes(`es-US-Wavenet-`)
                                    ? item.replace(
                                        `es-US-Wavenet-`,
                                        `Spanish US - `
                                      )
                                    : item.includes(`fr-FR-Wavenet-`)
                                    ? item.replace(
                                        `fr-FR-Wavenet-`,
                                        `French - `
                                      )
                                    : item.includes(`hi-IN-Wavenet-`)
                                    ? item.replace(
                                        `hi-IN-Wavenet-`,
                                        `Hindi - `
                                      )
                                    : item.includes(`id-ID-Wavenet-`)
                                    ? item.replace(
                                        `id-ID-Wavenet-`,
                                        `Indonesian - `
                                      )
                                    : item.includes(`it-IT-Wavenet-`)
                                    ? item.replace(
                                        `it-IT-Wavenet-`,
                                        `Italian - `
                                      )
                                    : item.includes(`ko-KR-Wavenet-`)
                                    ? item.replace(
                                        `ko-KR-Wavenet-`,
                                        `Korean - `
                                      )
                                    : item.includes(`ru-RU-Wavenet-`)
                                    ? item.replace(
                                        `ru-RU-Wavenet-`,
                                        `Russian - `
                                      )
                                    : item.includes(`uk-UA-Wavenet-`)
                                    ? item.replace(
                                        `uk-UA-Wavenet-`,
                                        `Ukranian - `
                                      )
                                    : item.includes(`cmn-CN-Wavenet-`)
                                    ? item.replace(
                                        `cmn-CN-Wavenet-`,
                                        `Mandarin Chinese - `
                                      )
                                    : item.includes(`cmn-TW-Wavenet-`)
                                    ? item.replace(
                                        `cmn-TW-Wavenet-`,
                                        `Chinese(T) `
                                      )
                                    : item.includes(`da-DK-Wavenet-`)
                                    ? item.replace(
                                        `da-DK-Wavenet-`,
                                        `Danish - `
                                      )
                                    : item.includes(`el-GR-Wavenet-`)
                                    ? item.replace(
                                        `el-GR-Wavenet-`,
                                        `Greek - `
                                      )
                                    : item.includes(`fil-PH-Wavenet-`)
                                    ? item.replace(
                                        `fil-PH-Wavenet-`,
                                        `Filipino - `
                                      )
                                    : item.includes(`hu-HU-Wavenet-`)
                                    ? item.replace(
                                        `hu-HU-Wavenet-`,
                                        `Hungarian - `
                                      )
                                    : item.includes(`nb-NO-Wavenet-`)
                                    ? item.replace(
                                        `nb-NO-Wavenet-`,
                                        `Norwegian - `
                                      )
                                    /*: item.includes(`nb-NO-Wavenet-`)
                                    ? item.replace(
                                        `nb-no-Wavenet-`,
                                        `Norwegian - `
                                      )*/
            
                                    : item.includes(`nl-BE-Wavenet-`)
                                    ? item.replace(
                                        `nl-BE-Wavenet-`,
                                        `Dutch(Belgium) - `
                                      )
                                    : item.includes(`nl-NL-Wavenet-`)
                                    ? item.replace(
                                        `nl-NL-Wavenet-`,
                                        `Dutch(Netherlands) - `
                                      )
                                    : item.includes(`pt-PT-Wavenet-`)
                                    ? item.replace(
                                        `pt-PT-Wavenet-`,
                                        `Portugese(Portugal) - `
                                      )
                                    : item.includes(`sk-SK-Wavenet-`)
                                    ? item.replace(
                                        `sk-SK-Wavenet-`,
                                        `Slovak - `
                                      )
                                    : item.includes(`pl-PL-Wavenet-`)
                                    ? item.replace(
                                        `pl-PL-Wavenet-`,
                                        `Polish - `
                                      )
                                    : item.includes(`vi-VN-Wavenet-`)
                                    ? item.replace(
                                        `vi-VN-Wavenet-`,
                                        `Vietnamese - `
                                      )
                                    /*: item.includes(`af-ZA-Wavenet-`)
                                    ? item.replace(
                                        `af-ZA-Wavenet-`,
                                        `Afrikaans - `
                                      )*/
                                    /*: item.includes(`bg-bg-Wavenet-`)
                                    ? item.replace(
                                        `bg-bg-Wavenet-`,
                                        `Bulgarian - `
                                      )*/
                                    /*: item.includes(`sr-rs-Wavenet-`)
                                    ? item.replace(
                                        `sr-rs-Wavenet-`,
                                        `Serbian (Cyrillic) - `
                                      )*/
                                    /*: item.includes(`te-IN-Wavenet-`)
                                    ? item.replace(
                                        `te-IN-Wavenet-`,
                                        `Telugu (India) - `
                                      )*/
                                    /*: item.includes(`th-TH-Wavenet-`)
                                    ? item.replace(
                                        `th-TH-Wavenet-`,
                                        `Thai (Thailand) - `
                                      )*/
                                    /*: item.includes(`is-is-Wavenet-`)
                                    ? item.replace(
                                        `is-is-Wavenet-`,
                                        `Icelandic (Iceland) - `
                                      )*/
                                    /*: item.includes(`lv-lv-Wavenet-`)
                                    ? item.replace(
                                        `lv-lv-Wavenet-`,
                                        `Latvian (Latvia) - `
                                      )*/
                                    /*: item.includes(`ca-es-Wavenet-`)
                                    ? item.replace(
                                        `ca-es-Wavenet-`,
                                        `Catalan - `
                                      )*/
                                    /*: item.includes(`yue-HK-Wavenet-`)
                                    ? item.replace(
                                        `yue-HK-Wavenet-`,
                                        `Chinese (Hong Kong) - `
                                      )*/
                                    : item.includes(`pt-BR-Wavenet-`)
                                    ? item.replace(
                                        `pt-BR-Wavenet-`,
                                        `Portugese(Brazil) - `
                                      )
                                    : item.includes(`ro-RO-Wavenet-`)
                                    ? item.replace(
                                        `ro-RO-Wavenet-`,
                                        `Romanian - `
                                      )
                                    : item.includes(`pa-IN-Wavenet-`)
                                    ? item.replace(
                                        `pa-IN-Wavenet-`,
                                        `Punjabi - `
                                      )
                                    : null}
                                </option>
                              )}
                            </>
                          );
                        })}
                    </select>
                  </div>
                </>
              )}
            </div>
          )}
          {/* {isConvertings && (
            <div className="TTSconverting">
              <Loading size={10} />
            </div>
          )} */}
          {talkText.length !== 0 && (
            <p>
              The detected language is: <span>{langDecode}</span>
            </p>
          )}
          <Win
            setTalkText={setTalkText}
            refresh={checked ? () => setNote(``) : () => setContent("")}
            handleConvert={convertTextToSpeech}
            width="1540px"
            height="530px"
            innerText={checked ? note : undefined}
            isChecked={checked}
            text="Type, paste, and edit text here."
          />
          {showLimitPopUp ? (
            <CountLimitPopUp setShow={setShowLimitPopUp} />
          ) : null}
        </section>
      </div>
    </div>
  );
}
