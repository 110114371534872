import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SEO from "layout/nextseo";

export default function AboutUs(props) {
  return (
    <>
      <Header isLoggedIn={props.isLoggedIn} />
      <SEO
        title="Bayanat Speech | About us"
        canonicalLink="https://bayanatspeech.com/AboutUs"
      />

      <div style={{ paddingTop: "78px" }}>
        <div className="AboutUsBody">
          <div className="AboutUsBodyUp">
            <div className="AboutUsBodyUpText">
              <p>About Us</p>
            </div>
          </div>
          <div className="AboutUsBodyDown">
            <p>
              BayanatSpeech is one of{" "}
              <a href="https://bayanatai.com/">Bayanatai</a> products that
              deliver artificial intelligence and machine learning solutions and
              services. Comes with cutting-edge AI technology in text-to-speech
              and speech–to–text.Our mission to deliver speech solutions to
              fulfill our clients’ expectations.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
