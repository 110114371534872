import React from "react";
import { createRoot } from "react-dom/client";
// Style
import "style/index.scss";
// Elements
import App from "App";
import SEO from "layout/nextseo";
import Loading from "app/components/loading";
import ScrollToTop from "app/elements/scrollTop";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

const RootApp = () => {
  return (
    <React.StrictMode>
      <React.Suspense fallback={<Loading />}>
        <HelmetProvider>
          <BrowserRouter>
            <SEO title="Bayanat Speech | Home" />
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </BrowserRouter>
        </HelmetProvider>
      </React.Suspense>
    </React.StrictMode>
  );
};
const rootElement = document.getElementById("root");
createRoot(rootElement).render(<RootApp />);

// For Typescript users
// const root = document.getElementById("root") as Element;

// import Layout from "./layout";
// import reportWebVitals from "./reportWebVitals";

// ReactDOM.render(<RootApp />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
