import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SEO from "layout/nextseo";

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <SEO
        title="Bayanat Speech | Terms & Conditions"
        canonicalLink="https://bayanatspeech.com/terms"
      />
      <section className="footerMenuPages wrapper">
        {/*<ul>*/}
        <h3 className="footerMenuPages__title wrapper">1. Terms</h3>
        <p className="footerMenuPages__list">
          By accessing the website at BayanatSpeech you are agreeing to be bound
          by these terms of service, all applicable laws and regulations, and
          agree that you are responsible for compliance with any applicable
          local laws. If you do not agree with any of these terms, you are
          prohibited from using or accessing this site. The materials contained
          in this website are protected by applicable copyright and trademark
          law.
        </p>
        <h3 className="footerMenuPages__title wrapper">
          2. General Conditions
        </h3>
        <p className="footerMenuPages__list">
          We reserve the right to refuse service to anyone, at any time, for any
          reason. We reserve the right to make any modifications to the website,
          including terminating, changing, suspending or discontinuing any
          aspect of the website at any time, without notice. We may impose
          additional rules or limits on the use of our website. You agree to
          review the Terms regularly and your continued access or use of our
          website will mean that you agree to any changes.
        </p>
        <h3 className="footerMenuPages__title wrapper">
          3. Products or Services
        </h3>
        <p className="footerMenuPages__list">
          We may, in our sole discretion, limit the sales of our products or
          services to any person, household, geographic region or jurisdiction.
          Prices for our services are subject to change, without notice. Unless
          otherwise indicated, prices displayed on our website are quoted in
          United States dollars. We reserve the right, in our sole discretion,
          to refuse orders, including without limitation, orders that appear to
          be placed by distributors or resellers. If we believe that you have
          made a false or fraudulent order, we will be entitled to cancel the
          order and inform the relevant authorities.
        </p>
        <h3 className="footerMenuPages__title wrapper">4. Service Agreement</h3>
        <ul className="footerMenuPages__ul wrapper">
          <li className="footerMenuPages__list">
            Grant of License to Access and Use Service. BayanatSpeech hereby
            grants to Customer, including to all Customers's Authorized Users, a
            non-exclusive, non-sublicensable, non-assignable, royalty-free, and
            worldwide license to access and use the service (the “Service”)
            solely for Customer's internal business operations.
          </li>
          <li className="footerMenuPages__list">
            Intellectual property rights. All intellectual property rights for
            audio files belong to the customer if the text content used for
            creating these audio files belongs to the customer and not infringe
            any third party’s IP rights. Commercial pack subscription allows
            customer to use the audio files for commercial purposes in any
            format. The Personal pack subscription does not grant such rights.
            BayanatSpeech owns all Intellectual Property Rights in the Services
            and Software.
          </li>
          <li className="footerMenuPages__list">
            Indemnification. By BayanatSpeech. BayanatSpeech will defend and
            indemnify Customer in any Third-Party Legal Proceeding to the extent
            arising solely from an Allegation that use of BayanatSpeech
            technology used to provide the Services.
          </li>
          <li className="footerMenuPages__list">
            Consequential Damages Waiver. EXCEPT FOR AMOUNTS PAYABLE TO THIRD
            PARTIES, NEITHER PARTY SHALL HAVE ANY LIABILITY ARISING OUT OF OR
            RELATED TO THIS AGREEMENT FOR ANY LOST PROFITS OR INDIRECT, SPECIAL,
            INCIDENTAL, RELIANCE, OR CONSEQUENTIAL DAMAGES OF ANY KIND, EVEN IF
            INFORMED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE.
          </li>
          <li className="footerMenuPages__list">
            Liability Cap. EXCEPT FOR AMOUNTS PAYABLE TO THIRD PARTIES VENDOR’S
            ENTIRE LIABILITY TO CUSTOMER ARISING OUT OF OR RELATED TO THIS
            AGREEMENT SHALL NOT EXCEED THE AMOUNT ACTUALLY PAID BY CUSTOMER TO
            VENDOR DURING THE PRIOR TWELVE (12) MONTHS UNDER THIS AGREEMENT.
          </li>
          <li className="footerMenuPages__list">
            Exceptions to Limitations. These limitations of liability do not
            apply to violations of a party's Intellectual Property Rights by the
            other party, indemnification obligations, or Customer's payment
            obligations. Payment. The customer must remit payment to
            BayanatSpeech in the amount of the Subscription Fee due on the
            Subscription creation day of every month/year.
          </li>
          <li className="footerMenuPages__list">
            System Availability Definition. 1. The system availability refers to
            the percentage of minutes that the system is available per month.
            This percentage of minutes includes when key components of the SaaS
            are functional and operational. 2. System availability doesn't
            include downtime minutes that result from: Malicious system attacks
            Issues with computing devices, internet service providers, online
            connections, or local area networks belonging to BayanatSpeech
            Events of force majeure Acts or omissions of BayanatSpeech,
            resulting in an inability for the customer to deliver services.
          </li>
        </ul>
        <h3 className="footerMenuPages__title wrapper">5 .Limitations</h3>
        <p className="footerMenuPages__list">
          In no event shall BayanatSpeech or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption) arising out of the use or
          inability to use the materials on BayanatSpeech ' website, even if
          BayanatSpeech or a BayanatSpeech authorized representative has been
          notified orally or in writing of the possibility of such damage.
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>
        <h3 className="footerMenuPages__title wrapper">
          6. Accuracy of materials
        </h3>
        <p className="footerMenuPages__list">
          The materials appearing on BayanatSpeech ' website could include
          technical, typographical, or photographic errors. BayanatSpeech does
          not warrant that any of the materials on its website are accurate,
          complete or current. BayanatSpeech may make changes to the materials
          contained on its website at any time without notice. However
          BayanatSpeech does not make any commitment to update the materials.
        </p>
        <h3 className="footerMenuPages__title wrapper">7. Links</h3>
        <p className="footerMenuPages__list">
          BayanatSpeech has not reviewed all of the sites linked to its website
          and is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by BayanatSpeech of
          the site. Use of any such linked website is at the user's own risk.
        </p>
        <h3 className="footerMenuPages__title wrapper">8. Modifications</h3>
        <p className="footerMenuPages__list">
          BayanatSpeech may revise these terms of service for its website at any
          time without notice. By using this website you are agreeing to be
          bound by the then current version of these terms of service. as
          acceptance of our practices around privacy and personal information.
          If you have any questions about how we handle user data and personal
          information, feel free to contact us.
        </p>

        <h3 className="footerMenuPages__title wrapper">
          9. Errors and Omissions
        </h3>
        <p className="footerMenuPages__list">
          Please note that our website may contain typographical errors or
          inaccuracies and may not be complete or current. We reserve the right
          to correct any errors, inaccuracies or omissions and to change or
          update information at any time, without prior notice (including after
          an order has been submitted). Such errors, inaccuracies or omissions
          may relate to product description, pricing, promotion and availability
          and we reserve the right to cancel or refuse any order placed based on
          incorrect pricing or availability information, to the extent permitted
          by applicable law.
        </p>
      </section>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
