import './style.css';
import box from '../../../assets/images/box.png';

export default function Step3(){
    return (
        <>
        <section className='stepsec'>
            <img className='step3img' src={box} alt='box'></img>
            <h1 className='hh1'>Your file is ready</h1>
        </section>
        </>
    )
} 