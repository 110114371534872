import React from "react";
import css from "./style.module.scss";
const Loading = () => {
  return (
    <div className={css["loader"]}>
      <div className={css["circle"]}></div>
    </div>
  );
};
export default Loading;
