import React from "react";
import css from "style/notfound.module.scss";
import Header from "app/components/header/Header";
import Footer from "app/components/footer/Footer";

const NotFound = () => {
  return (
    <React.Fragment>
      <Header />
      <div className={css.content}>
        <div class={css.text}>
          <h1>Error 404</h1>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default NotFound;
